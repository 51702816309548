import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../appAuthencationCheck";

function* fetchTrakFinancialEstimatesList(action) {
  const { payload } = action;
  const { values } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      ...values
    };
    const requestBody = createRequestBody("get", `/estimates/allEstimateDetails`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.TRAK_FINANCIAL_ESTIMATE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.TRAK_FINANCIAL_ESTIMATE_FAILURE,
        errorMsg: error.message
      });
    }
  }
}

function* fetchTrakFinancialEstimatesSaga() {
  yield takeEvery(actionTypes.TRAK_FINANCIAL_ESTIMATE_REQUESTED, fetchTrakFinancialEstimatesList);
}

// USER Role API Saga
function* fetchUserRoleDetail(action) {
  const { payload } = action;
  // const { values } = payload;

  try {
    //devapi.nextgenclarity.tech/users?limit=100&name=&offset=&practiceId=&primaryRole=FA%20Ops%20Manager
    const params = {
      offset: "0",
      limit: "10000"
      // practiceId: "",
      // name: ""
      // primaryRole: payload.primaryRole
    };
    console.log(payload);
    //  https://devapi.nextgenclarity.tech/practices/metadata?limit=100&offset=
    const requestBody = createRequestBody("get", `/practices/metadata`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_USER_ROLE,
      payload: response.data.accountRepArray
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.TRAK_FINANCIAL_ESTIMATE_FAILURE,
        errorMsg: error.message
      });
    }
  }
}

function* fetchUserRole() {
  yield takeEvery(actionTypes.GET_USER_ROLE, fetchUserRoleDetail);
}

// ADD Follow Up date

function* addFollowUpComment(action) {
  const { payload } = action;
  // const { values } = payload;
  //console.log(payload);
  try {
    // const params = {
    //   offset: "0",
    //   limit: "10000"
    // };
    const requestBody = createRequestBody(
      "put",
      `/estimates/${payload.estimateId}/followUpDate`,
      "",
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log(response);
    yield put({
      type: actionTypes.ADD_FOLLOW_UP_DATE_SUCCESS,
      payload: null,
      successMessage: "Saved Successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_FOLLOW_UP_DATE_FAILED,
        errorMessage: error.message
      });
    }
  }
}

function* addFollowUpCommentDetails() {
  yield takeEvery(actionTypes.ADD_FOLLOW_UP_DATE_REQUEST, addFollowUpComment);
}

export default function* trakFinancialEstimatesSaga() {
  yield all([fetchTrakFinancialEstimatesSaga(), addFollowUpCommentDetails(), fetchUserRole()]);
}
