import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import {
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import MessageModal from "../../shared/components/MessageModal";
import ReimbursementDetails from "./ReimbursementDetails";
import PricingModal from "./modals/PricingModal";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import moment from "moment";

const Rejected = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [openPricingModal, setIsOpenPricingModal] = useState(false);
  const [selectedPRId, setSelectedPRId] = useState(0);
  const [selectedPR, setSelectedPR] = useState({});
  const [selectedPRs, setSelectedPRs] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [unapproveModel, setUnapproveModel] = useState(false);
  const [approveModel, setApprovedModal] = useState(false);
  const [modelMsg, setModelMsg] = useState("");

  const updateSelectedPR = (selectedRowData) => {
    setSelectedPRs([...selectedRowData]);
  };

  const columns = [
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      width: 90,
      editable: false
    },
    {
      field: "clinicalTreatmentNo",
      headerName: "CYCLE NO.",
      width: 80,
      editable: false
    },
    {
      field: "patientFirstName",
      headerName: "FIRST NAME",
      width: 100,
      editable: false
    },
    {
      field: "patientLastName",
      headerName: "LAST NAME",
      width: 90,
      editable: false
    },
    {
      field: "approvedReimbursementAmount",
      headerName: "AMOUNT",
      width: 75,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.approvedReimbursementAmount);
      }
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 80,
      editable: false
    },
    {
      field: "medcaseStatus",
      headerName: "MEDCASE STATUS",
      width: 80,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 80,
      editable: false
    },
    {
      field: "transactionTypeDesc",
      headerName: "TRANSACTION TYPE",
      width: 140,
      editable: false
    },
    {
      field: "outcomeStatus",
      headerName: "OUTCOME",
      width: 90,
      editable: false
    },
    // {
    //   field: "embryosRemaining",
    //   headerName: "EMBRYOS REMAINING",
    //   width: 100,
    //   editable: false
    // },
    {
      field: "normalembryosRemaining",
      headerName: "NORMAL EMBRYOS REMAINING",
      width: 100,
      editable: false
    },
    {
      field: "verifiedNormalEmbryos",
      headerName: "Verified Embryos",
      width: 100,
      editable: false
    },
    {
      field: "requestedTreatmentPlan",
      headerName: "AUTH SERVICE",
      width: 85,
      editable: false
    },
    {
      field: "creationDate",
      headerName: "CREATED DATE",
      width: 150,
      editable: false,
      renderCell: ({ row }) =>
        row.creationDate ? moment(row.creationDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "createdBy",
      headerName: "CREATED USER",
      width: 150,
      editable: false
    },
    {
      field: "approvedDatez",
      headerName: "APPROVED DATE",
      width: 150,
      editable: false
    },
    {
      field: "approvedUserz",
      headerName: "APPROVED USER",
      width: 150,
      editable: false
    },
    {
      field: "exportedDatez",
      headerName: "EXPORTED DATE",
      width: 150,
      editable: false
    },
    {
      field: "exportedUserz",
      headerName: "EXPORTED USER",
      width: 150,
      editable: false
    },
    {
      field: "rejectedDate",
      headerName: "REJECTED DATE",
      width: 150,
      editable: false
    },
    {
      field: "rejectedUser",
      headerName: "REJECTED USER",
      width: 150,
      editable: false
    }
  ];

  const {
    providerReimbursementList = [],
    actions,
    addUnApproved,
    editUnApproved,
    loading,
    errorMsg,
    successEditPR,
    newPrList,
    successCheck
  } = props;

  const modifyCol = (rows) => {
    return rows.map((row) => {
      return {
        ...row,
        approvedUser: row.updatedBy,
        exportedUser: row.updatedBy,
        rejectedUser: row.updatedBy,
        creationDate: row.creationDate ? moment(row.creationDate).format("MM/DD/YYYY") : null,
        exportedDate: row.exportedDate ? moment(row.exportedDate).format("MM/DD/YYYY") : null
      };
    });
  };

  const rows = modifyCol(
    providerReimbursementList.map((item, index) => {
      item.id = index;
      return item;
    })
  );

  const initFetch = useCallback(() => {
    actions.getProviderReimbursement({ status: "Rejected" });
  }, [addUnApproved, editUnApproved]);

  useEffect(() => {
    if (Object.entries(selectedPR).length > 0) {
      let payload = {
        medcaseId: selectedPR.medcaseId,
        clinicalTreatmentNo: selectedPR.clinicalTreatmentNo
      };
      actions.createProgramCycle(payload);
    }
  }, [selectedPRId, providerReimbursementList]);
  useEffect(() => {
    actions.getProviderReimbursement({ status: "Rejected" });
  }, []);

  // useEffect(() => {
  //   if (rows.length > 0) {
  //     const filterData = rows.filter((item, index) => index == 0);
  //     setSelectedPR(filterData[0]);
  //     setSelectedPRId(filterData[0].medcaseId);
  //   }
  // }, [providerReimbursementList]);

  useEffect(() => {
    if (successEditPR || errorMsg) {
      setIsMessageModal(true);
    }

    if (providerReimbursementList.length == 0 || addUnApproved || editUnApproved) {
      initFetch();
    }
    return () => {
      actions.resetProviderReimbursementList();
    };
  }, [addUnApproved, editUnApproved, errorMsg, successEditPR]);

  useEffect(() => {
    console.log("successCheck: ", successCheck);
    if (successCheck === true) {
      if (unapproveModel) {
        setModelMsg("Unapproved successfully.");
      } else if (approveModel) {
        setModelMsg("Approved successfully.");
      }
      setSelectionModel([]);
      setSelectedPRs([]);
      setSelectedPRId(0);
      setSelectedPR({});
    }
  }, [successCheck]);

  const addUnapproved = () => {
    selectedPRs.map((item) => {
      item.reimbursementStatus = "Unapproved";
      delete item.id;
      delete item.approvedUser;
      delete item.exportedUser;
      delete item.rejectedUser;
      return item;
    });
    const payload = { value: selectedPRs };
    actions.addAproovedReimbursement(payload);
    setUnapproveModel(true);
  };
  const addApprove = () => {
    selectedPRs.map((item) => {
      item.reimbursementStatus = "Approved";
      delete item.id;
      delete item.approvedUser;
      delete item.exportedUser;
      delete item.rejectedUser;

      return item;
    });
    const payload = { value: selectedPRs };
    actions.addAproovedReimbursement(payload);
    setApprovedModal(true);
  };

  const closeMessageModal = () => {
    actions.getProviderReimbursement({ status: "Rejected" });
    setIsMessageModal(false);
    setUnapproveModel(false);
    setApprovedModal(false);
    setModelMsg("");
    actions.resetMessage();
  };
  const refresh = () => {
    actions.getProviderReimbursement({ status: "Rejected" });
  };
  const openPricing = () => {
    setIsOpenPricingModal(true);
  };
  const closePricingModal = () => {
    setIsOpenPricingModal(false);
  };
  // const disablePricing = () => {
  //   if (selectedPRId != null) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  const getRowsFromCurrentPage = ({ apiRef }) =>
    gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();
    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
    return (
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack
          direction="row"
          className="grid-header"
          sx={{ display: "flex", flexDirection: "row", marginBottom: "5px", gap: 5 }}>
          <Typography>Rejected List</Typography>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={addUnapproved}
            disabled={selectionModel.length > 0 ? false : true}>
            Unapprove
          </Button>
          <Button variant="contained" className="btn-primary" onClick={refresh}>
            Refresh
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={openPricing}
            disabled={selectionModel.length > 0 ? false : true}>
            Pricing
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={addApprove}
            disabled={selectionModel.length > 0 ? false : true}>
            Approve
          </Button>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}>
            Send To Excel
          </Button>
        </Stack>
      </Box>
    );
  };
  return (
    <>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          initialState={{
            sorting: {
              sortModel: [{ field: "creationDate", sort: "desc" }]
            }
          }}
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          checkboxSelection
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onRowSelectionModelChange={(ids) => {
            console.log("ids:", ids);
            setSelectionModel(ids);
            const selectedIDs = new Set(ids);
            console.log("selectedIDs:", selectedIDs);
            const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
            console.log("selectedRowData:", selectedRowData);
            updateSelectedPR(selectedRowData);
          }}
          onCellClick={(e) => {
            console.log("e:", e);
            setSelectedPRId(e?.row?.medcaseId);
            setSelectedPR(e?.row);
          }}
          rowSelectionModel={selectionModel}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          components={<CustomToolbar />}
        />
      </Box>
      {openPricingModal ? (
        <PricingModal
          open={openPricingModal}
          close={closePricingModal}
          newPrList={newPrList}
          selectedPR={selectedPR}
        />
      ) : (
        ""
      )}
      <MessageModal
        open={isMessageModal}
        message={successEditPR}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      {(modelMsg.length || errorMsg) && (
        <MessageModal
          open={unapproveModel}
          message={modelMsg}
          error={errorMsg}
          closeModal={closeMessageModal}
        />
      )}
      {(modelMsg.length || errorMsg) && (
        <MessageModal
          open={approveModel}
          message={modelMsg}
          error={errorMsg}
          closeModal={closeMessageModal}
        />
      )}
      <ReimbursementDetails selectedPRId={selectedPRId} selectedPR={selectedPR} />
    </>
  );
};
const mapStateToProps = ({ providerReimbursementReducer, newPrReducer }) => {
  return {
    loading: providerReimbursementReducer.loading,
    providerReimbursementList: providerReimbursementReducer.providerReimbursementList,
    addProviderReimbursement: providerReimbursementReducer.addProviderReimbursement,
    editProviderReimbursement: providerReimbursementReducer.editProviderReimbursement,
    errorMsg: providerReimbursementReducer.errorMsg,
    newPrList: newPrReducer.newPrList,
    successEditPR: providerReimbursementReducer.successEditPR,
    successCheck: providerReimbursementReducer.successCheck
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProviderReimbursement: (payload) => {
      dispatch(actionType.getProviderReimbursement(payload));
    },
    addNewProviderReimbursement: (payload) => {
      dispatch(actionType.addNewProviderReimbursement(payload));
    },
    addAproovedReimbursement: (payload) => {
      dispatch(actionType.addAproovedReimbursement(payload));
    },
    editProviderReimbursement: (payload) => {
      dispatch(actionType.editProviderReimbursement(payload));
    },
    resetProviderReimbursementList: () => {
      dispatch(actionType.resetProviderReimbursementList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    createProgramCycle: (payload) => {
      dispatch(actionType.createProgramCycle(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Rejected);
