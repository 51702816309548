import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Select,
  Typography,
  Button,
  Autocomplete,
  Menu,
  Stack,
  Modal,
  IconButton,
  Divider,
  Tooltip
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QuickAdd from "../../shared/components/QuickAdd";
import { connect } from "react-redux"; //useDispatch
import { useEffect, useState } from "react";
import { getPractices } from "../../admin/actions";
import * as actionType from "../actions";
import * as actionTypeEstimate from "../../workspace/actions";
// import { refundApplicationConstants } from "./../../shared/constants/constants";
// import moment from "moment/moment";
// import { FapRowData } from "../../workspace/actions";
// import { resetEstimateCards } from "../../workspace/actions";
import { v4 as uuidv4 } from "uuid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Link } from "react-router-dom";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import CommonModal from "../../shared/components/CommonModal";
import { commentConfig } from "../../shared/constants/renderConfigs";
import MessageModal from "../../shared/components/MessageModal";

const TrakFinancialEstimates = (props) => {
  const [today] = useState(moment().format("YYYY-MM-DD"));
  const { successMsg, errorMessage, successMessage } = props;
  let inputValue = {
    md: "",
    cycleType: "",
    clinicalLocation: ""
  };

  const {
    actions,
    trakFinancialEstimateList = [],
    loading,
    practices = [],
    metaData = {},
    userRole
  } = props;
  //   const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  let commentFormData = {
    type: "",
    comment: "",
    markImp: false,
    commentType: "Patient Communication"
  };
  const [commentValue, setCommentValue] = useState(commentFormData);

  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [isMessModal, setIsMessModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [succMsg, setSuccMsg] = useState("");
  const [followUpDetail, setFollowUpDetail] = useState({
    followUpDate: moment().format("MM-DD-YYYY"),
    accountRep: ""
  });
  const [values, setValues] = useState({
    patientNameOrId: "",
    practiceId: "",
    followUpDate: "",
    paymentStatus: "",
    accountRep: "",
    createDateFrom: null,
    createDateTo: null,
    publishDateFrom: moment().subtract(65, "days").format("YYYY-MM-DD"),
    publishDateTo: today,
    pgtaMedcaseStatus: "",
    refundEligibility: "",
    medcaseStatus: "",
    contractStatus: ""
  });
  const [csvData, setCsvData] = useState([]);
  const [valuesForPatient] = useState(inputValue);
  //show Success message
  useEffect(() => {
    if (successMsg && successMsg !== "") {
      setSuccMsg(successMsg);
      setErrMsg("");
      setIsMessModal(true);
      setIsOpen(false);
      setSelectedRow(undefined);
      actions.resetMessage();
      getList();
    }
  }, [successMsg]);

  //show success / error Message popup
  useEffect(() => {
    if (successMessage && successMessage !== "") {
      setSuccMsg(successMessage);
      setErrMsg("");
      setIsMessModal(true);
      setIsOpen(false);
      setSelectedRow(undefined);
      actions.resetTrakFinancialEstimateMessage();
      getList();
    }
    if (errorMessage && errorMessage !== "") {
      setSuccMsg("");
      setErrMsg(errorMessage);
      setIsMessModal(true);
      setIsOpen(false);
      setSelectedRow(undefined);
      actions.resetTrakFinancialEstimateMessage();
    }
  }, [errorMessage, successMessage]);

  // format Date
  function formateDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${month}/${day}/${year}`;
  }

  // function CustomToolbar() {
  //   return (
  //     <GridToolbarExport
  //       sx={{ textTransform: "none", width: "140px" }}
  //       printOptions={{
  //         hideFooter: true,
  //         hideToolbar: true,
  //         allColumnsOptional: true,
  //         allColumns: true,
  //         pageSize: pageSize,
  //         pageStyle: `
  //     @page {
  //       size: A4 landscape;
  //       margin: 0;
  //     }`
  //       }}
  //     />
  //   );
  // }
  // Prepare CSV Data
  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      patientNameAndKey:
        row?.patientName && row.patientKey
          ? `${row?.patientName ? row.patientName : ""} & ${row?.patientKey ? row.patientKey : ""}`
          : "",
      partnerNameAndKey:
        row?.partnerName && row.partnerKey
          ? `${row?.partnerName ? row.partnerName : ""} & ${row?.partnerKey ? row.partnerKey : ""}`
          : "",
      patientName: row?.patientName ? row.patientName : "",
      patientKey: row?.patientKey ? row.patientKey : "",
      patientId: row?.patientId ? row.patientId : "",
      patientDOB: row?.patientDOB ? row.patientDOB : "",
      patientAge: row?.patientAge ? row.patientAge : "",
      patientEmail: row?.patientEmail ? row.patientEmail : "",
      cellPhone: row?.cellPhone ? row.cellPhone : "",
      homePhone: row?.homePhone ? row.homePhone : "",
      practice: row?.practice ? row.practice : "",
      practice_id: row?.practice_id ? row.practice_id : "",
      primaryMD: row?.primaryMD ? row.primaryMD : "",
      partnerName: row?.partnerName ? row.partnerName : "",
      partnerId: row?.partnerId ? row.partnerId : "",
      partnerEmail: row?.partnerEmail ? row.partnerEmail : "",
      partnerKey: row?.partnerKey ? row.partnerKey : "",
      treatmentPlan: row?.treatmentPlan ? row.treatmentPlan : "",
      estimateTpId: row?.estimateTpId ? row.estimateTpId : "",
      estimateCreatedDate: row?.estimateCreatedDate ? formateDate(row.estimateCreatedDate) : "",
      daySinceCreatedDate: row?.daySinceCreatedDate ? Number(row.daySinceCreatedDate) : "",
      estimatePublishedDate: row?.estimatePublishedDate
        ? formateDate(row.estimatePublishedDate)
        : "",
      estimatePublishedBy: row?.estimatePublishedBy ? row.estimatePublishedBy : "",
      refundEligibility: row?.refundEligibility ? row.refundEligibility : "",
      refundApplicationStatus: row?.refundApplicationStatus ? row.refundApplicationStatus : "",
      refundApplicationNotes: row?.refundApplicationNotes ? row.refundApplicationNotes : "",
      contractStatus: row?.contractStatus ? row.contractStatus : "",
      paymentStatus: row?.paymentStatus ? row.paymentStatus : "",
      medcaseComment: row?.medcaseComment ? row?.medcaseComment : "",
      followUpDate: row?.followUpDate ? row?.followUpDate : "",
      estAccountRep: row?.estAccountRep ? row?.estAccountRep : "",
      preferredLanguage: row?.preferredLanguage ? row?.preferredLanguage : "",

      medcase:
        row?.medcaseId && row?.medcaseStatus && row?.medcaseStatusDate
          ? `${row.medcaseId} & ${row.medcaseStatus} & ${formateDate(row.medcaseStatusDate)}`
          : "",
      medcaseId: row?.medcaseId ? row.medcaseId : "",
      medcaseStatus: row?.medcaseStatus ? row.medcaseStatus : "",
      medcaseStatusDate: row?.medcaseStatusDate ? row.medcaseStatusDate : "",
      daySinceMedcaseStatus: row?.daySinceMedcaseStatus ? Number(row.daySinceMedcaseStatus) : "",
      programName: row?.programName ? row.programName : "",
      daySincePublishedDate: row?.daySincePublishedDate ? Number(row.daySincePublishedDate) : "",
      practiceName: row?.practiceName ? row.practiceName : "",
      estimateId: row?.estimateId ? row.estimateId : ""
    }));
  };

  const columns = [
    {
      field: "patientNameAndKey",
      //headerName: "PATIENT NAME & ID",
      headerName: "PATIENT",
      width: 170,
      renderCell: ({ row }) => {
        const patientName = row.patientName || "";
        const nameParts = patientName.split(" ");
        const firstName = nameParts[0] || "";
        const lastName = nameParts.slice(1).join(" ");
        return (
          <Link to={`/patients/${row?.patientId}/personal`}>
            <Button
              color="primary"
              variant="text"
              className="word-wrap"
              onClick={() => {
                actions.getPersonalList({ practiceId: row?.practice_id, valuesForPatient });
                localStorage.setItem("practiceId", row?.practice_id);
              }}>
              <div style={{ flexFlow: "row" }}>
                <Typography style={{ fontSize: "12px" }}>{`${firstName} ${lastName}`}</Typography>
                <Typography style={{ fontSize: "12px" }}>{`${row?.patientKey}`}</Typography>
                <Typography style={{ fontSize: "12px" }}>
                  {row?.patientDOB ? formateDate(row?.patientDOB) : "--"}
                </Typography>
                <Typography style={{ fontSize: "12px" }}>
                  {row?.patientAge ? row?.patientAge + " years" : "--"}
                </Typography>
                <Typography style={{ fontSize: "12px" }}>
                  <Tooltip title={row?.patientEmail} arrow followCursor={true}>
                    {row?.patientEmail ? `${row?.patientEmail?.split("@")[0]}@...` : "--"}
                  </Tooltip>
                </Typography>
                <Typography style={{ fontSize: "12px" }}>
                  {row?.preferredLanguage ? row?.preferredLanguage : "--"}
                </Typography>
              </div>
            </Button>
          </Link>
        );
      }
    },
    {
      field: "estimateId",
      headerName: "Estimate ID",
      width: 60,
      editable: false,
      renderCell: ({ row }) => {
        let estimateNumber = row.estimateId.split("-");
        return (
          <Link
            variant="contained"
            target="_blank"
            to={`/workspace/financial-estimates/${row.patientId}?PT=PD`}>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                localStorage.setItem("patientName", row.patientName);
                actions.resetEstimateViewList();
              }}
              className="word-wrap">
              {estimateNumber[1] ? estimateNumber[1] : "--"}
            </Button>
          </Link>
        );
      }
    },
    {
      field: "followUpDate",
      headerName: "Follow Up Date",
      width: 70,
      renderCell: ({ row }) => {
        // const createdDate = new Date(row.practiceId);
        // const currentDate = new Date();
        // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
        // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return (
          <Typography>
            {row?.followUpDate ? formateDate(row?.followUpDate) : "-"}
            {/* {row?.daySincePublishedDate ? " days" : "-"} */}
          </Typography>
        );
      }
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {row?.practiceName ? row?.practiceName : "--"}
          </Typography>
        );
      }
    },
    {
      field: "primaryMD",
      headerName: "Primary MD",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.primaryMD ? row?.primaryMD : "--"}</Typography>;
      }
    },
    {
      field: "treatmentPlan",
      headerName: "Treatment Plan",
      width: 65,
      renderCell: ({ row }) => {
        return <Typography>{row?.treatmentPlan ? row?.treatmentPlan : "--"}</Typography>;
      }
    },
    // {
    //   field: "patientDOB",
    //   headerName: "Patient DOB & Age",
    //   width: 60,
    //   renderCell: ({ row }) => {
    //     return (
    //       <div style={{ flexFlow: "row" }}>
    //         <Typography>{row?.patientDOB ? formateDate(row?.patientDOB) : "--"}</Typography>
    //         <Typography>{row?.patientAge ? row?.patientAge + " years" : "--"}</Typography>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   field: "patientEmail",
    //   headerName: "Patient Email",
    //   width: 60,
    //   renderCell: ({ row }) => {
    //     return <Typography>{row?.patientEmail ? row?.patientEmail : "--"}</Typography>;
    //   }
    // },
    {
      field: "cellPhone",
      headerName: "Cell Phone",
      width: 130,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.cellPhone
              ? `${
                  row?.cellPhone?.substring(0, 3) +
                  "-" +
                  row?.cellPhone?.substring(3, 6) +
                  "-" +
                  row?.cellPhone?.substring(6, 100)
                }`
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "homePhone",
      headerName: "Home Phone",
      width: 130,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.homePhone
              ? `${
                  row?.homePhone?.substring(0, 3) +
                  "-" +
                  row?.homePhone?.substring(3, 6) +
                  "-" +
                  row?.homePhone?.substring(6, 100)
                }`
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "partnerNameAndKey",
      headerName: "Partner",
      width: 170,
      renderCell: ({ row }) => {
        return (
          <div style={{ flexFlow: "row" }}>
            <Typography>{row?.partnerName ? row?.partnerName : "--"}</Typography>
            <Typography>{row?.partnerKey ? row?.partnerKey : "--"}</Typography>
            <Typography>{row?.partnerEmail ? row?.partnerEmail : "--"}</Typography>
          </div>
        );
      }
    },
    // {
    //   field: "partnerEmail",
    //   headerName: "Partner Email",
    //   width: 60,
    //   renderCell: ({ row }) => {
    //     return <Typography>{row?.partnerEmail ? row?.partnerEmail : "--"}</Typography>;
    //   }
    // },
    // {
    //   field: "estimateCreatedDate",
    //   headerName: "Estimate Created Date",
    //   width: 60,
    //   renderCell: ({ row }) => {
    //     return (
    //       <Typography>
    //         {row?.estimateCreatedDate
    //           ? formateDate(moment(new Date(row?.estimateCreatedDate)).format("L"))
    //           : "--"}
    //       </Typography>
    //     );
    //   }
    // },
    // {
    //   field: "daySinceCreatedDate",
    //   headerName: "Days Since Created Date",
    //   width: 60,
    //   renderCell: ({ row }) => {
    //     // const createdDate = new Date(row.practiceId);
    //     // const currentDate = new Date();
    //     // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
    //     // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    //     return (
    //       <Typography>
    //         {row?.daySinceCreatedDate ? row?.daySinceCreatedDate : "-"}
    //         {row?.daySinceCreatedDate ? " days" : "-"}
    //       </Typography>
    //     );
    //   }
    // },
    {
      field: "estimatePublishedDate",
      headerName: "Estimate Publish Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.estimatePublishedDate
              ? formateDate(moment(new Date(row?.estimatePublishedDate)).format("L"))
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "daySincePublishedDate",
      headerName: "Days Since Published Date",
      width: 60,
      renderCell: ({ row }) => {
        // const createdDate = new Date(row.practiceId);
        // const currentDate = new Date();
        // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
        // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return (
          <Typography>
            {row?.daySincePublishedDate ? row?.daySincePublishedDate : "-"}
            {/* {row?.daySincePublishedDate ? " days" : "-"} */}
          </Typography>
        );
      }
    },
    {
      field: "estimatePublishedBy",
      headerName: "Estimate Publish By",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>{row?.estimatePublishedBy ? row?.estimatePublishedBy : "--"}</Typography>
        );
      }
    },
    {
      field: "refundEligibility",
      headerName: "Refund Eligibility",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.refundEligibility ? row?.refundEligibility : "--"}</Typography>;
      }
    },
    {
      field: "refundApplicationStatus",
      headerName: "Refund Application Status",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.refundApplicationStatus ? row?.refundApplicationStatus : "--"}
          </Typography>
        );
      }
    },
    {
      field: "contractStatus",
      headerName: "Contract Status",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.contractStatus ? row?.contractStatus : "--"}</Typography>;
      }
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.paymentStatus ? row?.paymentStatus : "--"}</Typography>;
      }
    },
    {
      field: "medcase",
      headerName: "Medcase ID Status & Status Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <div style={{ flexFlow: "row" }}>
            <Link
              style={{
                textDecoration: "none",
                color: "#1BA3AD",
                fontFamily: "Rubik"
              }}
              to={{
                pathname: `/trak/caselookup/caselist/${row.medcaseId}/status`
                // state: { data: row.medcaseId }
              }}
              target="_blank">
              {row?.medcaseId}
            </Link>
            <Typography>{row?.medcaseStatus ? row?.medcaseStatus : "--"}</Typography>
            <Typography>
              {row?.medcaseStatusDate ? formateDate(row?.medcaseStatusDate) : "--"}
            </Typography>
          </div>
        );
      }
    },
    {
      field: "medcaseComment",
      headerName: "Comments",
      width: 200,
      renderCell: ({ row }) => {
        // const createdDate = new Date(row.practiceId);
        // const currentDate = new Date();
        // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
        // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return (
          <Typography>
            {row.medcaseComment ? row.medcaseComment : "-"}
            {/* {row?.daySincePublishedDate ? " days" : "-"} */}
          </Typography>
        );
      }
    },
    // {
    //   field: "daySincePublishedDate",
    //   headerName: "Days Since Published Date",
    //   width: 60,
    //   renderCell: ({ row }) => {
    //     // const createdDate = new Date(row.practiceId);
    //     // const currentDate = new Date();
    //     // const timeDifference = Math.abs(currentDate.getTime() - createdDate.getTime());
    //     // const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    //     return (
    //       <Typography>
    //         {row?.daySincePublishedDate ? row?.daySincePublishedDate : "-"}
    //         {/* {row?.daySincePublishedDate ? " days" : "-"} */}
    //       </Typography>
    //     );
    //   }
    // },
    {
      field: "refundApplicationNotes",
      headerName: "Refund Application Notes",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.refundApplicationNotes ? row?.refundApplicationNotes : "--"}
          </Typography>
        );
      }
    },
    {
      field: "daySinceMedcaseStatus",
      headerName: "Days Since Status Date",
      width: 60,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row?.daySinceMedcaseStatus ? row?.daySinceMedcaseStatus + " days" : "--"}
          </Typography>
        );
      }
    },
    {
      field: "programName",
      headerName: "Program Name",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.programName ? row?.programName : "--"}</Typography>;
      }
    },
    {
      field: "estAccountRep",
      headerName: "Account Rep",
      width: 60,
      renderCell: ({ row }) => {
        return <Typography>{row?.estAccountRep ? row?.estAccountRep : "--"}</Typography>;
      }
    }
  ];

  // const calculateDays = (fromDate, toDate) => {
  //   var date1 = moment(fromDate);
  //   var date2 = moment("2022-12-30");
  //   var days = date1.diff(date2, "days");
  // };

  const getUserDetails = () => {
    let payload = {
      primaryRole: "FA Ops Manager"
    };
    actions.getUserRole(payload);
  };

  useEffect(() => {
    if (trakFinancialEstimateList.length === 0) {
      getList();
    }
    if (userRole.length == 0) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (practices.length === 0) {
      actions.getPractices();
    }
    if (Object.keys(metaData).length === 0) {
      actions.getMetaData();
    }
  }, []);

  useEffect(() => {
    if (trakFinancialEstimateList.length != 0) {
      let rows = trakFinancialEstimateList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
      const csvDataMode = prepareCsvData(rows);
      setCsvData(csvDataMode);
    } else {
      setCsvData([]);
      setRows([]);
    }
  }, [loading]);

  const getList = () => {
    actions.getTrakFinacialEstimateList({ values });
  };
  const handleInputChange = (e) => {
    actions.resetTrakFinancialEstimateList();
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleAutoCompleteChange = (e, name, value) => {
    // const { name, value } = e.target;
    // actions.resetTrakFinancialEstimateList();
    if (value) {
      setValues({
        ...values,
        [name]: value.value
      });
    } else {
      setValues({
        ...values,
        [name]: null
      });
    }
  };

  const handleSearchClick = (e, searchText) => {
    // actions.resetTrakFinancialEstimateList();
    console.log("USF");
    values.patientNameOrId = searchText;
    setValues({
      ...values
    });
    getList();
  };
  const handleActionsClick = (event) => {
    if (!selectedRow) {
      setSuccMsg("");
      setErrMsg("Record must be selected.");
      setIsMessModal(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const clearSearchClick = () => {
    values.patientNameOrId = "";
    setValues({ ...values });
  };

  const pgtaMedcasetstaus = [
    "Clinical Review",
    "Complete",
    "Enrolled",
    "Fertility Access Withdrew",
    "Medically Cleared",
    "Not Enrolled",
    "Patient Withdrew",
    "Program Complete - Contract Expired",
    "Re-Enrolled"
  ];

  // Email Clicked Function
  const handleEmailClick = (email, ccEmail = "") => {
    // const email = "satyam@fexle.com";
    const subject = ""; // (Optional) Subject for the email
    const body = ""; // (Optional) Body content

    // Create the mailto link dynamically

    // const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
    //   email
    // )}&cc=${encodeURIComponent(ccEmail)}&subject=${encodeURIComponent(
    //   subject
    // )}&body=${encodeURIComponent(body)}`;
    // // Open the Gmail composer in a new tab
    // window.open(gmailUrl, "_blank");
    if (ccEmail == "") {
      const outlookWebUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(
        email
      )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Open the Outlook Web composer in a new tab
      window.open(outlookWebUrl, "_blank");
    } else {
      const outlookWebUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(
        email + "," + ccEmail
      )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Open the Outlook Web composer in a new tab
      window.open(outlookWebUrl, "_blank");
    }
  };

  // New comment Added

  const addNewComment = () => {
    setCommentValue({
      ...commentFormData,
      commentType: "Patient Communication"
    });
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveComment = (event) => {
    event.preventDefault();
    if (selectedRow && selectedRow?.medcaseId) {
      //  values.markImp = values.markImp ? values.markImp : false;
      //  values.medCaseId = id;
      actions.addNewComment({
        ...commentValue,
        medCaseId: selectedRow?.medcaseId
      });
    } else {
      setErrMsg("MedCaseId not available");
      setIsMessModal(true);
    }
    // addNewComment();
    // closeModal();
  };
  const handleCommentInputChange = (e) => {
    var { name, value } = e.target;
    if (name == "markImp") {
      value = e.target.checked;
    }
    setCommentValue({
      ...commentValue,
      [name]: value
    });
  };
  const comment = commentConfig({
    commentValue,
    onChange: handleCommentInputChange,
    onClick: closeModal,
    isAdd: true,
    disabled: !commentValue.comment || !commentValue.commentType ? true : false
  });

  // Submit Follow Up Date
  const handleSubmitFollowUpDetail = () => {
    if (selectedRow?.estimateId) {
      if (modalType == "Follow Up" && followUpDetail?.followUpDate) {
        actions.addFollowUpDate({
          ...followUpDetail,
          estimateId: selectedRow?.estimateId,
          accountRep: null
        });
      } else if (modalType == "Account Rep" && followUpDetail?.accountRep) {
        actions.addFollowUpDate({
          ...followUpDetail,
          estimateId: selectedRow?.estimateId,
          followUpDate: null
        });
      }
    } else {
      setErrMsg("MedCaseId not available");
      setIsMessModal(true);
    }
  };

  return (
    <Box className="refund-application">
      <Box className="teal-bg-color pad-5">
        {/* Action Button  */}
        <Grid item xs={1} style={{ marginLeft: "auto" }}>
          <Stack direction="row" sx={{ width: "fit-content" }}>
            <Button
              variant="contained"
              aria-controls={open ? "actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleActionsClick}>
              Actions
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleActionClose}
              MenuListProps={{
                "aria-labelledby": "actions-button"
              }}>
              <MenuItem
                onClick={() => {
                  handleEmailClick(selectedRow?.patientEmail);
                }}
                // disabled={isAccess("adpt") ? false : true}
              >
                Email Patient
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleEmailClick(selectedRow?.partnerEmail);
                }}
                // disabled={Object.keys(selectedPerson).length ? false : true}
              >
                Email Partner
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleEmailClick(selectedRow?.patientEmail, selectedRow?.partnerEmail);
                }}
                // disabled={Object.keys(selectedPerson).length ? false : true}
              >
                Email Patient and Partner
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setModalType("Comment");
                  addNewComment();
                  handleActionClose();
                }}
                // disabled={Object.keys(selectedPerson).length ? false : true}
              >
                Add Comment
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setModalType("Account Rep");
                  addNewComment();
                  handleActionClose();
                }}
                // disabled={Object.keys(selectedPerson).length ? false : true}
              >
                Add Account Rep
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setModalType("Follow Up");
                  setIsOpen(true);
                  handleActionClose();
                }}
                // disabled={Object.keys(selectedPerson).length ? false : true}
              >
                Add Follow Up Date
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
        {/* Action Button End  */}
        {/* Find a pateint */}

        <QuickAdd
          text={1}
          search={7}
          handleSearchClick={handleSearchClick}
          clearSearchClick={clearSearchClick}
        />

        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            {/* Practice */}
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Practice</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                name="practiceId"
                fullWidth
                value={values.practiceId}
                onChange={handleInputChange}>
                {practices &&
                  practices.map((item, index) => {
                    return (
                      <MenuItem value={item.practiceId} key={index}>
                        {item.name} - {item.practiceId}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={values?.practiceId}
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.practiceId}`,
                    name: item.name,
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "practiceId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.25}></Grid>
            {/* Follow Up Date  */}
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Follow Up Date </Typography>
            </Grid>

            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={values.followUpDate}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, followUpDate: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      size="small"
                      className="patient-dropdown"
                      fullWidth
                    />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={0.25}></Grid>
          </Grid>
        </Grid>

        {/*Date picker */}
        {/* <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            {" "}
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Create Date From </Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.createDateFrom}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, createDateFrom: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Create Date To</Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.createDateTo}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, createDateTo: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid> */}

        {/*Date picker */}
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Published Date From </Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.publishDateFrom}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, publishDateFrom: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Published Date To</Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disableFuture
                  value={values.publishDateTo}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
                    actions.resetTrakFinancialEstimateList();
                    setValues({ ...values, publishDateTo: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        {/* REfund Eligibility & Account Rep */}
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography>Refund Eligibility</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="refundEligibility"
                value={values.refundEligibility}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="refundEligibility"
                value={values.refundEligibility}
                options={[
                  {
                    id: 1,
                    label: "Select",
                    value: "",
                    name: "refundEligibility"
                  },
                  {
                    id: 2,
                    label: "Yes",
                    value: "Y",
                    name: "refundEligibility"
                  },
                  {
                    id: 3,
                    label: "No",
                    value: "N",
                    name: "refundEligibility"
                  }
                ]}
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "refundEligibility", value);
                }}
                renderInput={(params) => <TextField {...params} label="Refund Eligibility..." />}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Account Rep </Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="medcaseStatus"
                value={values.medcaseStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="accountRep"
                value={values?.accountRep}
                options={
                  userRole.length > 0 &&
                  userRole.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      value: item,
                      name: "accountRep"
                    };
                  })
                }
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "accountRep", {
                    value: value?.value
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Account Rep..." />}
                className="flex-4"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* payment Status && Medcase Status */}
        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography>Payment Status</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="refundEligibility"
                value={values.refundEligibility}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="paymentStatus"
                value={values?.paymentStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.paymentStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item
                    };
                  })
                }
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "paymentStatus", {
                    value: value.label
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Status..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Medcase Status </Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="medcaseStatus"
                value={values.medcaseStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="medcaseStatus"
                value={values.medcaseStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      value: item,
                      name: "medcaseStatus"
                    };
                  })
                }
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "medcaseStatus", value);
                }}
                renderInput={(params) => <TextField {...params} label="Medcase Status..." />}
                className="flex-4"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Contract Status & PGTA Medcase Status  */}

        <Grid container className="align-center pad-5">
          <Grid container className="align-center">
            <Grid item xs={1}>
              <Typography style={{ marginRight: "10px" }}>Contract Status</Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                name="contractStatus"
                value={values.contractStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {Object.keys(metaData).length > 0 &&
                  metaData?.contractStatus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>

            <Grid item xs={0.25}></Grid>

            {/* PGTA Medcase status */}

            <Grid item xs={1}>
              <Typography>PGTA Medcase Status</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="pgtaMedcaseStatus"
                value={values.pgtaMedcaseStatus}
                className="patient-dropdown"
                onChange={handleInputChange}>
                <MenuItem value="">Select</MenuItem>
                {pgtaMedcasetstaus.length > 0 &&
                  pgtaMedcasetstaus.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="pgtaMedcaseStatus"
                value={values.pgtaMedcaseStatus}
                options={pgtaMedcasetstaus.map((item, index) => {
                  return {
                    id: index,
                    label: item,
                    value: item,
                    name: "pgtaMedcaseStatus"
                  };
                })}
                onChange={(e, value) => {
                  handleAutoCompleteChange(e, "pgtaMedcaseStatus", value);
                }}
                renderInput={(params) => <TextField {...params} label="PGTA Medcase Status..." />}
                className="flex-4"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          loading={loading}
          rows={csvData}
          rowCount={rows.length}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={pageSize}
          onPaginationModelChange={setPageSize}
          pageSize={pageSize.pageSize}
          className="mt-8"
          getRowId={() => uuidv4()}
          localeText={"Export Button"}
          isExport={true}
          pagination
          onRowClick={({ row }) => {
            setSelectedRow(row);
            setFollowUpDetail({
              ...followUpDetail,
              followUpDate: row?.followUpDate
            });
          }}
        />
      </Box>

      {/* Follow Up Date Modal  */}
      <Modal
        open={isOpen && (modalType == "Follow Up" || modalType == "Account Rep")}
        backdrop="static"
        aria-labelledby="modal-add-title">
        <Box className="modal-style">
          <Stack direction="row" sx={{ justifyContent: "flex-end" }} className="modal-stack">
            <IconButton
              onClick={() => {
                setIsOpen(false);
              }}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          {modalType == "Follow Up" && (
            <Stack direction="column" className="flex-center m-8">
              <Typography>Add Follow Up Date</Typography>
              <Stack direction="row" className="flex flex-between" my={2}>
                {/* <Typography>Follow Up Date:</Typography> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // disableFuture
                    value={followUpDetail?.followUpDate}
                    onChange={(newValue) => {
                      const format2 = "YYYY-MM-DD";
                      newValue = moment(newValue).isValid()
                        ? moment(newValue).format(format2)
                        : null;
                      // actions.resetTrakFinancialEstimateList();
                      setFollowUpDetail({ ...followUpDetail, followUpDate: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={false}
                        size="small"
                        className="patient-dropdown"
                        fullWidth
                      />
                    )}></DatePicker>
                </LocalizationProvider>
              </Stack>
            </Stack>
          )}
          {modalType == "Account Rep" && (
            <Stack direction="column" className="flex-center m-5">
              <Typography>Add Account Rep</Typography>
              <Stack direction="row" style={{ width: "100%" }} className="flex flex-between" my={2}>
                <Autocomplete
                  disablePortal
                  required
                  style={{ width: "60%", margin: "auto" }}
                  name="accountRep"
                  value={followUpDetail?.accountRep}
                  options={
                    userRole.length > 0 &&
                    userRole.map((item, index) => {
                      return {
                        id: index,
                        label: item,
                        value: item,
                        name: "accountRep"
                      };
                    })
                  }
                  onChange={(e, value) => {
                    setFollowUpDetail({ ...followUpDetail, accountRep: value.value });
                  }}
                  renderInput={(params) => <TextField {...params} label="Account Rep..." />}
                  className="flex-1"
                />
              </Stack>
            </Stack>
          )}
          <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              // disabled={!additionalField.verifiedNormalEmbryosRemaining}
              onClick={() => {
                // setNormalEmbryosRemainingModal(false);
                handleSubmitFollowUpDetail();
              }}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>

      {/* comment Modal  */}
      <CommonModal
        open={isOpen && modalType == "Comment"}
        config={comment}
        isAdd={true}
        handleSubmit={saveComment}
      />
      {/* Message Modal  */}
      <MessageModal
        open={isMessModal}
        message={succMsg}
        error={errMsg}
        closeModal={() => {
          setErrMsg("");
          setSuccMsg("");
          setIsMessModal(false);
        }}
      />
    </Box>
  );
};

const mapStateToProps = ({
  practices,
  trakFinancialEstimatesReducer,
  caseUpdateReducer,
  commentReducer
}) => {
  return {
    practices: practices.practices,
    trakFinancialEstimateList: trakFinancialEstimatesReducer.trakFinancialEstimateList,
    loading: trakFinancialEstimatesReducer.loading,
    error: trakFinancialEstimatesReducer.error,
    metaData: caseUpdateReducer.metaData,
    successMsg: commentReducer.success,
    errorMessage: trakFinancialEstimatesReducer.errorMessage,
    successMessage: trakFinancialEstimatesReducer.successMessage,
    userRole: trakFinancialEstimatesReducer.userRole
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(getPractices());
    },
    getTrakFinacialEstimateList: (payload) => {
      dispatch(actionType.getTrakFinacialEstimateList(payload));
    },
    resetTrakFinancialEstimateList: () => {
      dispatch(actionType.resetTrakFinancialEstimateList());
    },
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    resetEstimateViewList: () => {
      dispatch(actionTypeEstimate.resetEstimateViewList());
    },
    getPersonalList: (payload) => {
      dispatch(actionType.getPersonalList(payload));
    },
    addNewComment: (payload) => {
      dispatch(actionType.addNewComment(payload));
    },
    addFollowUpDate: (payload) => {
      dispatch(actionType.addFollowUpDate(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetTrakFinancialEstimateMessage: () => {
      dispatch(actionType.resetTrakFinancialEstimateMessage());
    },
    getUserRole: (payload) => {
      dispatch(actionType.getUserRole(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TrakFinancialEstimates);
