import { Box, Grid, Stack, Typography, InputLabel, MenuItem, Menu, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { calculateAge, getGengerFullName } from "../../shared/constants/constants";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PatientDetails from "./PatientDetails";
const PatientCard = (props) => {
  const practiceId = localStorage.getItem("practiceId");
  const { personId } = useParams();
  const { actions, partnerDetails = {}, personData = {}, loading } = props;
  const [patientData, setPatientData] = useState({});
  const [partnerData, setPartnerData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [editPatient, setEditPatient] = useState(false);
  const open = Boolean(anchorEl);
  useEffect(() => {
    let payload = { practiceId, personId };
    actions.resetPartnerData();
    actions.getPersonDataById(payload);
  }, [personId]);
  useEffect(() => {
    if (Object.values(personData).length && personData?.personId == personId) {
      if (personData.activePartner != null) {
        const payload = { practiceId, personId: personData.activePartner.partnerId };
        actions.getPartnerData(payload);
      } else {
        actions.resetPartnerData();
      }
    }
  }, [personData]);
  useEffect(() => {
    if (Object.values(personData)?.length) {
      props.getPatientData(personData);
      setPatientData(personData);
    }
    // if (Object.values(partnerDetails)?.length) {
    setPartnerData(partnerDetails);
    // }
  }, [partnerDetails, personData]);
  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const gotoEditPatientForm = () => {
    setEditPatient(true);
    handleActionClose();
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const closePatientForm = () => {
    setEditPatient(false);
    handleActionClose();
  };
  return (
    <Box>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography className="font-w-500">
            {patientData.firstName + " " + patientData.lastName} | ID # {patientData.personKey} |
            IVF
          </Typography>
          <Stack direction="row">
            <Button
              variant="contained"
              aria-controls={open ? "actions-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleActionsClick}>
              Actions
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleActionClose}
              MenuListProps={{
                "aria-labelledby": "actions-button"
              }}>
              <MenuItem onClick={gotoEditPatientForm}>Edit Patient detail</MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Box>
      <Grid container className="pad-t-15">
        <Grid item xs={12}>
          <Stack direction="row" gap={1}>
            <InputLabel>
              Partner ({partnerData.gender ? getGengerFullName(partnerData.gender) : "-"})
            </InputLabel>
            {partnerData.personKey ? (
              <Stack direction="row">
                <Link
                  to={`${partnerData.personId}/personal`}
                  onClick={() => {
                    actions.resetPersonalList();
                  }}>
                  <SyncAltIcon color="primary" fontSize="18px" />
                </Link>
                <Typography>
                  {partnerData.firstName + " " + partnerData.lastName} ID#{partnerData.personKey}
                </Typography>
              </Stack>
            ) : (
              <Stack direction="row">
                <Typography>Unknown Unknown ID# 0</Typography>
              </Stack>
            )}
            <Link
              to="#"
              className="link-color"
              onClick={(e) => {
                window.location.href = "mailto:no-reply@example.com";
                e.preventDefault();
              }}>
              INT.Email <img alt="" className="m-l-5" src="/Assets/mail.png" />
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <Grid container className="pad-t-15">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Sex | Age (DOB)</InputLabel>
            <Typography>
              {getGengerFullName(patientData.gender)} | {calculateAge(patientData?.birthDate)}
              yo ({patientData?.birthDate})
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Primary MD</InputLabel>
            {patientData?.ivfMDFirstName ? (
              <Typography>
                {((patientData && patientData.ivfMDFirstName) || "") +
                  " " +
                  ((patientData && patientData.ivfMDMidInitial) || "") +
                  " " +
                  ((patientData && patientData.ivfMDLastName) || "")}
              </Typography>
            ) : (
              <Typography>--</Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Treatment</InputLabel>
            <Typography>---</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container className="pad-t-15">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Linked Patients</InputLabel>
            <Typography>---</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Preferred Language</InputLabel>
            {patientData?.preferredLanguage ? (
              <Typography>{patientData?.preferredLanguage}</Typography>
            ) : (
              <Typography>--</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box className="m-8">{loading && <LoadingSpinner top="33%" />}</Box>
      {editPatient ? (
        <PatientDetails
          open={editPatient}
          isEdit={true}
          selectedPatient={personData}
          close={closePatientForm}
        />
      ) : null}
    </Box>
  );
};
const mapStateToProps = ({ patientReducer }) => {
  return {
    personData: patientReducer.personDataById,
    partnerDetails: patientReducer.partnerData,
    loading: patientReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPersonDataById: (payload) => {
      dispatch(actionType.getPersonDataById(payload));
    },
    getPartnerData: (payload) => {
      dispatch(actionType.getPartnerData(payload));
    },
    resetPersonalList: () => {
      dispatch(actionType.resetPersonalList());
    },
    resetPartnerData: () => {
      dispatch(actionType.resetPartnerData());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientCard);
