import {
  LOGINS,
  AWS_REGION,
  AWS_IDENTITY_POOL_ID,
  AWS_LOGINS_KEY,
  AWS_CREDENTIALS
} from "../constants/cognitoConstants";

import { S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const storeTemporaryCredentials = (credentials) => {
  localStorage.setItem(AWS_CREDENTIALS, JSON.stringify(credentials));
};

const getTemporaryCredentials = () => {
  return JSON.parse(localStorage.getItem(AWS_CREDENTIALS));
};

/* Not to be used anywhere, except login & logout */
const clearSignInItems = (items) => {
  items.forEach((item) => {
    localStorage.removeItem(item);
  });
  localStorage.clear();
};

async function fetchAndStoreAWSTemporaryCredentails(idToken) {
  LOGINS[AWS_LOGINS_KEY] = idToken;
  const s3Client = new S3Client({
    region: AWS_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: AWS_REGION }, // Configure the underlying CognitoIdentityClient.
      identityPoolId: AWS_IDENTITY_POOL_ID,
      logins: LOGINS
    })
  });
  const response = await s3Client.config.credentials();

  storeTemporaryCredentials(response);
}

export {
  fetchAndStoreAWSTemporaryCredentails,
  storeTemporaryCredentials,
  getTemporaryCredentials,
  clearSignInItems
};
