import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { MenuItem, InputLabel, Stack, Typography, Divider, Button } from "@mui/material";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { useNavigate } from "react-router-dom";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import jwtDecode from "jwt-decode";
const ProfileDrawer = (props) => {
  const { actions, profileDetails = [], loading } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const [practiceList, setPracticeList] = useState([]);
  const handleClose = () => {
    if (open === true) {
      setOpen(false);
      props.close();
    } else {
      setOpen(true);
    }
  };
  useEffect(() => {
    const cognitoUserId = localStorage.getItem("cognitoUserId");
    /* start - run only when we have valid credentials to avoid 403 */
    const credInterval = setInterval(() => {
      let cred = getTemporaryCredentials();
      if (cred != null) {
        let payload = { cognitoUserId };
        actions.getUserProfileDetails(payload);
        clearInterval(credInterval);
      }
    }, 600);
    return () => clearInterval(credInterval);
    /* end */
  }, []);
  useEffect(() => {
    setPracticeList(profileDetails.practiceList);
    if (profileDetails.userId) {
      // const data = Object.values(profileDetails.userRightList);
      // let userScreenRightDetails = data?.map((item) => {
      //   return {
      //     userDetails: item
      //   };
      // });
      // //console.log("userScreenRightDetails", userScreenRightDetails);
      // localStorage.setItem("screenRights", JSON.stringify(userScreenRightDetails));

      // profileDetails.userRightList.forEach((userDetails) => {
      //   console.log(userDetails, "userDetails");

      //   userGroupFormat.includes(userDetails) &&
      //     userScreenRightDetails.push({ userDetails: decoded[userDetails] });
      // });
      localStorage.setItem("userProfile", JSON.stringify(profileDetails));
    }
    localStorage.setItem("userId", profileDetails.userId);
    let token = localStorage.getItem("token");
    const expiryDate = new Date(jwtDecode(token).exp * 1000);
    const datenow = new Date();
    if (datenow > expiryDate) {
      navigate("/logout");
      localStorage.clear();
    }
    if (
      profileDetails &&
      profileDetails.primaryRole &&
      profileDetails.primaryRole.toLowerCase() === "patient"
    ) {
      navigate("/logout");
      localStorage.clear();
    }
  }, [profileDetails]);
  return (
    <>
      <Drawer
        PaperProps={{ sx: { height: "auto" } }}
        sx={{ zIndex: 1 }}
        disableScrollLock
        hideBackdrop={true}
        anchor={"right"}
        open={props.openProfile}
        onClose={handleClose}>
        <Box
          sx={{ width: 250, paddingTop: 10 }}
          role="presentation"
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}>
          <MenuItem>User Profile</MenuItem>
          {loading ? null : (
            <Stack direction="column" className="m-l-20 m-t-20 m-r-20">
              <Stack style={{ marginBottom: 10 }}>
                <InputLabel>NAME</InputLabel>
                <Typography> {profileDetails.firstName + " " + profileDetails.lastName}</Typography>
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <InputLabel>USER ROLE</InputLabel>
                <Typography>{profileDetails.primaryRole}</Typography>
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <InputLabel>PRACTICE ASSOCIATION</InputLabel>
                {practiceList &&
                  practiceList.map((item, index) => (
                    <Typography key={index}>
                      {index + 1}) {item.practiceLongName}{" "}
                    </Typography>
                  ))}
              </Stack>

              <Divider className="div-finS" />
            </Stack>
          )}
          <Button
            onClick={() => navigate("/logout")}
            style={{ justifyContent: "flex-start", fontSize: "18px", marginLeft: "15px" }}>
            LOG OUT
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
const mapStateToProps = ({ profileDrawerReducer }) => {
  return {
    profileDetails: profileDrawerReducer.profileDetails,
    loading: profileDrawerReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getUserProfileDetails: (payload) => {
      dispatch(actionType.getUserProfileDetails(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDrawer);
