import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Stack,
  Button,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  CircularProgress,
  Modal,
  FormLabel
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Close from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import EmailDocumentModal from "../../trak/components/modals/EmailDocumentModal";
import {
  ACKNOWLEDGE_TEXT,
  // CLINICAL_DECISION,
  REQUESTED_SERVICES,
  // OUTCOME_DATA,
  // OUTCOME_OPTION_DATA,
  // ADITIONAL_SERVICES,
  getOutcomeFormData,
  MSG
} from "../../shared/constants/constants";
import * as actionType from "../../../modules/workspace/actions";
import * as actionTypeTrack from "../../trak/actions";
import { connect } from "react-redux";
import moment from "moment";
import MessageModal from "../../shared/components/MessageModal";
import WarningPopUp from "../../trak/components/modals/WarningPopUp";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const FelixOutcomeForm = (props) => {
  const {
    clinicalDetails = {},
    treatmentPlanList,
    actions,
    patientMedcaseInfo,
    medcaseInfoLoading,
    // patientId,
    selectedPatient,
    isOpen,
    isViewOnly,
    success,
    successMsg,
    errorEmail,
    patientUser
  } = props;

  const columns = [
    {
      field: "stageName",
      headerName: "STAGE",
      width: 50,
      flex: 0.5,
      sortable: false
    },
    {
      field: "stageCompleted",
      headerName: "STAGE COMPLETE",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Checkbox
          disabled={isViewOnly || row.disabed}
          checked={row.stageCompleted}
          onChange={(e) => handleStagePerformedData(e, row, "stageCompleted")}
        />
      )
    },
    {
      field: "stageCancelled",
      headerName: "CYCLE CANCELLED AFTER",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Checkbox
          disabled={isViewOnly || row.disabed}
          checked={row.stageCancelled}
          onChange={(e) => handleStagePerformedData(e, row, "stageCancelled")}
        />
      )
    },
    {
      field: "dateRecorded",
      headerName: "DATE RECORDED",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="o-date-picker"
            name="recordedDate"
            value={row?.dateRecorded}
            disabled={isViewOnly || row.disabed}
            onChange={(e) => handleStagePerformedData(e, row, "dateRecorded")}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )
    }
  ];
  const accordionSx = { bgcolor: "#ededed", m: 2, flexGrow: 1 };
  const [rows, setRows] = useState([]);
  const [fapList, setFapList] = useState({});
  const [clinicalData, setClinicalData] = useState({});
  const [additionalField, setAdditionalField] = useState({});
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  // const [isTransfer, setIsTransfer] = useState(false);
  // const [openEmailDocument, setOpenEmailDocument] = useState(false);
  const practiceId = localStorage.getItem("practiceId");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [submitButtonType, setSubmitButtonType] = useState("");
  const [normalEmbryosRemainingModal, setNormalEmbryosRemainingModal] = useState(false);
  // const [commentText, setCommentText] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [authExpDateChange, setAuthExpDateChange] = useState(false);
  const [authMessage, setAuthMessage] = useState(false);
  const [isOpenForExpDate, setIsOpenForExpDate] = useState(false);
  const [isNormalFrozenEmbroysRemainingEmpty, setIsNormalFrozenEmbroysRemainingEmpty] =
    useState(false);
  const [requestAuthClicked, setRequestAuthClicked] = useState(false);

  let preOutcomeCategory = null;
  const outcomeCategories = [
    "No Status",
    "Cycle Canceled",
    "Not Pregnant",
    "Not Pregnant - Miscarried",
    "Not Pregnant - Biochemical",
    "Not Pregnant - Ectopic",
    "Not Pregnant - Freeze All",
    "Not Pregnant - No Transfer",
    "Not Pregnant - Still born",
    "Not Pregnant - Loss of Newborn",
    "Pregnant"
  ];

  useEffect(() => {
    if (success === MSG.SAVED_SUCCESSFULLY) {
      if (
        clinicalData &&
        clinicalData.outcomeCategory &&
        clinicalData.outcomeCategory != preOutcomeCategory &&
        submitButtonType !== "SaveAndClose"
      ) {
        //setOpenEmailDocument(true);
        if (!requestAuthClicked) sendEmail();
      } else {
        // handleCloseModal();
      }
    }
  }, [success]);

  const sendEmailRequestAuth = () => {
    let clinicalTreatmentNo = clinicalData && clinicalData.clinicalTreatmentNo;
    let authorizedTreatment = clinicalData && clinicalData.authorizedTpName;
    let isOutcomeEmail = true;
    let clinicalTreatmentId = selectedPatient?.clinicalTreatmentTd;
    let mdCaseId = selectedPatient.medcaseID;

    actions.postEmailOption({
      medcaseId: isOutcomeEmail ? clinicalTreatmentId : mdCaseId,
      toAddresses: [],
      cycle: clinicalTreatmentNo,
      emailType: "OUTCOME_SUBMITTED_WITH_REQUESTED_SERVICE",
      requestedService: authorizedTreatment,
      outcomeRequestedService: isOutcomeEmail ? additionalField.requestedService : null,
      verifiedNormalEmbryosRemaining: additionalField?.verifiedNormalEmbryosRemaining
    });

    return "emailsend";
  };

  const sendEmail = () => {
    let clinicalTreatmentNo = clinicalData && clinicalData.clinicalTreatmentNo;
    let authorizedTreatment = clinicalData && clinicalData.authorizedTpName;
    let isOutcomeEmail = true;
    let clinicalTreatmentId = selectedPatient?.clinicalTreatmentTd;
    let mdCaseId = selectedPatient.medcaseID;

    actions.postEmailOption({
      medcaseId: isOutcomeEmail ? clinicalTreatmentId : mdCaseId,
      toAddresses: [],
      cycle: clinicalTreatmentNo,
      emailType: isOutcomeEmail
        ? "OUTCOME_SUBMITTED"
        : props.emailType
        ? props.emailType
        : "AUTHORIZATION",
      requestedService: authorizedTreatment,
      outcomeRequestedService: isOutcomeEmail ? additionalField.requestedService : null
    });

    return "emailsend";
  };
  const disableFormData = () => {
    return isViewOnly ? true : false;
  };

  // const closeEmailDocument = () => {
  //   handleCloseModal();
  //   setOpenEmailDocument(false);
  //   actions.resetMessage();
  // };

  const disableFormSubmit = () => {
    let isDisable = false;

    if (clinicalData.outcomeDate && clinicalData.outcomeCategory && isAcknowledge) {
      isDisable = false;
    }

    if (
      clinicalData.outcomeCategory === "Cycle Canceled" &&
      !additionalField.retrievalCycle &&
      !additionalField.fetCycle
    ) {
      isDisable = true;
    }

    if (clinicalData.outcomeCategory === "Miscarried" && !clinicalData.miscarrigeDate) {
      isDisable = true;
    }

    if (clinicalData.cycleStartDate && clinicalData.retrievalDate && !clinicalData.performedTp) {
      isDisable = true;
    }

    if (valideAuthExpDateFromClinicalDetails()) {
      console.log("exp date");
      isDisable = true;
    }

    console.log("isDisabled", isDisable);
    return isDisable;
  };

  useEffect(() => {
    if (selectedPatient && selectedPatient.patientId) {
      setFapList(selectedPatient);
      // if (Object.values(clinicalDetails).length == 0) {
      let payload = {
        medcaseId: selectedPatient.medcaseID,
        clinicalTreatmentId: selectedPatient.clinicalTreatmentTd
      };
      actions.getClinicalData(payload);
      actions.getPatientMedcaseInfo(payload);
      // }
    }
  }, [selectedPatient /* patientId */]);

  useEffect(() => {
    console.log("clinicalDetails", clinicalDetails);

    if (clinicalDetails.gdmId != null) {
      let data = getOutcomeFormData(clinicalDetails, "get");
      console.log("clinicalDetails data", data);
      setClinicalData(data);
      // setAdditionalField(clinicalDetails.clinicalTreatmentAdditionalField);
      setAdditionalField(data.clinicalTreatmentAdditionalField);
      //if (treatmentPlanList == undefined) {
      let payload = { practiceId, gdmId: data.gdmId };
      actions.getTreatmentPlanList(payload);
      //}

      preOutcomeCategory = preOutcomeCategory || data?.outcomeCategory;

      // if (data?.clinicalTreatmentAdditionalField) {
      //   setCommentText(data?.clinicalTreatmentAdditionalField?.comment);
      // }
    }
    if (clinicalDetails.clinicalTreatmentStages) {
      let rows = clinicalDetails.clinicalTreatmentStages.map((item, index) => {
        // if (item.stageName == "Transfer" && item.stageCompleted) {
        //   setIsTransfer(true);
        // }
        item.id = index;
        return item;
      });
      setRows(rows);
    }
  }, [clinicalDetails]);

  const populateRecordedDate = (e, row, type) => {
    if (type === "completed") {
      calculatetsgeComplted(e, row);
    } else if (type === "cancelled") {
      calculatetsgeCancelled(e, row);
    }
  };

  const calculatetsgeComplted = (e, row) => {
    let currentStage = false;
    const isChecked = e.target.checked;
    setRows((prevRows) =>
      prevRows.map((el) => {
        if (!currentStage) {
          el.stageCompleted = isChecked;
          el.disabled = false;
          if (isChecked) {
            el.dateRecorded = isChecked ? moment().format("YYYY-MM-DD") : null;
          } else {
            el.dateRecorded = null;
          }
        }

        if (el.stageId === row.stageId) {
          currentStage = true;
          if (isChecked) {
            el.dateRecorded = isChecked ? moment().format("YYYY-MM-DD") : null;
          } else {
            el.dateRecorded = null;
          }
        }

        return el;
      })
    );
    return "hi";
  };
  const calculatetsgeCancelled = (e, row) => {
    const isChecked = e.target.checked;
    let currentStage = false;
    setRows((prevRows) =>
      prevRows.map((el) => {
        if (!currentStage) {
          el.stageCompleted = isChecked;
          el.stageCancelled = false;
          el.disabed = isChecked ? true : false;
          el.dateRecorded = isChecked ? moment().format("YYYY-MM-DD") : null;
        }

        if (el.stageId === row.stageId) {
          currentStage = true;
          el.stageCompleted = false;
          el.stageCancelled = isChecked;
          el.disabed = false;
          el.dateRecorded = isChecked ? moment().format("YYYY-MM-DD") : null;
        }

        return el;
      })
    );
    return "hi";
  };

  const handleStagePerformedData = (e, row, name) => {
    if (name == "dateRecorded") {
      setRows((prevRows) =>
        prevRows.map((item) => {
          if (item.stageId == row.stageId) {
            item.dateRecorded = moment(e.$d).format("YYYY-MM-DD");

            if (row.stageName == "Retrieval" && clinicalData.outcomeCategory == "Pregnant") {
              setClinicalData({
                ...clinicalData
                // edcDate: moment(e.$d).add(266, "days").format("YYYY-MM-DD")
              });
            }
          }
          return item;
        })
      );
    } else {
      setRows((prevRows) =>
        prevRows.map((item) => {
          if (item.stageId == row.stageId) {
            const isCheck = e.target.checked;
            item[name] = isCheck;

            if (name == "stageCompleted") {
              if (isCheck) {
                item.dateRecorded = item.dateRecorded ?? moment().format("YYYY-MM-DD");

                if (row.stageName == "Retrieval" && clinicalData.outcomeCategory == "Pregnant") {
                  setClinicalData({
                    ...clinicalData
                    // edcDate: moment(item.dateRecorded).add(266, "days").format("YYYY-MM-DD")
                  });
                }
              }
              populateRecordedDate(e, row, "completed");
            } else {
              item.dateRecorded = isCheck
                ? item.dateRecorded ?? moment().format("YYYY-MM-DD")
                : null;
              populateRecordedDate(e, row, "cancelled");
            }
          }
          return item;
        })
      );
    }
  };

  const handleCloseModal = () => {
    props.close();
  };

  const handleClinicalDataChange = (e, name, type) => {
    if (type == "date") {
      setClinicalData({ ...clinicalData, [name]: e?.$d ? moment(e.$d).format("YYYY-MM-DD") : "" });
    } else if (name == "performedServiceICSI") {
      setClinicalData({
        ...clinicalData,
        cycleDetail: {
          ...clinicalData.cycleDetail,
          icsi: e.target.checked
        }
      });
    } else if (name == "performedServicePGTA") {
      setClinicalData({
        ...clinicalData,
        cycleDetail: {
          ...clinicalData.cycleDetail,
          pgta: e.target.checked
        }
      });
    } else if (name == "performedServicePGTM") {
      setClinicalData({
        ...clinicalData,
        cycleDetail: {
          ...clinicalData.cycleDetail,
          pgtm: e.target.checked
        }
      });
    } else if (name == "performedServicePGTSR") {
      setClinicalData({
        ...clinicalData,
        cycleDetail: {
          ...clinicalData.cycleDetail,
          pgtsr: e.target.checked
        }
      });
    } else if (name == "outcomeCategory") {
      const { name, value } = e.target;
      console.log("clinicalData.outcomeCategory:", clinicalData.outcomeCategory);
      if (
        valideAuthExpDateFromClinicalData() &&
        (clinicalData.outcomeCategory === "No Status" || outcomeCategories[0])
      ) {
        setIsOpenForExpDate(true);
      } else {
        if (value === "Pregnant") {
          if (clinicalData.retrievalDate) {
            // let newDate = new Date(clinicalData.retrievalDate);
            // let drDate = newDate.setDate(newDate.getDate() + 266);
            setClinicalData({
              ...clinicalData,
              [name]: value
              // edcDate: moment(drDate).format("YYYY-MM-DD")
            });
          } else {
            setClinicalData({ ...clinicalData, [name]: value /* edcDate: null */ });
          }
        } else {
          setClinicalData({ ...clinicalData, [name]: value /* edcDate: null */ });
        }
      }
    } else {
      const { name, value } = e.target;
      setClinicalData({ ...clinicalData, [name]: value });
    }
  };

  const handleAdditionalFieldChange = (e, Name, typeCheck) => {
    if (typeCheck == "date") {
      setAdditionalField({ ...additionalField, [Name]: moment(e.$d).format("YYYY-MM-DD") });
    } else {
      const { name, type, value } = e.target;
      if (type == "checkbox") {
        setAdditionalField({
          ...additionalField,
          [name]: e.target.checked
        });
      } else {
        setAdditionalField({
          ...additionalField,
          [name]: value
        });
      }
      if (name == "requestedService") {
        setNormalEmbryosRemainingModal(true);
      }
    }
  };

  const outcomeFormSubmitHandler = (btnType) => {
    if (authExpDateChange && !clinicalData?.cycleStartDate) {
      setAuthMessage(true);
      return;
    }

    // normalFrozenEmbroysRemaining check if != null
    if (btnType === "SaveAndSubmit") {
      if (additionalField?.requestedService && !additionalField?.normalFrozenEmbroysRemaining) {
        setIsNormalFrozenEmbroysRemainingEmpty(true);
        return;
      } else {
        setIsNormalFrozenEmbroysRemainingEmpty(false);
      }
    }

    let stageRows = [];
    rows.map((item) => {
      stageRows.push({
        stageId: item.stageId,
        stageName: item.stageName,
        stageCompleted: item.stageCompleted,
        stageCancelled: item.stageCancelled,
        dateRecorded: item.dateRecorded
      });
    });

    console.log("zhr additionalField", additionalField);

    let payload = {
      medcaseId: selectedPatient.medcaseID,
      clinicalTreatmentId: selectedPatient.clinicalTreatmentTd,
      formData: getOutcomeFormData(
        {
          ...clinicalData,
          clinicalTreatmentAdditionalField: additionalField,
          clinicalTreatmentStages: stageRows
        },
        "save"
      ),
      formButtonType: btnType
    };
    setSubmitButtonType(btnType);
    actions.updateOutcomeFormDetails(payload);
    // handleCloseModal();
  };

  useEffect(() => {
    if (successMsg && successMsg !== "") {
      setIsMessageModal(true);
    }
  }, [successMsg]);
  useEffect(() => {
    if (success && success !== "") {
      setIsMessageModal(true);
    }
  }, [success]);
  useEffect(() => {
    // console.log("errorEmail", errorEmail);
    if (errorEmail && errorEmail !== "") {
      setIsWarningOpen(true);
    }
  }, [errorEmail]);

  const closeMessageModal = () => {
    //console.log("hey");
    setIsMessageModal(false);

    actions.resetPostEmail();
    actions.resetEmail();
    actions.resetMessage();
    props.close();
  };

  const handleCloseWarning = () => {
    //console.log("hey warning");
    setIsWarningOpen(false);

    actions.resetPostEmail();
    actions.resetEmail();
    props.close();
  };

  let errorMessage;
  if (errorEmail && errorEmail.includes("not verified")) {
    errorMessage = "Email is not verified";
  } else {
    errorMessage = errorEmail;
  }
  // console.log("isOpen", isOpen);

  const textareaStyle = {
    width: "100%",
    height: "180px",
    padding: "10px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    backgroundColor: "background.paper",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "&:focus": {
      outline: "none",
      borderColor: "primary.main"
    },
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)"
    },
    outline: "none"
  };

  // function validateContExpDate() {
  //   const { contExpDate } = clinicalDetails || {};

  //   if (contExpDate) {
  //     const currentDate = new Date();
  //     const expiryDate = new Date(contExpDate);
  //     const isExpired = expiryDate < currentDate;
  //     console.log("zhr contExpDate", isExpired);
  //     return isExpired;
  //   }

  //   return false;
  // }

  function canEditAuthorizationExpirationDate(
    enrolledProgram,
    treatment,
    medcaseContractExpirationDate
  ) {
    console.log("==> medcaseContractExpirationDate", medcaseContractExpirationDate);
    let isDisable = true;
    // Check if enrolled program is not a refund program
    if (enrolledProgram == 0) {
      // Check if treatment is IVF or FET
      if (treatment === "IVF" || treatment === "FET") {
        // Check if medcase contract expiration date is greater than today
        //const today = new Date();
        if (medcaseContractExpirationDate) {
          const currentDate = new Date();
          const expiryDate = new Date(medcaseContractExpirationDate);
          const isExpired = expiryDate > currentDate;
          console.log("zhr contExpDate", isExpired);
          isDisable = false;
        }
      }
    }
    console.log("isDisable", isDisable);
    // If any condition is not met, return true
    return isDisable;
  }

  function valideAuthExpDateFromClinicalDetails() {
    const { authExpDate } = clinicalDetails || {};

    if (authExpDate) {
      const currentDate = new Date();
      const expiryDate = new Date(authExpDate);
      const isExpired = expiryDate < currentDate;
      console.log("zhr Check", isExpired);
      return isExpired;
    }

    return false;
  }

  function valideAuthExpDateFromClinicalData() {
    const { authExpDate } = clinicalData || {};

    if (authExpDate) {
      const currentDate = new Date();
      const expiryDate = new Date(authExpDate);
      const isExpired = expiryDate < currentDate;
      console.log("zhr Check", isExpired);
      return isExpired;
    }

    return false;
  }

  const handleDateChange = (e) => {
    const newDate = e.$d;
    const currentDate = clinicalData?.authExpDate ? moment(clinicalData.authExpDate) : moment();
    const maxDate = currentDate.clone().add(30, "days");

    if (moment(newDate).isBefore(maxDate)) {
      handleClinicalDataChange(e, "authExpDate", "date");
      setAuthExpDateChange(true);
    } else {
      alert("Authorization Expiration Date can only be extended an additional 30 days.");
    }
  };

  return (
    <Box>
      <Modal open={isOpen} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style outcome-form" sx={{ width: "80%", maxHeight: "85%" /* 700 */ }}>
          <Stack direction="row" className="modal-stack">
            <Typography>Outcome</Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <>
            {medcaseInfoLoading ? (
              <Stack justifyContent="center" alignItems="center">
                <CircularProgress size={50} />
              </Stack>
            ) : (
              <>
                <Box className="pad-5">
                  <Grid container item xs={12} spacing={2} className="m-t-5">
                    <Grid item xs={9}>
                      <Typography>Patient Details</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2} className="m-t-0" marginBottom="15px">
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Patient Name</InputLabel>
                        <Typography>
                          {fapList.patientName
                            ? fapList.patientName
                            : patientUser.firstName + " " + patientUser.lastName}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Person No</InputLabel>
                        <Typography>
                          {fapList.personKey ? fapList.personKey : patientUser.personKey}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Medcase ID</InputLabel>
                        <Typography>{fapList.medcaseID}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>DOB</InputLabel>
                        <Typography>
                          (
                          {fapList?.patientBirthDate
                            ? fapList?.patientBirthDate
                            : patientUser.birthDate}
                          )
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Practice</InputLabel>
                        <Typography>
                          {patientMedcaseInfo?.practice ? patientMedcaseInfo.practice : "--"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Location</InputLabel>
                        <Typography>
                          {patientMedcaseInfo?.location ? patientMedcaseInfo.location : "--"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Physician</InputLabel>
                        <Typography>
                          {patientMedcaseInfo?.physicianFirstName
                            ? patientMedcaseInfo.physicianFirstName +
                              " " +
                              patientMedcaseInfo.physicianLastName
                            : "--"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Location Contact</InputLabel>
                        <Typography>
                          {patientMedcaseInfo?.locationContactFirstName
                            ? patientMedcaseInfo.locationContactFirstName +
                              " " +
                              patientMedcaseInfo.locationContactLastName
                            : "--"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Enrolled Program</InputLabel>
                        <Typography>
                          {clinicalData.program ? clinicalData.program : "--"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12 / 5}>
                      <Stack direction="column">
                        <InputLabel>Enrolled Treatment Plan</InputLabel>
                        <Typography>
                          {clinicalData?.authorizedTpNameCustom != null
                            ? clinicalData.authorizedTpNameCustom
                            : clinicalData.authorizedTpName
                            ? clinicalData.authorizedTpName
                            : "--"}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="pad-5 m-t-10">
                  <Grid container item xs={12} spacing={2}>
                    <Accordion sx={accordionSx} defaultExpanded={true}>
                      <AccordionSummary
                        sx={{ flexDirection: "row-reverse", flexGrow: 0 }}
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography>Cycle {clinicalData.clinicalTreatmentNo}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className="bg-white pad-10">
                          <Typography>Cycle Information</Typography>
                          <Grid container item xs={12} spacing={2} marginTop="5px">
                            <Grid item xs={2}>
                              <Stack direction="column">
                                <InputLabel>Authorized Treatment Plan</InputLabel>
                                <Typography>
                                  {clinicalData.authorizedTpName
                                    ? clinicalData.authorizedTpName
                                    : "--"}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack direction="column">
                                <InputLabel>Performed Treatment Plan</InputLabel>
                                <Select
                                  name="performedTp"
                                  fullWidth
                                  value={clinicalData?.performedTp ?? ""}
                                  onChange={handleClinicalDataChange}
                                  disabled={isViewOnly}>
                                  {treatmentPlanList &&
                                    treatmentPlanList.map((item, index) => {
                                      return (
                                        <MenuItem value={item.treatmentPlanId} key={index}>
                                          {item.treatmentPlanName}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack direction="column">
                                <InputLabel>Program</InputLabel>
                                <Typography>
                                  {clinicalData.program ? clinicalData.program : "--"}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack direction="column">
                                <InputLabel>Enrollment Date</InputLabel>
                                <Typography>
                                  {clinicalData.enrollmentDate
                                    ? moment(clinicalData?.enrollmentDate).format("L")
                                    : "--"}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack direction="column">
                                <InputLabel>Authorization Date</InputLabel>
                                <Typography>
                                  {clinicalData?.authDate
                                    ? moment(clinicalData?.authDate).format("L")
                                    : "--"}
                                </Typography>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    className="o-date-picker"
                                    name="authDate"
                                    value={clinicalData.authDate}
                                    onChange={(e) => handleClinicalDataChange(e, "authDate", "date")}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider> */}
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack direction="column">
                                <InputLabel>Auth exp Date</InputLabel>
                                {/* <Typography>
                                  {clinicalData?.authExpDate
                                    ? moment(clinicalData?.authExpDate).format("L")
                                    : "--"}
                                </Typography> */}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  {console.log(
                                    "==>",
                                    clinicalDetails
                                    //validateContExpDate(clinicalDetails?.contExpDate)
                                  )}
                                  {console.log("==>", clinicalDetails?.treatment)}
                                  {console.log("==>", clinicalDetails?.programRefundType)}
                                  <DatePicker
                                    className="o-date-picker"
                                    name="authExpDate"
                                    value={clinicalData?.authExpDate}
                                    // onChange={(e) => {
                                    //   handleClinicalDataChange(e, "authExpDate", "date");
                                    //   setAuthExpDateChange(true);
                                    // }}
                                    onChange={(date) => handleDateChange(date)}
                                    disabled={
                                      canEditAuthorizationExpirationDate(
                                        clinicalDetails?.programRefundType,
                                        clinicalDetails?.treatment,
                                        clinicalDetails?.contExpDate
                                      )
                                      // validateContExpDate(clinicalDetails?.contExpDate) ||
                                      // (clinicalDetails?.treatment !== "IVF" &&
                                      //   clinicalDetails?.treatment !== "FET") ||
                                      // clinicalDetails?.programRefundType != "0"
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                            {/* <Grid item xs={3}>
                              <Stack direction="column">
                                <InputLabel>Forecast Date</InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    openTo="day"
                                    name="forecastDate"
                                    value={clinicalData?.forecastDate}
                                    onChange={handleClinicalDataChange}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" sx={{ flex: 8 }} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                            <Grid item xs={3}>
                              <Stack direction="column">
                                <InputLabel>Forecast Event</InputLabel>
                                <Select
                                  name="forecastEvent"
                                  value={clinicalData?.forecastEvent}
                                  className="patient-dropdown"
                                  onChange={handleClinicalDataChange}>
                                  {forecastEventList?.map((item, index) => {
                                    return (
                                      <MenuItem value={item} key={"FE-" + index}>
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Stack>
                            </Grid> */}
                          </Grid>
                          <Stack direction="row" marginTop="10px">
                            <Typography>
                              Outcome
                              <Box component="span" className="asteriks">
                                *
                              </Box>{" "}
                              {/* -{clinicalData.outcomeCategory}{" "} */}
                            </Typography>
                            <Stack direction="row">
                              {clinicalData.outcomeDescription && (
                                <Typography>({clinicalData.outcomeDescription})</Typography>
                              )}
                            </Stack>
                          </Stack>
                          <Divider className="modal-divider m-t-5" />
                          <Box>
                            <FormControl>
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                style={{ paddingTop: "8px" }}>
                                <Grid item xs={8}>
                                  <Select
                                    name="outcomeCategory"
                                    sx={{ flex: 6, height: 35, width: 350 }}
                                    value={clinicalData.outcomeCategory || outcomeCategories[0]}
                                    onChange={(e) =>
                                      handleClinicalDataChange(e, "outcomeCategory", "select")
                                    }
                                    disabled={isViewOnly && preOutcomeCategory != "Pregnant"}>
                                    {outcomeCategories.map((category, index) => {
                                      return (
                                        <MenuItem
                                          value={category}
                                          key={"cat" + index}
                                          disabled={
                                            preOutcomeCategory &&
                                            preOutcomeCategory == "Pregnant" &&
                                            category != "Miscarried"
                                          }>
                                          {category}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </Grid>
                              </Grid>
                            </FormControl>

                            <Divider className="modal-divider m-t-10" /* marginTop="15px" */ />

                            {clinicalData?.outcomeCategory === "Cycle Canceled" ? (
                              <>
                                {clinicalData?.clinicalCycleType == "IVF" ? (
                                  <Grid container item xs={12} className="item-center">
                                    <Grid item xs={2}>
                                      <Typography>Retrieval Cycle:</Typography>
                                    </Grid>

                                    <RadioGroup
                                      row
                                      name="retrievalCycle"
                                      value={additionalField.retrievalCycle}
                                      onChange={handleAdditionalFieldChange}>
                                      <FormControlLabel
                                        value="Patient Paid for Monitoring/IUI"
                                        control={<Radio disabled={isViewOnly} />}
                                        label="Patient Paid for Monitoring/IUI"
                                      />
                                      <FormControlLabel
                                        value="Fertility Access to pay for Monitoring/IUI*"
                                        control={<Radio disabled={isViewOnly} />}
                                        label="Fertility Access to pay for Monitoring/IUI*"
                                      />
                                      <FormControlLabel
                                        value="Cycle never started"
                                        control={<Radio disabled={isViewOnly} />}
                                        label="Cycle never started"
                                      />
                                    </RadioGroup>
                                  </Grid>
                                ) : null}

                                {clinicalData?.clinicalCycleType == "FET" ? (
                                  <Grid container item xs={12} className="item-center">
                                    <Grid item xs={2}>
                                      <Typography>FET Cycle:</Typography>
                                    </Grid>

                                    <RadioGroup
                                      row
                                      name="fetCycle"
                                      value={additionalField.fetCycle}
                                      onChange={handleAdditionalFieldChange}>
                                      <FormControlLabel
                                        value="Services Performed"
                                        control={<Radio disabled={isViewOnly} />}
                                        label="Services Performed"
                                      />
                                      <FormControlLabel
                                        value="No Services Performed"
                                        control={<Radio disabled={isViewOnly} />}
                                        label="No Services Performed"
                                      />
                                      <FormControlLabel
                                        value="Cycle never started"
                                        control={<Radio disabled={isViewOnly} />}
                                        label="Cycle never started"
                                      />
                                    </RadioGroup>
                                  </Grid>
                                ) : null}

                                {clinicalData?.clinicalCycleType == "IVF" ? (
                                  <Grid container item xs={12}>
                                    <Typography>
                                      *If Fertility Access is paying for the services, this will
                                      count as a retrieval cycle in the Program.
                                    </Typography>
                                  </Grid>
                                ) : null}

                                <Divider className="modal-divider m-t-10" marginTop="15px" />
                              </>
                            ) : null}

                            {/* <Grid container item xs={12} className="item-center" marginTop="10px">
                              <Grid item xs={2}>
                                <Typography>Additional Service</Typography>
                              </Grid>
                              {ADITIONAL_SERVICES.map((item, index) => {
                                return (
                                  <Grid item xs={2} key={index}>
                                    <FormControlLabel
                                      control={<Checkbox name={item.name} disabled={isViewOnly} />}
                                      label={item.label}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid> */}
                          </Box>
                          <Stack direction="row" className="modal-stack">
                            <Grid container item xs={12} spacing={2} className="item-center">
                              <Grid item xs={2}>
                                <Typography>
                                  Outcome Date
                                  <Box component="span" className="asteriks">
                                    *
                                  </Box>
                                </Typography>
                              </Grid>
                              <Grid item xs={4} mb={1}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    className="o-date-picker"
                                    name="outcomeDate"
                                    value={clinicalData.outcomeDate}
                                    onChange={(e) =>
                                      handleClinicalDataChange(e, "outcomeDate", "date")
                                    }
                                    disabled={
                                      isViewOnly || clinicalData?.outcomeCategory
                                        ? clinicalData?.outcomeCategory == outcomeCategories[0]
                                          ? false
                                          : true
                                        : false
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>

                              {/* <Grid item xs={2}>
                                <Typography>
                                  EDC Date
                                  {clinicalData.outcomeCategory == "Pregnant" && (
                                    <Box component="span" className="asteriks">
                                      *
                                    </Box>
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    className="o-date-picker"
                                    name="edcDate"
                                    value={clinicalData?.edcDate}
                                    disabled={
                                      isViewOnly ||
                                      clinicalData.outcomeCategory !== "Pregnant" ||
                                      clinicalData.outcomeCategory == "Cycle Cancelled"
                                    }
                                    onChange={(e) => handleClinicalDataChange(e, "edcDate", "date")}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid> */}
                              <Grid item xs={2}>
                                <Typography>
                                  Miscarriage Date
                                  {clinicalData.outcomeCategory == "Miscarried" &&
                                    clinicalData.outcomeCategory == "Not Pregnant - Miscarried" &&
                                    clinicalData.outcomeCategory == "Pregnant" && (
                                      <Box component="span" className="asteriks">
                                        *
                                      </Box>
                                    )}
                                </Typography>
                              </Grid>

                              <Grid item xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    className="o-date-picker"
                                    name="miscarrigeDate"
                                    value={clinicalData?.miscarrigeDate}
                                    disabled={
                                      clinicalData.outcomeCategory !==
                                        "Not Pregnant - Miscarried" &&
                                      clinicalData.outcomeCategory !== "Cycle Canceled" &&
                                      clinicalData.outcomeCategory !== "Pregnant"
                                    }
                                    onChange={(e) =>
                                      handleClinicalDataChange(e, "miscarrigeDate", "date")
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Stack>
                          <Stack direction="row" className="modal-stack ">
                            <Typography sx={{ fontSize: "15px", marginTop: "10px" }}>
                              Stages Performed
                              <Box component="span" className="asteriks">
                                *
                              </Box>
                            </Typography>
                          </Stack>
                          <Box style={{ height: "100%", width: "100%", paddingTop: "10px" }}>
                            <DataGridPremiumComp
                              columns={columns}
                              rows={rows}
                              rowCount={rows?.length}
                              getRowId={() => uuidv4()}
                              autoHeight={true}
                              pageSizeOptions={[10, 25, 50, 100]}
                              pageSize={pageSize.pageSize}
                              paginationModel={pageSize}
                              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                              pagination
                            />
                          </Box>
                          <Typography>Comments</Typography>
                          <Divider className="modal-divider" />
                          <Box sx={{ mt: 2, p: 2 }}>
                            <textarea
                              name="comment"
                              value={additionalField?.comment}
                              onChange={handleAdditionalFieldChange}
                              style={textareaStyle}
                            />
                          </Box>

                          <Stack direction="row" className="modal-stack m-t-15">
                            <Typography sx={{ fontSize: "16px", marginBottom: "5px" }}>
                              Cycle Details
                            </Typography>
                          </Stack>
                          <Divider className="modal-divider" />
                          <Box className="m-t-5">
                            <Grid container item xs={12} spacing={2} className="item-center m-t-10">
                              <Grid item xs={1}>
                                <Typography>
                                  Cycle Start Date*
                                  {clinicalData.outcomeCategory == "Pregnant" ||
                                    clinicalData.outcomeCategory == "Not Pregnant" ||
                                    clinicalData.outcomeCategory == "Miscarried" ||
                                    clinicalData.outcomeCategory == "Cycle Canceled" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Miscarried" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Biochemical" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Ectopic" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Freeze All" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - No Transfer" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Still born" ||
                                    clinicalData.outcomeCategory ==
                                      "Not Pregnant - Loss of Newborn"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    openTo="day"
                                    name="cycleStartDate"
                                    disabled={disableFormData(clinicalData)}
                                    required={authExpDateChange}
                                    value={clinicalData?.cycleStartDate}
                                    onChange={(e) =>
                                      handleClinicalDataChange(e, "cycleStartDate", "date")
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" sx={{ flex: 8 }} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={1}>
                                <Typography>
                                  Retrieval Date*
                                  {(clinicalData.outcomeCategory == "Pregnant" ||
                                    clinicalData.outcomeCategory == "Not Pregnant" ||
                                    clinicalData.outcomeCategory == "Miscarried" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Miscarried" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Biochemical" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Ectopic" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Freeze All" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - No Transfer" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Still born" ||
                                    clinicalData.outcomeCategory ==
                                      "Not Pregnant - Loss of Newborn") &&
                                    clinicalData?.authorizedTpName != "FET"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    openTo="day"
                                    name="retrievalDate"
                                    disabled={disableFormData(clinicalData)}
                                    value={clinicalData?.retrievalDate}
                                    onChange={(e) => {
                                      handleClinicalDataChange(e, "retrievalDate", "date");
                                      /* if (clinicalData.outcomeCategory == "Pregnant") {
                                        let edcDate_ = null;
                                        if (e) {
                                          let newDate_ = new Date(e);
                                          edcDate_ = newDate_.setDate(newDate_.getDate() + 266);
                                        }
                                        setClinicalData({
                                          ...clinicalData,
                                          edcDate: moment(edcDate_).format("YYYY-MM-DD")
                                        });
                                      } */
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" sx={{ flex: 8 }} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={1}>
                                <Typography>
                                  Transfer Date
                                  {(clinicalData.outcomeCategory == "Pregnant" ||
                                    clinicalData.outcomeCategory == "Not Pregnant" ||
                                    clinicalData.outcomeCategory == "Miscarried" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Miscarried" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Biochemical" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Ectopic" ||
                                    clinicalData.outcomeCategory == "Not Pregnant - Still born" ||
                                    clinicalData.outcomeCategory ==
                                      "Not Pregnant - Loss of Newborn") &&
                                    "**"}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    openTo="day"
                                    name="transferDate"
                                    disabled={disableFormData(clinicalData)}
                                    value={clinicalData?.transferDate}
                                    onChange={(e) =>
                                      handleClinicalDataChange(e, "transferDate", "date")
                                    }
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" sx={{ flex: 8 }} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={12}
                              className="item-center"
                              style={{ marginTop: "15px" }}>
                              <Grid item xs={2}>
                                <Typography>Performed Service</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="performedServiceICSI"
                                      onClick={(e) =>
                                        handleClinicalDataChange(e, "performedServiceICSI", "")
                                      }
                                      checked={
                                        clinicalData?.authorizedTpName != "FET"
                                          ? clinicalData?.cycleDetail?.icsi
                                            ? true
                                            : false
                                          : false
                                      }
                                      disabled={clinicalData?.authorizedTpName == "FET"}
                                      /* disabled={disableFormData(clinicalData)} */
                                    />
                                  }
                                  label="ICSI"
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="performedServicePGTA"
                                      onClick={(e) =>
                                        handleClinicalDataChange(e, "performedServicePGTA", "")
                                      }
                                      checked={
                                        clinicalData?.authorizedTpName != "FET"
                                          ? clinicalData?.cycleDetail?.pgta
                                            ? true
                                            : false
                                          : false
                                      }
                                      disabled={clinicalData?.authorizedTpName == "FET"}
                                      /* disabled={disableFormData(clinicalData)} */
                                    />
                                  }
                                  label="PGT-A"
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="performedServicePGTM"
                                      onClick={(e) =>
                                        handleClinicalDataChange(e, "performedServicePGTM", "")
                                      }
                                      checked={clinicalData?.cycleDetail?.pgtm ? true : false}
                                      /* disabled={disableFormData(clinicalData)} */
                                    />
                                  }
                                  label="PGT-M"
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="performedServicePGTSR"
                                      onClick={(e) =>
                                        handleClinicalDataChange(e, "performedServicePGTSR", "")
                                      }
                                      checked={clinicalData?.cycleDetail?.pgtsr ? true : false}
                                      /*  disabled={disableFormData(clinicalData)} */
                                    />
                                  }
                                  label="PGT-SR"
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={12}
                              spacing={2}
                              className="item-center"
                              style={{ marginTop: "5px" }}>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="eggFreezing"
                                      checked={additionalField?.eggFreezing}
                                      onChange={handleAdditionalFieldChange}
                                      disabled={isViewOnly}
                                    />
                                  }
                                  label="Egg Freezing"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>Number of Frozen Eggs</Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  name="noOfFrozonEgg"
                                  placeholder="Number of Frozen Eggs"
                                  disabled={isViewOnly || additionalField?.eggFreezing == false}
                                  value={additionalField?.noOfFrozonEgg}
                                  onChange={handleAdditionalFieldChange}
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          <Box>
                            <Grid container item xs={12} spacing={2} style={{ marginTop: "5px" }}>
                              <Grid item xs={6}>
                                <Stack direction="column">
                                  <InputLabel>
                                    Embryos Transferred
                                    {(clinicalData.outcomeCategory == "Pregnant" ||
                                      clinicalData.outcomeCategory == "Not Pregnant" ||
                                      clinicalData.outcomeCategory == "Miscarried" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Miscarried" ||
                                      clinicalData.outcomeCategory ==
                                        "Not Pregnant - Biochemical" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Ectopic" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Still born" ||
                                      clinicalData.outcomeCategory ==
                                        "Not Pregnant - Loss of Newborn") &&
                                      "**"}
                                  </InputLabel>
                                  <TextField
                                    name="transferedEmbryo"
                                    value={additionalField?.transferedEmbryo}
                                    /*  disabled={validateTransfer} */
                                    /* onChange={(e) => handleChangeValues(e, index)} */
                                    onChange={handleAdditionalFieldChange}
                                    //disabled={isViewOnly || !isTransfer}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} style={{ marginTop: "10px" }}>
                              <Grid item xs={4}>
                                <Stack direction="column">
                                  <InputLabel>
                                    Embryos Frozen
                                    {(clinicalData.outcomeCategory == "Pregnant" ||
                                      clinicalData.outcomeCategory == "Not Pregnant" ||
                                      clinicalData.outcomeCategory == "Miscarried" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Miscarried" ||
                                      clinicalData.outcomeCategory ==
                                        "Not Pregnant - Biochemical" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Ectopic" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Still born" ||
                                      clinicalData.outcomeCategory ==
                                        "Not Pregnant - Loss of Newborn" ||
                                      clinicalData.outcomeCategory == "Not Pregnant - Freeze All" ||
                                      clinicalData.outcomeCategory ==
                                        "Not Pregnant - No Transfer") &&
                                      "**"}
                                  </InputLabel>
                                  <TextField
                                    name="frozenEmbryo"
                                    value={additionalField?.frozenEmbryo}
                                    /* disabled={validateTransfer}
                                    onChange={(e) => handleChangeValues(e, index)} */
                                    onChange={handleAdditionalFieldChange}
                                    //disabled={isViewOnly || !isTransfer}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack direction="column">
                                  <InputLabel>
                                    Frozen Embryos Remaining
                                    {clinicalData.outcomeCategory &&
                                      clinicalData.outcomeCategory !== "No Status" &&
                                      "**"}
                                  </InputLabel>
                                  <TextField
                                    name="remainingEmbryo"
                                    value={additionalField?.remainingEmbryo}
                                    /* disabled={validateTransfer}
                                    onChange={(e) => handleChangeValues(e, index)} */
                                    onChange={handleAdditionalFieldChange}
                                    //disabled={isViewOnly || !isTransfer}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack direction="column">
                                  <InputLabel>
                                    Normal Frozen Embryos Remaining
                                    {/* clinicalData.outcomeCategory &&
                                      clinicalData.outcomeCategory !== "No Status" &&
                                      "**" */}
                                  </InputLabel>
                                  {/* {console.log("isTransfer", isTransfer)} */}
                                  <TextField
                                    name="normalFrozenEmbroysRemaining"
                                    value={additionalField?.normalFrozenEmbroysRemaining}
                                    /* disabled={validateTransfer}
                                    onChange={(e) => handleChangeValues(e, index)} */
                                    onChange={(e) =>
                                      handleAdditionalFieldChange(
                                        e,
                                        "normalFrozenEmbroysRemaining",
                                        "text"
                                      )
                                    }
                                    //disabled={isViewOnly || !isTransfer}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>

                          {/* <Stack direction="row" className="modal-stack m-t-15">
                            <Typography>Egg freezing Details</Typography>
                          </Stack>
                          <Divider className="modal-divider m-t-5" />
                          <Box className="m-t-5">
                            <Grid container item xs={12} spacing={2} className="item-center">
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="eggFreezing"
                                      checked={additionalField.eggFreezing}
                                      onChange={handleAdditionalFieldChange}
                                    />
                                  }
                                  label="Egg Freezing"
                                />
                              </Grid>
                              {additionalField?.eggFreezing && (
                                <Grid container item xs={9} spacing={2} mt={1}>
                                  <Grid item xs={3}>
                                    <Typography>Number of Frozen Eggs</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      type="number"
                                      name="noOfFrozonEgg"
                                      value={additionalField.noOfFrozonEgg}
                                      onChange={handleAdditionalFieldChange}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                          <Divider className="modal-divider m-t-10" />
                          <Stack direction="row" className="modal-stack">
                            <Typography>Transfer Details</Typography>
                          </Stack>
                          <Divider className="modal-divider m-t-5" />
                          <Box className="m-t-5">
                            <Grid container item xs={12} spacing={2}>
                              {isTransfer && (
                                <Grid item xs={12}>
                                  <Grid item xs={6}>
                                    <Stack direction="column">
                                      <InputLabel>Transfer Date</InputLabel>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          className="o-date-picker"
                                          name="transferDate"
                                          value={additionalField?.transferDate}
                                          onChange={(e) =>
                                            handleAdditionalFieldChange(e, "transferDate", "date")
                                          }
                                          renderInput={(params) => <TextField {...params} />}
                                        />
                                      </LocalizationProvider>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              )}
                              <Grid item xs={6}>
                                <Stack direction="column">
                                  <InputLabel>Frozen Embryo</InputLabel>
                                  <TextField
                                    type="number"
                                    name="frozenEmbryo"
                                    value={additionalField?.frozenEmbryo}
                                    onChange={handleAdditionalFieldChange}
                                    disabled={!isTransfer}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack direction="column">
                                  <InputLabel>Transffered</InputLabel>
                                  <TextField
                                    type="number"
                                    name="transferedEmbryo"
                                    value={additionalField?.transferedEmbryo}
                                    onChange={handleAdditionalFieldChange}
                                    disabled={!isTransfer}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack direction="column">
                                  <InputLabel>Remaining Frozen Embryo</InputLabel>
                                  <TextField
                                    type="number"
                                    name="remainingEmbryo"
                                    value={additionalField?.remainingEmbryo}
                                    onChange={handleAdditionalFieldChange}
                                    disabled={!isTransfer}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack direction="column">
                                  <InputLabel>Normal frozen embryos remaining</InputLabel>
                                  <TextField
                                    type="number"
                                    name="normalFrozenEmbroysRemaining"
                                    value={additionalField?.normalFrozenEmbroysRemaining}
                                    onChange={handleAdditionalFieldChange}
                                    disabled={!isTransfer}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box> */}
                          {/* <Stack direction="row" className="modal-stack">
                            <Typography>Clinical Decision</Typography>
                          </Stack> */}
                          <Divider className="modal-divider m-t-5" />
                          <Box className="m-t-20">
                            {/* <Grid container item xs={12} className="item-center">
                              <Grid item xs={2}>
                                <Typography>Clinical Decision</Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Select
                                  name="clinicalDecision"
                                  fullWidth
                                  value={additionalField?.clinicalDecision}
                                  sx={{ flex: 6, height: 35 }}
                                  onChange={handleAdditionalFieldChange}>
                                  {CLINICAL_DECISION.map((item, index) => {
                                    return (
                                      <MenuItem value={item} key={index}>
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                            </Grid> */}

                            <Grid container item xs={12} spacing={2} className="item-center">
                              <Grid item xs={2}>
                                <Typography>
                                  Requested Services
                                  <Box component="span" className="asteriks">
                                    *
                                  </Box>
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    name="requestedService"
                                    value={additionalField.requestedService}
                                    onChange={handleAdditionalFieldChange}>
                                    {REQUESTED_SERVICES.map((item, index) => {
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          value={item.value}
                                          control={<Radio />}
                                          label={item.label}
                                          checked={additionalField?.requestedService == item?.value}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid direction={"row"} item xs={4}>
                                <Stack direction="column">
                                  <InputLabel
                                    sx={{
                                      color:
                                        additionalField?.verifiedNormalEmbryosRemaining &&
                                        additionalField?.verifiedNormalEmbryosRemaining !=
                                          additionalField.normalFrozenEmbroysRemaining
                                          ? "red"
                                          : ""
                                    }}>
                                    Verified Normal Embryos remaining
                                    <Box component="span" className="asteriks">
                                      *
                                    </Box>
                                  </InputLabel>
                                  <TextField
                                    name="verifiedNormalEmbryosRemaining"
                                    value={additionalField?.verifiedNormalEmbryosRemaining}
                                    // disabled={true}
                                    /* disabled={validateTransfer}
                                    onChange={(e) => handleChangeValues(e, index)} */
                                    onChange={
                                      (e) => {
                                        handleAdditionalFieldChange(
                                          e,
                                          "verifiedNormalEmbryosRemaining",
                                          "text"
                                        );
                                      }
                                      // handleAdditionalFieldChange(
                                      //   e,
                                      //   "verifiedNormalEmbryosRemaining",
                                      //   "text"
                                      // )
                                    }
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack direction="column">
                                  <InputLabel>
                                    Estimated Cycle Start Date
                                    <Box component="span" className="asteriks">
                                      *
                                    </Box>
                                  </InputLabel>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      views={["day"]}
                                      name="cycleStartDay"
                                      className="o-date-picker"
                                      value={additionalField?.cycleStartDay}
                                      onChange={(e) =>
                                        handleAdditionalFieldChange(e, "cycleStartDay", "date")
                                      }
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                  </LocalizationProvider>
                                </Stack>
                                {/* <Typography>
                                  Estimated Cycle Start Date
                                  <Box component="span" className="asteriks">
                                    *
                                  </Box>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    openTo="day"
                                    views={["day"]}
                                    name="cycleStartDay"
                                    className="o-date-picker"
                                    value={additionalField?.cycleStartDay}
                                    onChange={(e) =>
                                      handleAdditionalFieldChange(e, "cycleStartDay", "date")
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider> */}
                              </Grid>
                              {/* <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    openTo="day"
                                    views={["day"]}
                                    name="cycleStartDay"
                                    className="o-date-picker"
                                    value={additionalField?.cycleStartDay}
                                    onChange={(e) =>
                                      handleAdditionalFieldChange(e, "cycleStartDay", "date")
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid> */}
                            </Grid>
                          </Box>
                          <Box className="m-t-10">
                            <Stack direction="column">
                              <Typography>{ACKNOWLEDGE_TEXT}</Typography>
                              <FormControl required variant="standard">
                                <FormControlLabel
                                  required
                                  control={
                                    <Checkbox
                                      required
                                      checked={isAcknowledge}
                                      onChange={() => {
                                        setIsAcknowledge(!isAcknowledge);
                                      }}
                                    />
                                  }
                                />
                                <FormLabel className="ack">I Acknowledge</FormLabel>
                              </FormControl>
                            </Stack>
                          </Box>
                        </Box>
                        <Box>
                          <Stack
                            direction="row"
                            spacing={2}
                            className="modal-bottom-section"
                            style={{ justifyContent: "flex-end" }}>
                            <Button
                              type="submit"
                              variant="contained"
                              className="btn-usf"
                              disabled={
                                clinicalData?.outcomeCategory
                                  ? clinicalData?.outcomeCategory == outcomeCategories[0] ||
                                    !additionalField?.requestedService
                                    ? true
                                    : false
                                  : true
                              }
                              onClick={() => {
                                setRequestAuthClicked(true);
                                outcomeFormSubmitHandler("SaveAndSubmit");
                                sendEmailRequestAuth();
                              }}>
                              Request Auth
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              className="btn-usf"
                              onClick={() => outcomeFormSubmitHandler("SaveAndClose")}>
                              Save and Close
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              className="btn-usf"
                              onClick={() => outcomeFormSubmitHandler("SaveAndSubmit")}
                              disabled={disableFormSubmit()}>
                              Save and Submit
                            </Button>
                          </Stack>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Box>
              </>
            )}
          </>
        </Box>
      </Modal>
      {/* <EmailDocumentModal
        openDialog={openEmailDocument}
        close={closeEmailDocument}
        clinicalTreatmentNo={clinicalData && clinicalData.clinicalTreatmentNo}
        authorizedTreatment={clinicalData && clinicalData.authorizedTpName}
        isOutcomeEmail={true}
        clinicalTreatmentId={selectedPatient?.clinicalTreatmentTd}
        mdCaseId={selectedPatient.medcaseID}
      /> */}
      <Modal open={normalEmbryosRemainingModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style">
          <Stack direction="row" sx={{ justifyContent: "flex-end" }} className="modal-stack">
            <IconButton
              onClick={() => {
                if (
                  (!additionalField.verifiedNormalEmbryosRemaining ||
                    additionalField.verifiedNormalEmbryosRemaining == "") &&
                  additionalField.normalFrozenEmbroysRemaining
                ) {
                  setAdditionalField({
                    ...additionalField,
                    verifiedNormalEmbryosRemaining: additionalField.normalFrozenEmbroysRemaining
                  });
                }
                setNormalEmbryosRemainingModal(false);
              }}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="column" className="flex-center m-8">
            <Typography>
              What is the total number of Normal Embryos Remaining from all Fertility Access program
              Cycle?
            </Typography>
            <Stack direction="row" className="flex flex-between" my={2}>
              <Typography>Verified Normal Embryos Remaining:</Typography>
              <TextField
                // className="width-50"
                // size={"small"}
                name="verifiedNormalEmbryosRemaining"
                sx={{ marginLeft: "20px", display: "inline-block", width: "100px" }}
                value={additionalField?.verifiedNormalEmbryosRemaining}
                onChange={(e) =>
                  handleAdditionalFieldChange(e, "verifiedNormalEmbryosRemaining", "text")
                }
              />
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              disabled={!additionalField.verifiedNormalEmbryosRemaining}
              onClick={() => {
                setNormalEmbryosRemainingModal(false);
              }}>
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
      <MessageModal
        open={isMessageModal}
        message={successMsg || success}
        closeModal={closeMessageModal}
      />
      <MessageModal
        open={authMessage}
        message={"Cycle Start Date is required to extend an authorization."}
        closeModal={() => {
          setAuthMessage(false);
        }}
      />
      <MessageModal
        open={isOpenForExpDate}
        message={
          "This authorization has expired.  The Authorization Expiration Date must be updated prior to entering Outcome data"
        }
        closeModal={() => {
          setIsOpenForExpDate(false);
        }}
      />
      <WarningPopUp open={isWarningOpen} closeModal={handleCloseWarning} error={errorMessage} />
      <MessageModal
        open={isNormalFrozenEmbroysRemainingEmpty}
        message={
          "The Number of Normal Embryos Remaining must be entered in order to request the next cycle."
        }
        closeModal={() => setIsNormalFrozenEmbroysRemainingEmpty(false)}
      />
    </Box>
  );
};
const mapStateToProps = ({ fapReducer, financialSnapshotPaymentReducer }) => {
  return {
    fapList: fapReducer.fapList,
    clinicalDetails: fapReducer.clinicalData,
    treatmentPlanList: fapReducer.treatmentPlanList,
    patientMedcaseInfo: fapReducer.patientMedcaseInfo,
    medcaseInfoLoading: fapReducer.medcaseInfoLoading,
    success: fapReducer.success,
    successMsg: financialSnapshotPaymentReducer.success,
    errorEmail: financialSnapshotPaymentReducer.errorEmail,
    patientUser: fapReducer.patientUser
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateOutcomeFormDetails: (payload) => {
      dispatch(actionType.updateOutcomeFormDetails(payload));
    },
    getClinicalData: (payload) => {
      dispatch(actionType.getClinicalData(payload));
    },
    getPatientMedcaseInfo: (payload) => {
      dispatch(actionType.getPatientMedcaseInfo(payload));
    },
    getTreatmentPlanList: (payload) => {
      dispatch(actionType.getTreatmentPlanList(payload));
    },
    resetMessage: (payload) => {
      dispatch(actionType.resetMessage(payload));
    },
    postEmailOption: (payload) => {
      dispatch(actionTypeTrack.postEmailOption(payload));
    },
    resetEmail: () => {
      dispatch(actionTypeTrack.resetEmailList());
    },
    resetPostEmail: () => {
      dispatch(actionTypeTrack.resetPostEmailOption());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(FelixOutcomeForm);
