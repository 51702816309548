import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Fragment, useState } from "react";
import { connect, useSelector } from "react-redux";
// import { getPractices } from "../../admin/actions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as actionType from "../actions";
import {
  calculateAge,
  enumUsStates,
  isEmailValid,
  isNameValid,
  isPhoneNumberValid,
  languages
} from "../../shared/constants/constants";

const PatientDetailsForm = (props) => {
  const {
    disabled,
    getValue,
    values,
    disableEdit,
    actions,
    primaryMdList,
    mdListLoading,
    practiceId,
    isEdit,
    existingPatient
  } = props;
  // const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profileDrawerReducer.profileDetails);
  const practices = profileDetails?.practiceList;
  console.log("zhr practices", practices);
  console.log("zhr practices", practiceId);

  const defualtPracticeId = values.practiceId ? values.practiceId : practiceId;

  const [validation, setValidation] = useState({
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: ""
  });
  // useEffect(() => {
  //   if (practices.length == 0) dispatch(getPractices());
  // }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "practiceId") {
      getValue(name, value);
      actions.resetPrimaryMd();
    } else if (
      name == "firstName" ||
      name == "lastName" ||
      name == "email" ||
      name == "phoneNumber" ||
      name == "preferredLanguage"
    ) {
      setValidation({ ...validation, [name]: "" });
      getValue(name, value);
    } else {
      getValue(name, value);
    }
  };

  const genderConstant = [
    { label: "Male", id: 1, value: "M" },
    { label: "Female", id: 2, value: "F" },
    { label: "Unknown", id: 3, value: "U" }
  ];

  const phoneType = [
    { label: "Cell Phone", id: 1, value: "cell" },
    { label: "Home Phone", id: 2, value: "home" },
    { label: "Work Phone", id: 3, value: "work" }
  ];

  const handleSearch = (name, value) => {
    console.log("zhr handle search", name, value);
    if ((name === "defaultAddress" || name === "state") && value?.label) {
      getValue(name, value?.label);
    } else if (
      (name === "practiceId" || name === "defaultPhone" || name === "gender") &&
      value?.value
    ) {
      getValue(name, value.value);
      if (name === "practiceId") {
        actions.resetPrimaryMd();
      }
    } else {
      getValue(name, value);
    }
  };
  return (
    <Fragment>
      <Box className="mr-8 ml-">
        <Grid container>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                First Name
              </InputLabel>
              <TextField
                className="flex-4"
                size="small"
                type="text"
                disabled={disabled || disableEdit}
                required
                name="firstName"
                value={values?.firstName}
                onChange={handleInputChange}
                error={validation.firstName ? true : false}
                helperText={validation.firstName}
                onBlur={() => {
                  if (isNameValid(values?.firstName)) {
                    setValidation({ ...validation, firstName: "" });
                  } else {
                    setValidation({
                      ...validation,
                      firstName: "The name should contain only characters"
                    });
                  }
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2 patient-form">
                Last Name
              </InputLabel>
              <TextField
                fullWidth
                type="text"
                className="flex-4"
                required
                disabled={disabled || disableEdit}
                name="lastName"
                value={values?.lastName}
                onChange={handleInputChange}
                error={validation.lastName ? true : false}
                helperText={validation.lastName}
                onBlur={() => {
                  if (isNameValid(values?.lastName)) {
                    setValidation({ ...validation, lastName: "" });
                  } else {
                    setValidation({
                      ...validation,
                      lastName: "The name should contain only characters"
                    });
                  }
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                Date of Birth
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  name="birthDate"
                  disableFuture
                  disabled={disabled || disableEdit}
                  value={values?.birthDate ? values?.birthDate : null}
                  onChange={(birthDate) => {
                    getValue("birthDate", birthDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      size="small"
                      autoComplete="off"
                      className="flex-4"
                    />
                  )}></DatePicker>
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel className="flex-2 patient-form">Age</InputLabel>
              <TextField
                fullWidth
                type="number"
                required
                value={values?.birthDate ? calculateAge(values.birthDate) : ""}
                className="flex-4"
                disabled
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                Gender
              </InputLabel>
              <FormControl fullWidth className="flex-4">
                {/* <Select
                  onChange={handleInputChange}
                  disabled={disabled || disableEdit}
                  name="gender"
                  value={values?.gender}
                  required>
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                  <MenuItem value="W">Unknown</MenuItem>
                </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="gender"
                  defaultValue={!isEdit ? "" : "F"}
                  value={
                    genderConstant.find((gender) =>
                      !isEdit && existingPatient == "No" ? "" : gender.value === values?.gender
                    )?.label
                  }
                  options={genderConstant}
                  onChange={(e, value) => {
                    handleSearch("gender", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Gender..." sx={{ paddingTop: "7px" }} />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2 patient-form">
                Email
              </InputLabel>
              <TextField
                fullWidth
                type="email"
                name="email"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values?.email}
                error={validation.email ? true : false}
                helperText={validation.email}
                onBlur={() => {
                  const res = isEmailValid(values?.email);
                  if (res.status) {
                    setValidation({ ...validation, email: "" });
                  } else {
                    setValidation({ ...validation, email: res.msg });
                  }
                }}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                Phone Type
              </InputLabel>
              <FormControl fullWidth className="flex-4">
                {/* <Select
                  fullWidth
                  value={values?.defaultPhone}
                  onChange={handleInputChange}
                  disabled={disabled}
                  name="defaultPhone">
                  <MenuItem value="cell">Cell Phone</MenuItem>
                  <MenuItem value="home">Home Phone</MenuItem>
                  <MenuItem value="work">Work Phone</MenuItem>
                </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="defaultPhone"
                  defaultValue={"Cell Phone"}
                  value={phoneType.find((phone) => values?.defaultPhone === phone.label)?.label}
                  options={phoneType}
                  onChange={(e, value) => {
                    handleSearch("defaultPhone", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Phone Type..."
                      sx={{ paddingTop: "7px" }}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2 patient-form">
                Phone Number
              </InputLabel>
              <TextField
                fullWidth
                type="tel"
                name="phoneNumber"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values?.phoneNumber}
                error={validation.phoneNumber ? true : false}
                helperText={validation.phoneNumber}
                onBlur={() => {
                  const res = isPhoneNumberValid(values?.phoneNumber);
                  if (res.status) {
                    setValidation({ ...validation, phoneNumber: "" });
                  } else {
                    setValidation({ ...validation, phoneNumber: res.msg });
                  }
                }}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                Address Type
              </InputLabel>
              <FormControl fullWidth className="flex-4">
                {/* <Select
                  fullWidth
                  disabled={disabled}
                  value={values?.defaultAddress}
                  onChange={handleInputChange}
                  name="defaultAddress">
                  <MenuItem value="mailing">Mailing</MenuItem>
                  <MenuItem value="billing">Billing</MenuItem>
                </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="defaultAddress"
                  defaultValue={"Mailing"}
                  value={values?.defaultAddress}
                  options={[
                    { label: "Mailing", id: 1 },
                    { label: "Billing", id: 2 }
                  ]}
                  onChange={(e, value) => {
                    handleSearch("defaultAddress", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Address Type..."
                      sx={{ paddingTop: "7px" }}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2 patient-form">
                Street Line 1
              </InputLabel>
              <TextField
                fullWidth
                type="text"
                name="streetLine1"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values.streetLine1}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                City
              </InputLabel>
              <TextField
                fullWidth
                type="text"
                name="city"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values?.city}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel className="flex-2 patient-form">Street Line 2</InputLabel>
              <TextField
                fullWidth
                type="text"
                name="streetLine2"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values?.streetLine2}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                State
              </InputLabel>
              {/* <Select
                size="small"
                fullWidth
                className="flex-4"
                disabled={disabled}
                name="state"
                value={values?.state}
                onChange={handleInputChange}>
                {enumUsStates.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="state"
                value={values?.state}
                options={enumUsStates.map((state, index) => {
                  return {
                    id: index,
                    label: state.name
                  };
                })}
                onChange={(e, value) => {
                  handleSearch("state", value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select State..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2 patient-form">
                Zip
              </InputLabel>
              <TextField
                fullWidth
                type="text"
                name="zip"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values?.zip}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel required className="flex-2">
                Country
              </InputLabel>
              <TextField
                fullWidth
                type="text"
                name="country"
                disabled={disabled}
                onChange={handleInputChange}
                className="flex-4"
                value={values?.country}
                required
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" className="modal-stack">
              <InputLabel className="flex-2 patient-form">Preferred Language</InputLabel>
              <Select
                size="small"
                fullWidth
                className="flex-4"
                disabled={disabled}
                name="preferredLanguage"
                value={
                  languages.find((item) => {
                    return item.value == values.preferredLanguage;
                  })?.value
                }
                onChange={handleInputChange}>
                {languages.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} className="pad-t-15">
            <Stack direction="row">
              <Typography variant="subtitle">Practice and Provider</Typography>
            </Stack>
            <Divider className="divider" />
          </Grid>
          <Grid item xs={6}>
            <Stack className="modal-stack" direction="row">
              <InputLabel required className="flex-2">
                Practice
              </InputLabel>
              {/* <Select
                fullWidth
                className="flex-4"
                name="practiceId"
                value={values?.practiceId}
                disabled={disabled || disableEdit}
                onChange={handleInputChange}
                required
                placeholder="Select Practice">
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} - {item.practiceId}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={
                  practices.find((item) => item.practiceId === defualtPracticeId)
                    ? `${
                        practices.find((item) => item.practiceId === defualtPracticeId).practiceName
                      } - ${defualtPracticeId}`
                    : ""
                }
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.practiceName} - ${item.practiceId}`,
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handleSearch("practiceId", value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Practice..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack className="modal-stack" direction="row">
              <InputLabel required className="flex-2 patient-form">
                Primary MD
              </InputLabel>
              <Autocomplete
                disabled={values?.practiceId && !disabled && !disableEdit ? false : true}
                className="flex-4"
                value={values.ivfMDFirstName + " " + values.ivfMDLastName}
                name="primaryMd"
                loading={mdListLoading}
                onChange={(e, value) => {
                  handleSearch("primaryMd", value);
                }}
                required
                options={primaryMdList?.map((item, index) => {
                  return {
                    ...item,
                    label: `${item.ivfMDFirstName} ${item.ivfMDLastName}`,
                    id: index + 1
                  };
                })}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Find by first name or last name"
                    onClick={() => {
                      if (primaryMdList.length == 0 && values.practiceId) {
                        actions.getPrimaryMdList({ practiceId: values.practiceId });
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {mdListLoading ? <CircularProgress size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      )
                    }}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
const mapStateToProps = ({ patientReducer }) => {
  return {
    primaryMdList: patientReducer.primaryMdList,
    mdListLoading: patientReducer.mdListLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPrimaryMdList: (payload) => {
      dispatch(actionType.getPrimaryMdList(payload));
    },
    resetPrimaryMd: () => {
      dispatch(actionType.resetPrimaryMd());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientDetailsForm);
