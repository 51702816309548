import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Checkbox, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { expiaryMonths, expiaryYear, currentYear } from "../../constants";
import WarnningMessageModal from "../../../shared/components/WarnningMessageModal";
import { patientPaymentWarning } from "../../constants";
import moment from "moment/moment";
import isAccess from "../../../shared/components/CheckUserRights";

const PatientPaymentsModal = (props) => {
  const [values, setValues] = useState();
  const [isDefault, setDefault] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  //const [warningMessage, setWarningMessage] = useState("");
  function manageTransactionActions(transactionDate) {
    // Get the current date with Moment.js
    const currentDate = moment();

    // Parse the transaction date with Moment.js
    const postedDate = moment(transactionDate);

    // Calculate the last date for editing/deleting: 10th of the following month
    const editDeleteDeadline = postedDate.clone().add(1, "months").date(10);
    console.log("editDeleteDeadline", editDeleteDeadline);

    // Initialize button states as disabled by default

    let editEnabled = false;

    // isAccess("eft") &&
    // Enable Delete and Edit if user has "eft" role and within allowed date range
    if (props.ppSnap) {
      editEnabled = true;
    } else if (isAccess("eft") && currentDate.isSameOrBefore(editDeleteDeadline)) {
      editEnabled = true;
    }

    // Return functions for external use (like posting a transaction)
    return editEnabled;
  }
  useEffect(() => {
    if (props.isDelete) {
      setValues({ ...props.patientPaymentDetails });
    } else {
      setValues({
        amount: null,
        paymentType: "creditCard",
        transactionDate: new Date(),
        recordedDate: new Date()
      });
      if (values) setDefault(false);
      else setDefault(true);
    }
  }, [props]);

  const validateCC =
    values?.paymentType == "creditCard"
      ? values?.amount &&
        values?.transactionDate &&
        values?.cardName &&
        // values?.billingZip &&
        values?.cardType &&
        values?.cardNo &&
        values?.approval
        ? true
        : false
      : false;
  const validateCH =
    values?.paymentType == "check"
      ? values?.amount && values?.transactionDate && values?.checkNumber
        ? true
        : false
      : false;
  const validateLN =
    values?.paymentType == "loan"
      ? values?.amount &&
        values?.transactionDate &&
        values?.recordedDate &&
        values?.loanType &&
        values?.discountAmount
        ? true
        : false
      : false;
  const validateWT =
    values?.paymentType == "wireTransfer"
      ? values?.amount && values?.transactionDate && values?.recordedDate
        ? true
        : false
      : false;

  const validate = validateCC || validateCH || validateLN || validateWT;
  console.log(new Date(values?.transactionDate).getUTCMonth());
  console.log(new Date().getUTCMonth());
  console.log(new Date(values?.transactionDate).getFullYear());
  console.log(new Date().getFullYear());
  console.log(
    new Date(values?.transactionDate).getUTCMonth() === new Date().getUTCMonth() &&
      new Date(values?.transactionDate).getFullYear() === new Date().getFullYear()
  );

  const isPNFT = isAccess("pnft");
  const isEFT = isAccess("eft");
  const handleSave = () => {
    let data = {
      isPNFT,
      isEFT,
      ...values
    };
    props.savePatientPayment(data);
  };

  //console.log(values?.transactionDate);
  const handleChange = (e) => {
    // console.log("e", e);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const closeWarningModal = () => {
    setIsWarningOpen(false);
    //setWarningMessage("");
  };

  const cancelModal = () => {
    setIsWarningOpen(false);
    props.handleClose();
  };
  const handleClose = () => {
    props.handleClose();
  };
  const handleCreate = () => {
    values.isImportant = values?.isImportant ? values.isImportant : false;
    // let totalPatientAmount = parseFloat(props.totalPatientPayment) + parseFloat(values.amount);
    // if (props.netProgramFee < values.amount) {
    //   setIsWarningOpen(true);
    //   //setWarningMessage(patientPaymentWarning);
    // } else
    if (values.amount > props.amountDue) {
      setConfirmModal(true);
    } else {
      props.createPatientPayment(values);
    }
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const confirmPayment = () => {
    setConfirmModal(false);
    values.isImportant = values?.isImportant ? values.isImportant : false;
    props.createPatientPayment(values);
  };

  const handleDelete = () => {
    props.deletePayment();
  };
  const creditCardRender = (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "right",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 3 }}> Name On Card{props.isDelete ? "" : "*"}</InputLabel>
        <TextField
          sx={{ flex: 8 }}
          size="small"
          name="cardName"
          id="filled-basic"
          value={values?.cardName}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
        {/* <InputLabel sx={{ flex: 2 }}> Billing Zip{props.isDelete ? "" : "*"}</InputLabel> */}
        <InputLabel sx={{ flex: 2 }}> Billing Zip</InputLabel>
        <TextField
          sx={{ flex: 4 }}
          size="small"
          name="billingZip"
          type="number"
          id="filled-basic"
          value={values?.billingZip}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "center",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 3 }}>Card Type{props.isDelete ? "" : "*"}</InputLabel>
        <Select
          sx={{ flex: 8 }}
          size="small"
          id="cardType-select"
          value={values?.cardType || ""}
          onChange={handleChange}
          className="add-description"
          name="cardType"
          disabled={!manageTransactionActions(values?.transactionDate)}>
          <MenuItem value="Visa">Visa</MenuItem>
          <MenuItem value="MasterCard">MasterCard</MenuItem>
          <MenuItem value="Amex">American Express</MenuItem>
          <MenuItem value="Discover">Discover</MenuItem>
        </Select>

        <InputLabel sx={{ flex: 2 }}> Approval Code{props.isDelete ? "" : "*"}</InputLabel>
        <TextField
          sx={{ flex: 4 }}
          size="small"
          name="approval"
          type="text"
          id="filled-basic"
          value={values?.approval}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "right",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 2.9 }}>
          {" "}
          Card # (Last 4 digit){props.isDelete ? "" : "*"}
        </InputLabel>
        <TextField
          sx={{ flex: 8 }}
          size="small"
          name="cardNo"
          type="number"
          id="filled-basic"
          value={values?.cardNo}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
        <InputLabel sx={{ flex: 2 }}>Expiration</InputLabel>
        <Select
          sx={{ flex: 1.5 }}
          size="small"
          id="expiryMonth-select"
          value={values?.expiryMonth || ""}
          onChange={handleChange}
          className="add-description"
          name="expiryMonth"
          disabled={!manageTransactionActions(values?.transactionDate)}>
          <MenuItem value="">Select</MenuItem>
          {expiaryMonths.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>

        <Select
          sx={{ flex: 2 }}
          size="small"
          id="expiryYear-select"
          value={values?.expiryYear || ""}
          onChange={handleChange}
          className="add-description"
          name="expiryYear"
          disabled={!manageTransactionActions(values?.transactionDate)}>
          <MenuItem value="">Select</MenuItem>
          {expiaryYear.map((item, index) => (
            <MenuItem value={Number(String(currentYear + item.value).slice(-2))} key={index}>
              {currentYear + item.value}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </>
  );
  const checkRender = (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "center",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 3 }}> Bank Name</InputLabel>
        <TextField
          sx={{ flex: 8 }}
          size="small"
          name="bankName"
          id="filled-basic"
          value={values?.bankName}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
        <InputLabel sx={{ flex: 2.5 }}> Account Number</InputLabel>
        <TextField
          sx={{ flex: 4 }}
          size="small"
          name="accountNo"
          type="number"
          id="filled-basic"
          value={values?.accountNo}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "center",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 3 }}>Cheque Number{props.isDelete ? "" : "**"}</InputLabel>
        <TextField
          sx={{ flex: 8 }}
          size="small"
          name="checkNumber"
          type="number"
          id="filled-basic"
          value={values?.checkNumber}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 11);
          }}
        />
        <InputLabel sx={{ flex: 2.5 }}> Routing Number</InputLabel>
        <TextField
          sx={{ flex: 4 }}
          size="small"
          name="routingNo"
          type="number"
          id="filled-basic"
          value={values?.routingNo}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
      </Stack>
    </>
  );
  const loanRender = (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "left",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 3 }}> Loan Type{props.isDelete ? "" : "*"}</InputLabel>
        <TextField
          sx={{ flex: 8 }}
          size="small"
          id="select"
          placeholder=""
          value={values?.loanType ?? ""}
          onChange={handleChange}
          className="add-description"
          name="loanType"
          disabled={!manageTransactionActions(values?.transactionDate)}
          defaultValue={String(values?.loanType ?? "")}
          select>
          <MenuItem key="ifsLoan" value="ifsLoan">
            IFS Loan
          </MenuItem>
          <MenuItem key="capexMd" value="capexMd">
            CapexMD
          </MenuItem>
          <MenuItem key="leadingclub(formerlySpringStone)" value="leadingclub(formerlySpringStone)">
            Lending Club(formerly Springstone)
          </MenuItem>
        </TextField>
        <InputLabel className="word-break" sx={{ flex: 2 }}>
          Loan Discount Amount{props.isDelete ? "" : "*"}
        </InputLabel>
        <TextField
          sx={{ flex: 4 }}
          size="small"
          name="discountAmount"
          id="filled-basic"
          value={values?.discountAmount}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
      </Stack>
    </>
  );
  const wireTransferRender = (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          alignItems: "center",
          justifyContent: "space-between"
        }}>
        <InputLabel sx={{ flex: 3 }}> Order Party</InputLabel>
        <TextField
          sx={{ flex: 8 }}
          size="small"
          name="orderParty"
          id="filled-basic"
          value={values?.orderParty}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
        <InputLabel sx={{ flex: 2 }}> Debit Party</InputLabel>
        <TextField
          sx={{ flex: 4 }}
          size="small"
          name="debitParty"
          id="filled-basic"
          value={values?.debitParty}
          onChange={handleChange}
          className="add-description"
          disabled={!manageTransactionActions(values?.transactionDate)}
        />
      </Stack>
    </>
  );
  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-add-title"
      aria-describedby="modal-add-description">
      <Box className="modal-style-contact" style={{ maxHeight: "80vh", overflow: "auto" }}>
        {props.isDelete ? (
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Transaction Details</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>
              Patient Payment Detail (Fields marked with * are mandatory and ** are conditionally
              mandatory)
            </Typography>
            <Typography>Invoice Id: {props.invoiceId ? props.invoiceId : "--"}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        )}
        <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
        <Stack
          direction="row"
          spacing={2}
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <InputLabel sx={{ flex: props.isDelete ? 1.85 : 2 }}>
            Payment Type{props.isDelete ? "" : "*"}
          </InputLabel>
          {props.isDelete ? (
            <TextField
              sx={{ flex: 5 }}
              size="small"
              name="paymentType"
              id="filled-basic"
              value={values?.paymentType}
              disabled={props.isDelete}
              className="add-description"
            />
          ) : (
            <RadioGroup
              value={values?.paymentType}
              onClick={(e) => {
                setValues({
                  amount: null,
                  paymentType: e.target.value,
                  transactionDate: new Date(),
                  recordedDate: new Date()
                });
                setDefault(false);
              }}
              row
              sx={{ my: 1, flex: 6 }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="creditCard"
                name="radio-buttons-group"
                row>
                {" "}
                <Stack spacing={2} direction="row">
                  {" "}
                  <Typography>
                    <Radio value="creditCard" label="CreditCard" />
                    Credit Card
                  </Typography>{" "}
                </Stack>{" "}
                <Stack spacing={2} direction="row">
                  {" "}
                  <Typography>
                    <Radio value="check" label="Check" />
                    Check
                  </Typography>{" "}
                </Stack>{" "}
                <Stack spacing={2} direction="row">
                  {" "}
                  <Typography>
                    <Radio value="loan" label="Loan" />
                    Loan
                  </Typography>{" "}
                </Stack>{" "}
                <Stack spacing={2} direction="row">
                  {" "}
                  <Typography>
                    <Radio value="wireTransfer" label="WireTransfer" />
                    Wire Transfer
                  </Typography>{" "}
                </Stack>{" "}
              </RadioGroup>
            </RadioGroup>
          )}
          <InputLabel sx={{ flex: 1 }}>Amount{props.isDelete ? "" : "*"}</InputLabel>
          <TextField
            sx={{ flex: 3 }}
            size="small"
            name="amount"
            type="number"
            id="filled-basic"
            value={values?.amount}
            onChange={handleChange}
            disabled={!manageTransactionActions(values?.transactionDate)}
            className="add-description"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <InputLabel sx={{ flex: 3 }}> Transaction Date{props.isDelete ? "" : "*"}</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              name="transactionDate"
              disabled={props.isDelete}
              value={values?.transactionDate}
              onChange={(newValue) => {
                if (newValue > values?.recordedDate)
                  setValues({ ...values, recordedDate: null, transactionDate: newValue });
                else setValues({ ...values, transactionDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" sx={{ flex: 8 }} />
              )}></DatePicker>
          </LocalizationProvider>
          <InputLabel sx={{ flex: 2.5 }}>
            Recorded Date
            {props.isDelete
              ? ""
              : values &&
                (values.paymentType == "loan" || values.paymentType == "wireTransfer") &&
                "*"}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              name="recordedDate"
              disabled={true}
              value={values?.recordedDate}
              onChange={(newValue) => {
                if (values?.transactionDate && newValue >= values?.transactionDate)
                  setValues({ ...values, recordedDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" sx={{ flex: 4 }} />
              )}></DatePicker>
          </LocalizationProvider>
        </Stack>
        {props.isDelete
          ? (props.paymentType && props.paymentType.toUpperCase()) == "CREDIT CARD" &&
            creditCardRender
          : (values?.paymentType == "creditCard" || isDefault) && creditCardRender}
        {props.isDelete
          ? (props.paymentType && props.paymentType.toUpperCase()) == "CHECK" && checkRender
          : values?.paymentType == "check" && checkRender}
        {props.isDelete
          ? (props.paymentType && props.paymentType.toUpperCase()) == "LOAN" && loanRender
          : values?.paymentType == "loan" && loanRender}
        {props.isDelete
          ? (props.paymentType && props.paymentType.toUpperCase()) == "WIRE TRANSFER" &&
            wireTransferRender
          : values?.paymentType == "wireTransfer" && wireTransferRender}
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <InputLabel sx={{ flex: 11 }}> Mark Important</InputLabel>
            <Checkbox
              sx={{ flex: 1 }}
              value={values?.isImportant}
              checked={values?.isImportant ? true : false}
              disabled={!manageTransactionActions(values?.transactionDate)}
              onClick={(e) => {
                setValues({ ...values, isImportant: e.target.checked });
              }}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <InputLabel sx={{ flex: 2 }}> Comment</InputLabel>
          <TextField
            sx={{ flex: 7 }}
            size="small"
            name="comment"
            id="filled-basic"
            value={values?.comment}
            onChange={handleChange}
            className="add-description"
            disabled={!manageTransactionActions(values?.transactionDate)}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
          <Button variant="outlined" className="btn-primary" onClick={handleClose}>
            Cancel
          </Button>
          {manageTransactionActions(values?.transactionDate) && props.isDelete && (
            <Button variant="outlined" className="btn-primary" onClick={handleSave}>
              Edit
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            className="btn-usf"
            onClick={props.isDelete ? handleDelete : handleCreate}
            disabled={
              props.isDelete
                ? new Date(values?.transactionDate).getUTCMonth() === new Date().getUTCMonth() &&
                  new Date(values?.transactionDate).getFullYear() === new Date().getFullYear()
                  ? false
                  : true
                : !validate
            }>
            {props.isDelete ? "Delete" : "Create"}
          </Button>
          <WarnningMessageModal
            open={isWarningOpen}
            message={patientPaymentWarning}
            closeModal={closeWarningModal}
            cancelModal={cancelModal}
          />

          <Modal open={confirmModal} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="confirm-modal">
              <Stack direction="row" className="modal-stack">
                <Typography> Confirmation</Typography>
                <IconButton onClick={closeConfirmModal}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Stack sx={{ py: 2 }}>
                <Typography>Are you sure you want to make payment ?</Typography>
                <Typography>Payment was greater than due amount</Typography>
              </Stack>
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Button variant="outlined" className="btn-primary" onClick={closeConfirmModal}>
                  Cancel
                </Button>
                <Button variant="contained" className="btn-primary" onClick={confirmPayment}>
                  Continue
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PatientPaymentsModal;
