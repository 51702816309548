import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, InputLabel, Grid, Divider, Button, Checkbox } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import CommonModal from "../../shared/components/CommonModal";
import moment from "moment";
import SaveModal from "../../shared/components/SaveModal";
import { commentConfig, finSnapshotConfig } from "../../shared/constants/renderConfigs";
import PatientPaymentsModal from "./modals/PatientPaymentsModal";
import MessageModal from "../../shared/components/MessageModal";
import GeneticTransactionLog from "./GeneticTransactionLog";
import MedcaseEnroll from "./MedcaseEnroll";
import AddLabPayoutTransaction from "./AddLabPayoutTransaction";
import ManageLabModal from "./modals/ManageLabModal";
import LoadingModal from "./modals/loadingModal";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import { patientPaymentWarning } from "../constants";
import MedcaseRefund from "./MedcaseRefund";
import AddRevenue from "./AddRevenue";
import { priceFormat } from "../../shared/common";
import FinancialComments from "./FinancialComments";
import ClinicalComments from "./ClinicalComments";

import isAccess from "../../shared/components/CheckUserRights";

const GeneticTesting = (props) => {
  const {
    actions,
    pgtaSummary,
    medcaseHeader,
    programName,
    successMsg,
    errorPayment,
    success,
    successFee,
    loadingFee,
    loadingPatient,
    successEnroll,
    errorFee,
    revenueData,
    financialSnapshotPaymentList,
    // financialSnapshotPgtaList,
    successRevenue,
    manualReviewSuccess,
    clinicalReviewSuccess,
    loadingRevenue,
    loading,
    successMsgEmail,
    errorEmail
  } = props;

  const [isOpenAddFee, setIsOpenAddFee] = useState(false);
  const [values, setValues] = useState({});
  const [saveModal, setIsSaveModal] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const { id } = useParams();
  const [isRefundOpen, setIsRefundOpen] = useState(false);
  const [isRevenueOpen, setIsRevenueOpen] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isClinicalChecked, setIsClinicalChecked] = useState(false);
  console.log("isEmailsend", isEmailSend);

  const isPNFT = isAccess("pnft");
  const isEFT = isAccess("eft");

  const addFeeFormData = {
    netProgramFee: pgtaSummary.netFees,
    feeType: "",
    recordedDate: new Date(),
    transactionDate: new Date(),
    amount: "",
    addRadio: false,
    subRadio: false
  };

  const medcaseStatusListDisable = [
    "Program Complete Successful",
    "Program Complete Unsuccessful",
    "Closed",
    "Application Withdrawn",
    "Patient Withdrew",
    "Fertility Access Withdrew"
  ];

  const initPage = () => {
    actions.getFinancialSnapshotPayment({ medcaseId: id });
    actions.getFinancialSnapshotPgta({ medcaseId: id });
    actions.getRevenueFin({ medcaseId: id });
    actions.getPgtaSummary({ medcaseId: id });
    actions.getMedcaseHeader({ medcaseId: id });
    actions.getProgramName({ medcaseId: id });
    // actions.getMedcaseStatus({ medcaseId: id });
    // actions.getMedcaseHeader({ medcaseId: id });
    // actions.getMedcaseDetailsHeader({ medcaseId: id });
    // actions.getProgramName({ medcaseId: id });
    // actions.getProgramCycle({ medcaseId: id, clinicalTreatmentNo: "1.0" });

    // Added code for financial comment
    actions.getComment({ medcaseId: id, commentType: "Financial" });
  };

  useEffect(() => {
    initPage();
  }, []);

  useEffect(() => {
    if (successRevenue || success || successMsg || successFee) {
      initPage();
    }
  }, [successRevenue, success, successMsg, successFee]);
  useEffect(() => {
    if (manualReviewSuccess || clinicalReviewSuccess) {
      setIsMessageModal(true);
      // Added for comment.
      actions.getComment({ medcaseId: id, commentType: "Financial" });
      actions.getComment({ medcaseId: id, commentType: "Clinical" });
    }
    if (manualReviewSuccess || clinicalReviewSuccess) {
      actions.getMedcaseHeader({ medcaseId: id });
    }

    return () => {
      // actions.resetAddFeesList();
      // actions.resetProgram();
    };
  }, [manualReviewSuccess, clinicalReviewSuccess]);

  useEffect(() => {
    if (successEnroll) {
      actions.getPgtaSummary({ medcaseId: id });
      actions.getMedcaseHeader({ medcaseId: id });
      actions.getProgramName({ medcaseId: id });
    }
  }, [successEnroll]);

  useEffect(() => {
    if (success || successMsg || successRevenue) {
      setIsMessageModal(true);
    }

    if (
      successFee == "Saved Successfully" &&
      success != "Saved Successfully" &&
      successMsg != "Saved Successfully"
    ) {
      setIsSaveModal(true);
    }

    if (successMsg || success || successFee || successRevenue) {
      actions.getPgtaSummary({ medcaseId: id });
    }
  }, [successMsg, success, successFee, successRevenue]);

  useEffect(() => {
    if (errorFee || errorPayment) {
      setIsWarningOpen(true);
    }
  }, [errorFee, errorPayment]);

  const enrollButtonDisable = () => {
    if (
      medcaseHeader.paymentStatus == "Paid Full Amount" &&
      medcaseHeader.contractStatus == "Signed Contract" &&
      (medcaseHeader.pgtaAnalysis == "Medically Cleared" ||
        medcaseHeader.pgtaAnalysis == "Pre-Enrolled")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkAccessPermission = (data) => {
    let valuesConfig = { ...data };
    if (isPNFT) {
      valuesConfig = { ...valuesConfig, isPNFT: true };
    } else {
      valuesConfig = { ...valuesConfig, isPNFT: false };
    }
    if (isEFT) {
      valuesConfig = { ...valuesConfig, isEFT: true };
    } else {
      valuesConfig = { ...valuesConfig, isEFT: false };
    }
    return valuesConfig;
  };

  //Add Fee
  const saveAddFee = (event) => {
    event.preventDefault();
    values.recordedDate = values.recordedDate
      ? moment(values.recordedDate).format("YYYY-MM-DD")
      : null;
    values.transactionDate = values.transactionDate
      ? moment(values.transactionDate).format("YYYY-MM-DD")
      : null;

    let configPayload = checkAccessPermission(values);
    actions.postFees({ ...configPayload, medcaseId: id });
    closeModal();
  };

  const handleSaveStatus = (event) => {
    event.preventDefault();
    setIsSaveModal(false);
    actions.resetMessage();
  };

  const closeSaveModal = () => {
    setIsSaveModal(false);
    actions.resetMessage();
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name === "addRadio") {
      values.subRadio = false;
      if (String(values.amount).includes("-")) {
        values.amount = -values.amount;
      } else {
        values.amount = parseInt(values.amount);
      }
    }
    if (name === "subRadio") {
      values.amount = -values.amount;
      values.addRadio = false;
    }
    setValues({
      ...values,
      [name]: value
    });
  };
  const closeModal = () => {
    setIsOpenAddFee(false);
  };

  const configAddFee = finSnapshotConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd: false,
    setValues,
    disabled:
      values.amount && values.feeType && values.netProgramFee && values.transactionDate
        ? false
        : true
  });
  const addFees = () => {
    setValues(addFeeFormData);
    setIsOpenAddFee(true);
  };

  //Patient Payment
  const [openPatientPayments, setOpenPatientPayments] = useState(false);
  const [ppSnap, setPPSnap] = useState(false);

  const handlePatientPayments = () => {
    setOpenPatientPayments(true);
    setPPSnap(true);
  };
  const handleClose = () => {
    setOpenPatientPayments(false);
    setPPSnap(false);
  };
  const createPatientPayment = (data) => {
    data.amount = data.amount ? Number(data.amount) : 0;
    data.recordedDate = data.recordedDate ? moment(data.recordedDate).format("YYYY-MM-DD") : null;
    data.transactionDate = data.transactionDate
      ? moment(data.transactionDate).format("YYYY-MM-DD")
      : null;
    if (data.paymentType == "creditCard") {
      delete data.paymentType;
      let configPayload = checkAccessPermission(data);
      actions.addCreditCardPayment({ medcaseId: id, data: configPayload });
    } else if (data.paymentType == "check") {
      delete data.paymentType;
      let configPayload = checkAccessPermission(data);
      actions.addCheckPayment({ medcaseId: id, data: configPayload });
    } else if (data.paymentType == "loan") {
      delete data.paymentType;
      let configPayload = checkAccessPermission(data);
      actions.addLoanPayment({ medcaseId: id, data: configPayload });
    } else if (data.paymentType == "wireTransfer") {
      delete data.paymentType;
      let configPayload = checkAccessPermission(data);
      actions.addWiredPayment({ medcaseId: id, data: configPayload });
    }
    setOpenPatientPayments(false);
  };

  useEffect(() => {
    // console.log("successMsg", successMsg);
    if (successMsgEmail && successMsgEmail !== "") {
      setIsMessageModal(true);
    }
  }, [successMsgEmail]);
  useEffect(() => {
    // console.log("errorEmail", errorEmail);
    if (errorEmail && errorEmail !== "") {
      setIsWarningOpen(true);
    }
  }, [errorEmail]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetManageLab();
    actions.resetLabPayout();
    actions.resetPatientPayment();
    actions.resetManualReview();
    actions.resetRevenue();
    actions.resetMessage();
    actions.resetPostEmail();
    actions.resetEmail();
    initPage();
  };

  //Enroll Patient
  const [isEnrollOpen, setIsEnrollOpen] = useState(false);
  const openEnrollDialog = () => {
    setIsEnrollOpen(true);
  };

  useEffect(() => {
    if (isEmailSend === true) {
      sendEmail();
    }
  }, [isEmailSend]);

  const sendEmail = () => {
    let clinicalTreatmentNo = "";
    let authorizedTreatment = "";

    actions.postEmailOption({
      medcaseId: id,
      toAddresses: [],
      cycle: clinicalTreatmentNo,
      emailType: "PGTA_ENROLLMENT",
      requestedService: authorizedTreatment
    });

    setIsEmailSend(false);
    return "emailsend";
  };

  //Lab Payout
  const [isOpenLabPayout, setIsOpenLabPayout] = useState(false);
  const openLabPayout = () => {
    setIsOpenLabPayout(true);
  };
  const handleCloseLabPayout = () => {
    setIsOpenLabPayout(false);
  };
  const saveLabPayout = (data) => {
    data.amount = data.amount ? Number(data.amount) : 0;
    data.transactionDate = data.transactionDate
      ? moment(data.transactionDate).format("YYYY-MM-DD")
      : null;
    data.invoiceDate = data.invoiceDate ? moment(data.invoiceDate).format("YYYY-MM-DD") : null;
    actions.saveLabPayout({ ...data, medcaseId: id });
  };

  //Manage Lab
  const [isOpenManageLab, setIsOpenManageLab] = useState(false);

  const handleCloseManageLab = () => {
    setIsOpenManageLab(false);
  };

  const closeWarningModal = () => {
    setIsWarningOpen(false);
    actions.resetPatientPayment();
    initPage();
  };

  // const nearestTo100 = (amount) => {
  //   return amount.toLocaleString();
  // };

  const openRefundDialog = () => {
    setIsRefundOpen(true);
  };

  /*
   * Calculation for net patient payment for disable/ unable the Refund button.
   */
  const isNetPatientPayment = () => {
    console.log(
      "zhr checkk" +
        (financialSnapshotPaymentList &&
          financialSnapshotPaymentList.totalPatientPayment -
            financialSnapshotPaymentList.totalRefunds)
    );
    return (
      (financialSnapshotPaymentList &&
        financialSnapshotPaymentList.totalPatientPayment -
          financialSnapshotPaymentList.totalRefunds) <= 0
    );
  };

  const openRevenueDialog = () => {
    setIsRevenueOpen(true);
  };
  let commentFormData = {
    type: "",
    comment: "",
    markImp: false,
    commentType: ""
  };
  const [commentsValue, setCommentsValue] = useState(commentFormData);
  const [isOpenForComment, setIsOpenForComment] = useState(false);
  const [isAddForComment, setIsAddForComment] = useState(false);

  const handleInputChangeForComment = (e) => {
    var { name, value } = e.target;

    console.log("zhr finSnapshot: ", name, value);

    if (name == "markImp") {
      value = e.target.checked;
    }
    setCommentsValue({
      ...commentsValue,
      [name]: value
    });
  };
  const addNewComment = () => {
    setCommentsValue(commentFormData);
    setIsAddForComment(true);
    setIsOpenForComment(true);
  };
  const closeModalForComment = () => {
    setIsOpenForComment(false);
  };

  const chkChange = (e) => {
    // actions.updateManualReveiw({ medcaseId: id, isChecked: e.target.checked });
    let isCheck = e.target.checked;
    if (!isCheck) {
      console.log(isCheck);
      actions.updateManualReveiw({ medcaseId: id, isChecked: isCheck });
    } else {
      setIsChecked(isCheck);
      setIsClinicalChecked(medcaseHeader.isClinicalReview);
      addNewComment();
    }
  };

  const saveComment = (event) => {
    event.preventDefault();
    if (isAddForComment) {
      commentsValue.markImp = commentsValue.markImp ? commentsValue.markImp : false;
      commentsValue.medCaseId = id;
      actions.addNewComment(commentsValue);
    }

    actions.updateManualReveiw({ medcaseId: id, isChecked: isChecked });
    actions.updateClinicalReview({ medcaseId: id, isClinicalChecked: isClinicalChecked });

    closeModalForComment();
  };
  const commentConfiguration = commentConfig({
    commentsValue,
    onChange: handleInputChangeForComment,
    onClick: closeModalForComment,
    isAdd: isAddForComment,
    disabled: commentsValue.comment || commentsValue.commentType ? false : true
  });
  const chkChangeForClinicalReview = (e) => {
    let isCheck = e.target.checked;
    if (!isCheck) {
      actions.updateClinicalReview({ medcaseId: id, isClinicalChecked: isCheck });
    } else {
      setIsClinicalChecked(isCheck);
      setIsChecked(medcaseHeader.isManualReview);
      addNewComment();
    }
  };

  const calculateAmountDue = (dueAmount, totalrefund) => {
    let totalDue = 0;
    if (dueAmount >= 0) {
      totalDue = "$" + priceFormat(parseFloat(dueAmount) + parseFloat(totalrefund));
    } else {
      totalDue = priceFormat(parseFloat(dueAmount) + parseFloat(totalrefund)).replace("-", "-$");
    }

    return totalDue;
  };

  return (
    <>
      <Box sx={{ ml: 1, border: "1px solid #D3D3D3" }} className="m-r-8 m-t-10">
        <Stack
          direction="row"
          sx={{
            marginLeft: "21px",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "flex-start",
            alignItems: "center"
          }}>
          <InputLabel sx={{ fontWeight: "bold" }}>Program: &nbsp;</InputLabel>
          <Typography sx={{ fontWeight: "bold" }}>
            {programName.program === null ? "--" : programName.program}
          </Typography>
        </Stack>
        <Stack direction="row" className="pad-10 flex-between">
          <Stack direction="column" className="pad-10 flex-start">
            <InputLabel>Treatment: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              {programName.treatment === null ? "--" : programName.treatment}
            </Typography>
          </Stack>
          <Stack className="pad-10 flex-start">
            <InputLabel> Program Fee: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              ${" "}
              {programName.fee === null
                ? "--"
                : programName && programName.fee && priceFormat(programName.fee)}
            </Typography>
          </Stack>
          <Stack className="pad-10 flex-start">
            <InputLabel> Total Discount Amount: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              ${" "}
              {programName.totalDiscountAmount === null
                ? "--"
                : programName && priceFormat(programName.totalDiscountAmount)}
            </Typography>
          </Stack>
          <Stack className="pad-10 flex-start">
            <InputLabel> Remaining Program Fee: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              ${" "}
              {(programName &&
                programName.fee &&
                programName.totalDiscountAmount &&
                priceFormat(programName.fee - programName.totalDiscountAmount)) ||
                "0.00"}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" className="pad-8">
          <Button
            variant="contained"
            className="btn-usf"
            sx={{ mx: "10px" }}
            onClick={addFees}
            disabled={medcaseStatusListDisable.indexOf(medcaseHeader.medcaseStatus) > -1}>
            Fees
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            onClick={handlePatientPayments}
            sx={{ mx: "10px" }}
            disabled={medcaseStatusListDisable.indexOf(medcaseHeader.medcaseStatus) > -1}>
            Patient Payments
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            sx={{ mx: "10px" }}
            onClick={openEnrollDialog}
            disabled={enrollButtonDisable()}>
            Enroll
          </Button>

          <Button
            variant="contained"
            className="btn-usf"
            sx={{ mx: "10px" }}
            onClick={openRefundDialog}
            disabled={isNetPatientPayment()}>
            Refund
          </Button>
          <Button variant="contained" className="btn-usf" onClick={openRevenueDialog}>
            Revenue
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            style={{ marginLeft: "15px" }}
            onClick={openLabPayout}
            disabled={
              medcaseHeader.pgtaAnalysis == "Enrolled" ||
              medcaseHeader.pgtaAnalysis == "Pre-Enrolled"
                ? false
                : true
            }>
            Lab Payment
          </Button>
        </Stack>
        <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
          <Grid container sx={{ my: "5px" }}>
            <Grid item xs={6}>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Typography className={"m-l-5 m-t-5 trak-head-medtype non-pgta"}>Payment</Typography>
              <Divider
                sx={{
                  border: "1px solid",
                  color: "#13BFEA",
                  backgroundColor: "#13BFEA",
                  marginBottom: "8px"
                }}
              />
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography className={" m-l-5 m-t-5 table-title-bold"}>
                  Net Program Fees{" "}
                </Typography>
                <Typography className={" m-l-5 m-t-5 value-bold"}>
                  {(pgtaSummary && priceFormat(pgtaSummary.netFees)) || "0.00"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Patient Payments</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {(financialSnapshotPaymentList &&
                    priceFormat(financialSnapshotPaymentList.totalPatientPayment)) ||
                    "0.00"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Program Refunds </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {(financialSnapshotPaymentList &&
                    priceFormat(financialSnapshotPaymentList.programRefunds)) ||
                    "0.00"}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Pregnancy Loss Refunds</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {(financialSnapshotPaymentList &&
                    priceFormat(financialSnapshotPaymentList.pregnancyLossRefunds)) ||
                    0}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Miscellaneous Refunds</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {(financialSnapshotPaymentList &&
                    priceFormat(financialSnapshotPaymentList.miscellaneousRefunds)) ||
                    0}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Refunds </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {(financialSnapshotPaymentList &&
                    priceFormat(financialSnapshotPaymentList.totalRefunds)) ||
                    0}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography className={" m-l-5 m-t-5 table-title-bold"}>
                  Net Patient Payments{" "}
                </Typography>
                <Typography className={" m-l-5 m-t-5 value-bold"}>
                  $
                  {(financialSnapshotPaymentList &&
                    financialSnapshotPaymentList.totalPatientPayment &&
                    priceFormat(
                      financialSnapshotPaymentList.totalPatientPayment -
                        financialSnapshotPaymentList.totalRefunds
                    )) ||
                    0}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#FACFDA"
                }}>
                <Typography className={"m-l-5 m-t-5 table-title-bold"}>Amount Due</Typography>
                <Typography className={" m-l-5 m-t-5 value-bold"}>
                  {/* ${pgtaSummary.amountDue || 0.0} */}
                  {financialSnapshotPaymentList &&
                  financialSnapshotPaymentList.amountDue &&
                  parseFloat(financialSnapshotPaymentList.amountDue) +
                    parseFloat(financialSnapshotPaymentList.totalRefunds) >=
                    0
                    ? "$" +
                      priceFormat(
                        parseFloat(financialSnapshotPaymentList.amountDue) +
                          parseFloat(financialSnapshotPaymentList.totalRefunds)
                      )
                    : calculateAmountDue(
                        financialSnapshotPaymentList.amountDue,
                        financialSnapshotPaymentList.totalRefunds
                      )}
                  {/*  {financialSnapshotPaymentList &&
                    financialSnapshotPaymentList.amountDue &&
                    (financialSnapshotPaymentList.amountDue > 0
                      ? "$" +
                        nearestTo100(
                          parseFloat(financialSnapshotPaymentList.amountDue) +
                            parseFloat(financialSnapshotPaymentList.totalRefunds)
                        ).toLocaleString()
                      : (
                          parseFloat(financialSnapshotPaymentList.amountDue) +
                          parseFloat(financialSnapshotPaymentList.totalRefunds)
                        )
                          .toLocaleString()
                          .replace("-", "-$"))} */}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Typography className={"m-l-5 m-t-5 trak-head-medtype non-pgta"}>Revenue</Typography>
              <Divider
                sx={{
                  border: "1px solid",
                  color: "#13BFEA",
                  backgroundColor: "#13BFEA",
                  marginBottom: "8px"
                }}
              />
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Lab Payments </Typography>
                <Typography className="m-l-5 m-t-5">
                  ${(pgtaSummary && priceFormat(pgtaSummary.labPayout)) || 0}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Medical Cost Reserve </Typography>
                <Typography className="m-l-5 m-t-5">
                  ${revenueData && priceFormat(revenueData.medicalCostReserve)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Expenses </Typography>
                <Typography className="m-l-5 m-t-5">
                  ${revenueData && priceFormat(revenueData.totalExpenses)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography className={"m-l-5 m-t-5 table-title-bold"}>
                  Revenue Recognized
                </Typography>
                <Typography className={" m-l-5 m-t-5 value-bold"}>
                  ${revenueData && priceFormat(revenueData.revenueRecognized)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography className={"m-l-5 m-t-5 table-title-bold"}>Revenue Deferred</Typography>
                <Typography className={" m-l-5 m-t-5 value-bold"}>
                  ${revenueData && priceFormat(revenueData.revenueDeferred)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography className={"m-l-5 m-t-5 table-title-bold"}>
                  Contract Exp Date
                </Typography>
                <Typography className={" m-l-5 m-t-5 value-bold"}>
                  {revenueData?.contractExpDate &&
                    new Date(revenueData?.contractExpDate).toLocaleDateString()}
                </Typography>
              </Stack>
              <Grid container className="modal-stackMedCase">
                <Grid item xs={2}>
                  <InputLabel
                    style={{
                      whiteSpace: "break-spaces",
                      color: medcaseHeader.isManualReview == true ? "red" : "black"
                    }}>
                    Financial Review Required
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    size="small"
                    fullWidth
                    autoComplete="off"
                    name="manualReviewcheckbox"
                    checked={medcaseHeader.isManualReview == true ? true : false}
                    onChange={chkChange}
                    style={{
                      color: medcaseHeader.isManualReview == true ? "red" : "black"
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel
                    style={{
                      whiteSpace: "break-spaces",
                      color: medcaseHeader.isClinicalReview == true ? "red" : "black"
                    }}>
                    Clinical Review Required
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    size="small"
                    fullWidth
                    autoComplete="off"
                    name="clinicalReviewcheckbox"
                    checked={medcaseHeader.isClinicalReview == true ? true : false}
                    onChange={chkChangeForClinicalReview}
                    style={{
                      color: medcaseHeader.isClinicalReview == true ? "red" : "black"
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* <PatientPaymentsModal_test
          open={openPatientPayments}
          handleClose={handleClose}
          createPatientPayment={createPatientPayment}
          invoiceId={medcaseHeader?.medcaseInvoice}
          netProgramFee={pgtaSummary?.netProgramFees}
          totalPatientPayment={pgtaSummary?.totalPatientPayment || 0}
          amountDue={pgtaSummary?.amountDue}
        /> */}
      </Box>

      <Box style={{ marginTop: "20px" }}>
        <GeneticTransactionLog medcaseId={id} paymentCreated={successMsg || success} />
      </Box>

      <ClinicalComments medcaseType={medcaseHeader.medcaseType} />

      <FinancialComments medcaseType={medcaseHeader.medcaseType} />

      {openPatientPayments ? (
        <PatientPaymentsModal
          open={openPatientPayments}
          handleClose={handleClose}
          createPatientPayment={createPatientPayment}
          invoiceId={medcaseHeader?.medcaseInvoice}
          netProgramFee={pgtaSummary.netFees}
          error={errorPayment}
          ppSnap={ppSnap ? ppSnap : false}
        />
      ) : null}
      <CommonModal
        open={isOpenAddFee}
        config={configAddFee}
        isAdd={false}
        handleSubmit={saveAddFee}
      />
      <SaveModal open={saveModal} close={closeSaveModal} handleSave={handleSaveStatus} />
      {isEnrollOpen ? (
        <MedcaseEnroll
          openDialog={isEnrollOpen}
          medcaseId={id}
          close={() => {
            setIsEnrollOpen(false);
            initPage();
            setIsEmailSend(true);
          }}
          pgtaStatus={medcaseHeader.pgtaAnalysis}
          paymentStatus={medcaseHeader.paymentStatus}
          contractStatus={medcaseHeader.contractStatus}
          emailType={"PGTA_ENROLLMENT"}
        />
      ) : null}
      <MessageModal
        open={isMessageModal}
        message={successMsg || success || successRevenue}
        closeModal={closeMessageModal}
      />
      {isOpenLabPayout ? (
        <AddLabPayoutTransaction
          open={isOpenLabPayout}
          handleClose={handleCloseLabPayout}
          saveLabPayout={saveLabPayout}
          pgtaStatus={medcaseHeader.pgtaAnalysis}
        />
      ) : null}
      {isOpenManageLab ? (
        <ManageLabModal open={isOpenManageLab} handleClose={handleCloseManageLab} />
      ) : null}
      <WarnningMessageModal
        open={isWarningOpen}
        message={errorFee || errorPayment}
        patientPayment={patientPaymentWarning}
        closeModal={closeWarningModal}
        cancelModal={closeWarningModal}
      />
      <LoadingModal open={loadingFee || loadingPatient || loadingRevenue || loading} />
      {isRefundOpen ? (
        <MedcaseRefund
          openDialog={isRefundOpen}
          close={() => {
            setIsRefundOpen(false);
            initPage();
          }}
          netProgramFee={pgtaSummary?.netProgramFees}
        />
      ) : null}
      {isRevenueOpen ? (
        <AddRevenue
          openDialog={isRevenueOpen}
          close={() => {
            setIsRevenueOpen(false);
            initPage();
          }}
          netProgramFee={pgtaSummary?.netProgramFees}
          medcaseId={id}
          isPGTA={true}
        />
      ) : (
        ""
      )}

      <MessageModal
        open={isMessageModal}
        message={
          successRevenue ||
          manualReviewSuccess ||
          clinicalReviewSuccess ||
          errorFee ||
          "Patients Payment Applied"
        }
        //error={errorMsg}
        closeModal={closeMessageModal}
      />

      <CommonModal
        open={isOpenForComment}
        config={commentConfiguration}
        isAdd={isAddForComment}
        handleSubmit={saveComment}
      />

      {isMessageModal && successMsgEmail && (
        <MessageModal
          open={isMessageModal}
          message={successMsgEmail}
          closeModal={closeMessageModal}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  programNameReducer,
  geneticTestingReducer,
  statusReducer,
  patientPaymentReducer,
  transactionLogReducer,
  enrollReducer,
  financialSnapshotPaymentReducer,
  addRevenueReducer
}) => {
  return {
    programName: programNameReducer.programName,
    pgtaSummary: geneticTestingReducer.pgtaSummary,
    medcaseHeader: statusReducer.medcaseHeader,
    loading: geneticTestingReducer.loading,
    errorMsg: geneticTestingReducer.error,
    successMsg: patientPaymentReducer.success,
    errorPayment: patientPaymentReducer.error,
    loadingPatient: patientPaymentReducer.loading,
    success: geneticTestingReducer.success,
    successFee: transactionLogReducer.successFees,
    loadingFee: transactionLogReducer.loading,
    errorFee: transactionLogReducer.errorFee,
    successEnroll: enrollReducer.success,
    financialSnapshotPaymentList: financialSnapshotPaymentReducer.financialSnapshotPaymentList,
    financialSnapshotPgtaList: financialSnapshotPaymentReducer.financialSnapshotPgtaList,
    manualReviewSuccess: financialSnapshotPaymentReducer.manualReviewSuccess,
    clinicalReviewSuccess: financialSnapshotPaymentReducer.clinicalReviewSuccess,
    revenueData: addRevenueReducer.revenueData,
    successRevenue: addRevenueReducer.success,
    loadingRevenue: addRevenueReducer.loading,
    successMsgEmail: financialSnapshotPaymentReducer.success,
    errorEmail: financialSnapshotPaymentReducer.errorEmail
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPgtaSummary: (payload) => {
      dispatch(actionType.getPgtaSummary(payload));
    },
    getProgramName: (payload) => {
      dispatch(actionType.getProgramName(payload));
    },
    getMedcaseHeader: (payload) => {
      dispatch(actionType.getMedcaseHeader(payload));
    },
    postFees: (payload) => {
      dispatch(actionType.postFees(payload));
    },
    addCreditCardPayment: (payload) => {
      dispatch(actionType.addCreditCardPayment(payload));
    },
    addCheckPayment: (payload) => {
      dispatch(actionType.addCheckPayment(payload));
    },
    addLoanPayment: (payload) => {
      dispatch(actionType.addLoanPayment(payload));
    },
    addWiredPayment: (payload) => {
      dispatch(actionType.addWiredPayment(payload));
    },
    saveLabPayout: (payload) => {
      dispatch(actionType.saveLabPayout(payload));
    },
    resetPatientPayment: () => {
      dispatch(actionType.resetPatientPayment());
    },
    resetManageLab: () => {
      dispatch(actionType.resetManageLab());
    },
    resetLabPayout: () => {
      dispatch(actionType.resetLabPayout());
    },
    updateMedcaseStatusDdl: (payload) => {
      dispatch(actionType.updateMedcaseStatusDdl(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getFinancialSnapshotPayment: (payload) => {
      dispatch(actionType.getFinancialSnapshotPayment(payload));
    },
    getFinancialSnapshotPgta: (payload) => {
      dispatch(actionType.getFinancialSnapshotPgta(payload));
    },
    getRevenueFin: (payload) => {
      dispatch(actionType.getRevenueFin(payload));
    },
    updateManualReveiw: (payload) => {
      dispatch(actionType.updateManualReview(payload));
    },
    updateClinicalReview: (payload) => {
      dispatch(actionType.updateClinicalReview(payload));
    },
    resetRevenue: () => {
      dispatch(actionType.resetRevenue());
    },
    postEmailOption: (payload) => {
      dispatch(actionType.postEmailOption(payload));
    },
    resetEmail: () => {
      dispatch(actionType.resetEmailList());
    },
    resetPostEmail: () => {
      dispatch(actionType.resetPostEmailOption());
    },
    resetManualReview: () => {
      dispatch(actionType.resetManualReview());
    },
    getComment: (payload) => {
      dispatch(actionType.getComment(payload));
    },
    addNewComment: (payload) => {
      dispatch(actionType.addNewComment(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneticTesting);
