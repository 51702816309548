/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  Link
} from "@mui/material";
import { Stack } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { useEffect, useState } from "react";
import StyledMenu from "../../shared/components/styledMenu";
import { connect } from "react-redux";
import * as actionType from "../actions";
import {
  refundApplicationValidation,
  withdrawOptions,
  approvalStatus,
  declinedStatus
} from "../../shared/constants/constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const FAPDetails = (props) => {
  const practiceId = localStorage.getItem("practiceId");
  const pathname = location.pathname;
  const navigate = useNavigate();
  const {
    actions,
    refundQuestions = [],
    practiceUsers = [],
    loadingPractice,
    loadingRefundQstns,
    fapRow,
    estimateCards,
    estimateCardLoading,
    withdrawLoading,
    assignLoading,
    success,
    error,
    saveLoading,
    submitLoading,
    refundResponseLoading,
    refundResponse,
    approveRefundLoading,
    treatmentQstns,
    treatmentQstnsLoading,
    questions,
    loadingQuestions,
    cardClickAction
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [statusType, setStatusType] = useState("");
  const [refundApplicationStatus, setRefundApplicationStatus] = useState("");
  const [assignModal, setAssignModal] = useState(false);
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [active, setActive] = useState(false);
  const [cardIndex, setCardIndex] = useState({});
  const [nextQuestions, setNextQuestions] = useState([]);
  let initialValue = {
    rfaVersion: 2,
    raBMI: "",
    raNicGt3: "",
    raAMHorE2FSH: "",
    raMaxAMH: null,
    raE2: null,
    raFSH: null,
    raFamBalance: "",
    raSpermNOA: "",
    raNormalCavity: "",
    raCavityShape: "",
    raPrevMiscarriage: null,
    ptPrevCycles: ""
  };
  const [values, setValues] = useState(initialValue);
  const [successModal, setSuccessModal] = useState(false);
  const [noteSave, setNoteSave] = useState("");
  const { patientId } = useParams();
  useEffect(() => {
    actions.resetCardClick();
    if (refundQuestions.length == 0) {
      actions.getRefundQstns();
    }
    if (fapRow.estimateId) {
      let payload = {
        practiceId,
        estimateId: fapRow.estimateId
      };
      actions.getEstimateCard(payload);
    }
    if (Object.keys(questions).length == 0) {
      actions.getFinancialEstimateQuestions(practiceId);
    }
  }, []);
  useEffect(() => {
    if (practiceUsers.length) {
      setAssignTo(practiceUsers[0].contactId);
    }
    if (success || error) {
      setSuccessModal(true);
    }
  }, [loadingPractice, success, error]);

  useEffect(() => {
    if (error || success) {
      setSuccessModal(true);
    }
  }, [success, error]);

  useEffect(() => {
    if (refundResponse.responses != undefined) {
      setValues(refundResponse.responses);
    }
  }, [refundResponse]);
  useEffect(() => {
    if (treatmentQstns.length && questions.responses != undefined) {
      const nextQuestionsToRender = questions.responses.filter(
        (item) => item.responseNo == treatmentQstns[0].responseNo
      );
      setNextQuestions(nextQuestionsToRender[0].nextQuestions);
      setNextQuestions((prevRows) =>
        prevRows.map((row) =>
          treatmentQstns.some((b) => row.questionId === b.questionId)
            ? {
                ...row,
                checkedValue: row.responses.filter((a) =>
                  treatmentQstns.some(
                    (b) => a.responseNo === b.responseNo && b.questionId == row.questionId
                  )
                )[0].responsetext,
                responseNo: row.responses.filter((a) =>
                  treatmentQstns.some(
                    (b) => a.responseNo === b.responseNo && b.questionId == row.questionId
                  )
                )[0].responseNo
              }
            : row
        )
      );
    }
  }, [treatmentQstnsLoading, loadingQuestions]);
  const handleCardClick = (data, index) => {
    actions.resetRefundResponses();
    setValues(initialValue);
    actions.cardClickedAction();
    actions.getTreatmentPlanQuestions({
      estimateTpId: data.finEstimateTpId,
      estimateId: fapRow.estimateId
    });
    if (data.refundApplicationStatus !== null) {
      actions.getRefundQuestionResponse({
        estimateId: fapRow.estimateId,
        estimateTpId: data.finEstimateTpId
      });
    }
    setCardIndex(index);
    setSelectedItem(data);
  };
  useEffect(() => {
    if (estimateCards?.length > 0) {
      handleCardClick(estimateCards[0], 0);
    }
  }, [estimateCards]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openWithdraw = () => {
    setWithdrawModal(true);
    handleClose();
  };
  const closeWithdrawModal = () => {
    setWithdrawModal(false);
  };

  const handleWithdraw = () => {
    let payload = {
      estimateId: fapRow.estimateId,
      estimateTpId: fapRow.estimateTpId,
      withdrawReason: otherReason == "" ? reason : otherReason,
      query: "withdraw"
    };
    actions.withdrawRefundApplication(payload);
  };

  const openAssignApp = () => {
    actions.getPracticeUsers(practiceId);
    handleClose();
    setAssignModal(true);
  };

  const closeAssignModal = () => {
    setAssignModal(false);
  };
  const closeStatusChangeModal = () => {
    setStatusChangeModal(false);
  };

  const saveAssigne = () => {
    let assigneeName = practiceUsers.filter((item) => item.contactId === assignTo)[0];
    let payload = {
      estimateId: fapRow.estimateId,
      estimateTpId: fapRow.estimateTpId,
      assignee: assigneeName.firstName + "-" + assigneeName.lastName
    };
    actions.saveAsignee(payload);
  };

  const handleCancel = () => {
    setValues(initialValue);
  };

  const handleSave = () => {
    let payload = {
      estimateId: fapRow.estimateId,
      estimateTpId: fapRow.estimateTpId,
      values,
      refundApplicationNotes: noteSave,
      query: "commit"
    };
    console.log("values", values);
    console.log("noteSave", noteSave);
    actions.saveRefundApplication(payload);
  };

  // const handleSaveSubmit = () => {
  //   let payload = {
  //     estimateId: fapRow.estimateId,
  //     estimateTpId: fapRow.estimateTpId,
  //     values,
  //     query: "commit"
  //   };
  //   actions.saveRefundApplication(payload);
  // };

  const closeSuccessModal = () => {
    setSuccessModal(false);
    closeWithdrawModal();
    closeAssignModal();
    setStatusChangeModal(false);
    actions.resetWithdraw();
    actions.resetAssignee();
    actions.resetSaveRefundApp();
    actions.resetSubmit();
    actions.resetCardClick();
    let payload = {
      practiceId,
      estimateId: fapRow.estimateId
    };
    actions.getEstimateCard(payload);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (refundApplicationValidation(name, value)) {
      return;
    }
    setValues({
      ...values,
      [name]: value === "" ? null : value
    });
  };

  const handleNoteSaveChange = (e) => {
    const note = e.target.value;
    setNoteSave(note);
  };

  const handleApproval = (e) => {
    setStatusChangeModal(true);
    let status = e.target.name;
    console.log("status", status);
    setStatusType(status);
  };

  const upadteStatus = () => {
    let payload = {
      estimateId: fapRow.estimateId,
      estimateTpId: fapRow.estimateTpId,
      refundApplicationNotes: noteSave,
      refundApplicationStatus: refundApplicationStatus,

      query: "review"
    };
    console.log("payload", payload);
    actions.approveDenyRefundApplication(payload);
  };

  const handleLinkedEstimateClick = (event, fapRow) => {
    event.stopPropagation();
    event.preventDefault();
    actions.resetEstimateViewList();
    navigate(`/workspace/financial-estimates/${patientId}?estimateId=${fapRow.estimateId}&PT=PD`);
  };

  return (
    <Box className="m-l-r-12">
      <Grid container className="p-16 financial-directory">
        <Grid item xs={6}>
          <Stack direction="row">
            <Typography variant="body2">Detailed Tab of Fertility Access Program </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" className="align-end">
            <Button
              variant="contained"
              id="GDM-customized-button"
              aria-controls={open ? "GDM-customized-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}>
              Action
            </Button>
            <StyledMenu
              id="GDM-customized-button"
              MenuListProps={{
                "aria-labelledby": "GDM-customized-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem
                onClick={openWithdraw}
                disableRipple
                disabled={
                  selectedItem.refundApplicationStatus === "Application Withdrawn" ? true : false
                }>
                Withdraw Application
              </MenuItem>
              <MenuItem
                onClick={openAssignApp}
                disableRipple
                disabled={
                  selectedItem.refundApplicationStatus === "Application Withdrawn" ? true : false
                }>
                Assign Incomplete Application
              </MenuItem>
            </StyledMenu>
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Stack>
            <InputLabel>Linked Estimate</InputLabel>
            <Typography variant="subtitle1">
              <Link
                className="go-to-application"
                underline="none"
                onClick={(event) => {
                  handleLinkedEstimateClick(event, fapRow);
                }}>
                {`${fapRow.estimateType == "D" ? "Detailed " : "Preliminary "}` +
                  fapRow.estimateDescription}
              </Link>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <InputLabel>Estimate Status</InputLabel>
            <Typography variant="subtitle1">{fapRow.estimateStatus}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Box my={1}>
        <Typography variant="bold">Treatment Information</Typography>
      </Box>
      <Divider className="modal-divider" />
      {estimateCardLoading ? (
        <Stack justifyContent="center" p={5} alignItems="center">
          <CircularProgress size={50} />
        </Stack>
      ) : (
        <Grid container spacing={1}>
          {estimateCards.map((data, index) => {
            return (
              <>
                {data.treatmentType.indexOf("IVF") > -1 ? (
                  <Grid item xs={4} my={1} key={index}>
                    <Card
                      variant="outlined"
                      sx={{
                        backgroundColor: cardIndex == index ? "#f9f9f9" : "",
                        border: cardIndex == index ? "2px solid gray" : ""
                      }}>
                      <CardActionArea
                        disabled={data.treatmentType.indexOf("IVF") == -1}
                        onClick={() => {
                          handleCardClick(data, index);
                        }}>
                        <Stack m={1}>
                          <Typography variant="subtitle2">
                            {data.treatmentPlanName}
                            {data.assist ? " + With Assist" : ""}
                          </Typography>
                        </Stack>
                        <Divider sx={{ background: "#1BA3AD", m: 0.5 }} />
                        <Stack m={1}>
                          {data.treatmentType.indexOf("IVF") > -1 ? (
                            <Stack direction="row">
                              <InputLabel
                                className="word-break"
                                sx={{
                                  color: cardIndex == index ? "black" : "gray"
                                }}>
                                Refund Application status :{" "}
                                {data.refundApplicationStatus == null
                                  ? "Incomplete"
                                  : data.refundApplicationStatus}
                              </InputLabel>
                            </Stack>
                          ) : null}
                          <InputLabel
                            sx={{
                              color: cardIndex == index ? "black" : "gray"
                            }}>
                            Created On :{" "}
                            {data.refundCreationDate
                              ? new Date(data.refundCreationDate).toLocaleDateString()
                              : "-"}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              color: cardIndex == index ? "black" : "gray"
                            }}>
                            Created By : {data.refundCreatedBy}
                          </InputLabel>
                        </Stack>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ) : null}
              </>
            );
          })}
        </Grid>
      )}

      {/* list of qusetions selected during treatment plan selection */}

      {cardClickAction ? (
        <Card variant="outlined" className="p-8 m-t-b-16">
          <Box>
            <Stack my={1}>
              <Typography>To edit the treatment plan, see the Estimates section</Typography>
            </Stack>
            {treatmentQstnsLoading || refundResponseLoading ? (
              <Stack justifyContent="center" p={5} alignItems="center">
                <CircularProgress size={50} />
              </Stack>
            ) : (
              <>
                <Grid container className="row-gap-8 p-8 financial-directory" direction="row">
                  <Grid item xs={6}>
                    <Stack>
                      <InputLabel className="word-wrap">Treatment Plan</InputLabel>
                      <Typography>IVF</Typography>
                    </Stack>
                  </Grid>
                  {nextQuestions.map((item, index) => {
                    return (
                      <Grid key={index} item xs={6}>
                        <Stack>
                          <InputLabel className="word-wrap">{item.questionText}</InputLabel>
                          <Typography>
                            {item.checkedValue == undefined ? "--" : item.checkedValue}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container direction="column" className="gap-2 mt-16">
                  <Grid item>
                    <Stack direction="row">
                      <Typography variant="bold">Refund Eligibility Application</Typography>
                    </Stack>
                  </Grid>
                  <Divider className="modal-divider" />
                  <Grid item>
                    <Stack>
                      {/* <FormControl>
                        <Stack direction="row">
                          <FormLabel required id="raBMI-radio-buttons-group">
                            {`Are Both Egg Source & Carrier's BMI Less than 37?`}
                          </FormLabel>
                          <Tooltip
                            title="Egg Source and Carrier can be the Parent, Donor, Intended Parent or GC"
                            arrow
                            followCursor={true}>
                            <ErrorOutlineIcon fontSize="medium" />
                          </Tooltip>
                        </Stack>

                        <RadioGroup
                          aria-labelledby="raBMI-radio-buttons-group"
                          value={values.raBMI}
                          onChange={handleChange}
                          name="raBMI">
                          <FormControlLabel
                            className="fit-content"
                            value="yes"
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            className="fit-content"
                            value="no"
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {values.raBMI === "yes" ? (
                        <FormControl required>
                          <Stack direction="row">
                            <FormLabel required id="raNicGt3-radio-buttons-group">
                              {`Has Egg Source and Carrier been Free of Nicotine for 3 Months or Longer?`}
                            </FormLabel>
                            <Tooltip
                              title="Egg Source and Carrier can be the Parent, Donor, Intended Parent or GC"
                              arrow
                              followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <RadioGroup
                            aria-labelledby="raNicGt3-radio-buttons-group"
                            value={values.raNicGt3}
                            onChange={handleChange}
                            name="raNicGt3">
                            <FormControlLabel
                              className="fit-content"
                              value="yes"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="fit-content"
                              value="no"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null} */}
                      <FormControl required>
                        <Stack direction="row">
                          <FormLabel required id="raAMHorE2FSH-radio-buttons-group">
                            {`Has there been an AMH in the past 12 months and/or (E2 & FSH) within the last 6 Mo.?`}
                          </FormLabel>
                          <Tooltip
                            title="Enter either the AMH (within the last 12 mo) or  E2/FSH combination (within the last 6 Mo.)"
                            arrow
                            followCursor={true}>
                            <ErrorOutlineIcon fontSize="medium" />
                          </Tooltip>
                        </Stack>

                        <RadioGroup
                          aria-labelledby="raAMHorE2FSH-radio-buttons-group"
                          name="raAMHorE2FSH"
                          value={values.raAMHorE2FSH}
                          onChange={handleChange}>
                          <FormControlLabel
                            className="fit-content"
                            value="amh"
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            control={<Radio />}
                            label="AMH"
                          />
                          <FormControlLabel
                            className="fit-content"
                            value="e2fsh"
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            control={<Radio />}
                            label="E2 and FSH"
                          />
                        </RadioGroup>
                      </FormControl>
                      {values.raAMHorE2FSH === "amh" ? (
                        <FormControl>
                          <Stack direction="row">
                            <FormLabel required id="raMaxAMH-radio-buttons-group">
                              Lowest AMH Level in the past 12 months
                            </FormLabel>
                            <Tooltip title="Possible values 0 to 50" arrow followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <TextField
                            aria-labelledby="raMaxAMH-radio-buttons-group"
                            sx={{ width: "50%", py: 1 }}
                            onChange={handleChange}
                            required
                            InputProps={{ inputProps: { min: 0, max: 50 } }}
                            type="number"
                            value={values.raMaxAMH}
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            autoComplete="false"
                            name="raMaxAMH"
                          />
                        </FormControl>
                      ) : null}
                      {values.raAMHorE2FSH === "e2fsh" ? (
                        <FormControl>
                          <Stack direction="row">
                            <FormLabel required id="raE2-radio-buttons-group">
                              E2 (Must be same date as FSH within 6 mo)
                            </FormLabel>
                            <Tooltip title="Possible values 0 to 2000" arrow followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <TextField
                            aria-labelledby="raE2-radio-buttons-group"
                            sx={{ width: "50%", py: 1 }}
                            onChange={handleChange}
                            required
                            InputProps={{ inputProps: { min: 0, max: 2000 } }}
                            type="number"
                            value={values.raE2}
                            autoComplete="false"
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            name="raE2"
                          />
                        </FormControl>
                      ) : null}

                      {/* {values.raE2 >= 75 &&} */}
                      {values.raE2 !== "" && values.raE2 !== null && values.raE2 <= 99.9 ? (
                        <FormControl>
                          <Stack direction="row">
                            <FormLabel required id="raFSH-radio-buttons-group">
                              FSH (Must be same date as E2 within 6 mo)
                            </FormLabel>
                            <Tooltip title="Possible values 0 to 100" arrow followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <TextField
                            sx={{ width: "50%", py: 1 }}
                            onChange={handleChange}
                            required
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            type="number"
                            value={values.raFSH}
                            disabled={
                              selectedItem.refundApplicationStatus == "Submitted Approved" ||
                              selectedItem.refundApplicationStatus == "Submitted Denied" ||
                              selectedItem.refundApplicationStatus ==
                                "Submitted Need Clinical Review" ||
                              selectedItem.refundApplicationStatus == "Withdrawn"
                            }
                            autoComplete="false"
                            name="raFSH"
                          />
                        </FormControl>
                      ) : null}
                      {(values.raMaxAMH !== "" && values.raMaxAMH !== null) ||
                      values.raFSH /* && values.raFSH <= 12 */ ? (
                        <FormControl required>
                          <Stack direction="row">
                            <FormLabel required id="raFamBalance-radio-buttons-group">
                              {`Is Intended Parent Performing PGT-SR or any PGT Testing for Sex Selection?`}
                            </FormLabel>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {
                                    "PGT has three categories:  \n 1) PGT for aneuploidies (PGT-A) Previously PGS. \n 2) PGT for monogenic/single gene defects (PGT-M) Previously PGD. \n 3) PGT for chromosomal structural rearrangements (PGT-SR) Previously PGS translocation."
                                  }
                                </span>
                              }
                              // title="PGT has three categories: \n 1) PGT for aneuploidies (PGT-A) Previously PGS. {CR} 2) PGT for monogenic/single gene defects (PGT-M) Previously PGD. {CR} 3) PGT for chromosomal structural rearrangements (PGT-SR) Previously PGS translocation."
                              arrow
                              followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <RadioGroup
                            aria-labelledby="raFamBalance-radio-buttons-group"
                            onChange={handleChange}
                            required
                            value={values.raFamBalance}
                            name="raFamBalance">
                            <FormControlLabel
                              className="fit-content"
                              value="yes"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="fit-content"
                              value="no"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                      {values.raFamBalance === "no" ? (
                        <FormControl>
                          <Stack direction="row">
                            <FormLabel required id="raSpermNOA-radio-buttons-group">
                              {`Does Sperm Provider have Non-obstructive Azoospermia?`}
                            </FormLabel>
                            <Tooltip
                              title="Sperm Provider can be partner or donor as source"
                              arrow
                              followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <RadioGroup
                            aria-labelledby="raSpermNOA-radio-buttons-group"
                            onChange={handleChange}
                            required
                            value={values.raSpermNOA}
                            name="raSpermNOA">
                            <FormControlLabel
                              className="fit-content"
                              value="yes"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="fit-content"
                              value="no"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                      {values.raSpermNOA === "no" ? (
                        <FormControl>
                          <FormLabel required id="raNormalCavity-radio-buttons-group">
                            {`Carrier has Normal (or Repairable) Uterine Cavity/Hydros`}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="raNormalCavity-radio-buttons-group"
                            onChange={handleChange}
                            required
                            value={values.raNormalCavity}
                            name="raNormalCavity">
                            <FormControlLabel
                              className="fit-content"
                              value="yes"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="fit-content"
                              value="no"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                      {values.raNormalCavity === "yes" ? (
                        <FormControl>
                          <FormLabel required id="raCavityShape-radio-buttons-group">
                            {`Is the shape Septate, Unicornuate, Bicornuate or Didelphys?`}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="raCavityShape-radio-buttons-group"
                            onChange={handleChange}
                            required
                            value={values.raCavityShape}
                            name="raCavityShape">
                            <FormControlLabel
                              className="fit-content"
                              value="yes"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="fit-content"
                              value="no"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                      {values.raCavityShape === "no" && values.raNormalCavity === "yes" ? (
                        <FormControl>
                          <Stack direction="row">
                            <FormLabel required id="raPrevMiscarriage-radio-buttons-group">
                              Enter the number of Previous Miscarriages?
                            </FormLabel>
                            <Tooltip
                              title="Previous miscarriage defined as spontaneous abortion"
                              arrow
                              followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <Stack direction="row">
                            <TextField
                              sx={{ width: "50%", py: 1 }}
                              onChange={handleChange}
                              required
                              InputProps={{ inputProps: { min: 0, max: 15 } }}
                              type="number"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              autoComplete="false"
                              value={values.raPrevMiscarriage}
                              name="raPrevMiscarriage"
                            />

                            <Tooltip title="Possible values 0 to 15" arrow followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>
                        </FormControl>
                      ) : null}

                      {values.raPrevMiscarriage !== "" && values.raPrevMiscarriage !== null ? (
                        <FormControl>
                          <Stack direction="row">
                            <FormLabel required id="ptPrevCycles-radio-buttons-group">
                              {`Did Patient have any Previous Failed or Canceled Cycles?`}
                            </FormLabel>
                            <Tooltip
                              title="Only clinically canceled cycles pertain to this question"
                              arrow
                              followCursor={true}>
                              <ErrorOutlineIcon fontSize="medium" />
                            </Tooltip>
                          </Stack>

                          <RadioGroup
                            aria-labelledby="ptPrevCycles-radio-buttons-group"
                            onChange={handleChange}
                            required
                            value={values.ptPrevCycles}
                            name="ptPrevCycles">
                            <FormControlLabel
                              className="fit-content"
                              value="yes"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              className="fit-content"
                              value="no"
                              disabled={
                                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                                selectedItem.refundApplicationStatus ==
                                  "Submitted Need Clinical Review" ||
                                selectedItem.refundApplicationStatus == "Withdrawn"
                              }
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : null}
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Grid item></Grid>

          <Stack direction="row" justifyContent="flex-end" gap={1} my={1}>
            {pathname.indexOf("trak") != -1 ? (
              <Button
                variant="outlined"
                disabled={approveRefundLoading}
                onClick={() => navigate("/trak/refund-application")}>
                Cancel
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={handleCancel}
                disabled={
                  selectedItem.refundApplicationStatus == "Submitted Approved" ||
                  selectedItem.refundApplicationStatus == "Submitted Denied" ||
                  selectedItem.refundApplicationStatus == "Submitted Need Clinical Review" ||
                  selectedItem.refundApplicationStatus == "Withdrawn"
                }>
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={
                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                selectedItem.refundApplicationStatus == "Submitted Need Clinical Review" ||
                selectedItem.refundApplicationStatus == "Withdrawn"
              }>
              Save
            </Button>
            {/* <Button
              variant="contained"
              onClick={handleSaveSubmit}
              disabled={
                selectedItem.refundApplicationStatus == "Submitted Approved" ||
                selectedItem.refundApplicationStatus == "Submitted Denied" ||
                selectedItem.refundApplicationStatus == "Submitted Need Clinical Review" ||
                selectedItem.refundApplicationStatus == "Withdrawn"
              }>
              Save & Submit
            </Button> */}
            {pathname.indexOf("trak") != -1 && (
              <>
                <Button
                  variant="outlined"
                  onClick={handleApproval}
                  disabled={approveRefundLoading}
                  name="denied">
                  Decline
                </Button>
                <Button
                  variant="contained"
                  onClick={handleApproval}
                  disabled={approveRefundLoading}
                  name="approved">
                  Approve
                </Button>
              </>
            )}
          </Stack>
        </Card>
      ) : null}

      {withdrawModal ? (
        <Modal open={withdrawModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Withdraw Application</Typography>
              <IconButton onClick={closeWithdrawModal} disabled={withdrawLoading}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Stack direction="column">
                <InputLabel>Reason for withdrawn</InputLabel>
                <Select
                  value={reason}
                  disabled={withdrawLoading}
                  sx={{ my: 1 }}
                  size="small"
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  fullWidth>
                  {withdrawOptions.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}

                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {reason === "other" ? (
                  <TextField
                    value={otherReason}
                    onChange={(e) => {
                      setOtherReason(e.target.value);
                    }}
                  />
                ) : null}
              </Stack>
              <Typography my={1}>
                Note:- Once an application is withdrawn, it can no longer be changed in any way
              </Typography>
              <Stack direction="row" gap={1} justifyContent="end" alignItems="center">
                <Button variant="outlined" onClick={closeWithdrawModal} disabled={withdrawLoading}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={(reason == "" && otherReason == "") || withdrawLoading}
                  onClick={handleWithdraw}>
                  Withdraw
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      ) : null}
      <Modal
        open={withdrawLoading || approveRefundLoading}
        backdrop="static"
        aria-labelledby="modal-add-title">
        <Box className="alert-modal" width="100px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <CircularProgress size={50} />
          </Box>
        </Box>
      </Modal>
      {assignModal ? (
        <Modal open={assignModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Assign Incomplete Application</Typography>
              <IconButton onClick={closeAssignModal} disabled={assignLoading}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Stack direction="column">
                <InputLabel>Assigned To</InputLabel>
                <Select
                  value={assignTo}
                  sx={{ my: 1 }}
                  size="small"
                  disabled={assignLoading}
                  onChange={(e) => {
                    let name = practiceUsers.filter((item) => item.contactId === e.target.value);
                    setAssignTo(name[0].contactId);
                  }}
                  fullWidth>
                  {practiceUsers.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.contactId}>
                        {item.firstName}-{item.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>

              <Stack direction="row" gap={1} justifyContent="end" alignItems="center">
                <Button variant="outlined" onClick={closeAssignModal} disabled={assignLoading}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={saveAssigne} disabled={assignLoading}>
                  Save
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      ) : null}

      {/* Success Modal */}
      {successModal ? (
        <Modal open={successModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Stack direction="row" className="modal-stack">
              <Typography>
                {success == "Submitted Approved"
                  ? success
                  : success == "Submitted Denied"
                  ? success
                  : success == "Submitted Need Clinical Review"
                  ? success
                  : "Alert"}
              </Typography>
              <IconButton onClick={closeSuccessModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
              {success && (
                <Typography variant="body2" color="#42ba96">
                  {success == "Incomplete"
                    ? "Saved Successfully"
                    : success == "Submitted Approved"
                    ? "Congratulations! The Application has been approved"
                    : success == "Submitted Denied"
                    ? "Sorry! Your Refund Application has been denied"
                    : success == "Submitted Need Clinical Review"
                    ? "Your Refund Application need Clinical Review"
                    : success}
                </Typography>
              )}
              {error && (
                <Typography variant="body2" color="#A6315C">
                  {error}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
              <Button variant="contained" className="btn-primary" onClick={closeSuccessModal}>
                OK
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {submitLoading || saveLoading ? (
        <Modal open={saveLoading} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}

      {statusChangeModal ? (
        <Modal open={statusChangeModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Refund Application Status Update</Typography>
              <IconButton onClick={closeStatusChangeModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Typography my={1}>
                Are you sure you want to update the Refund Application Status to (new status
                selected)? Once the Status is updated, the practice will be notified of your
                decision and the patient financial portal will be updated accordingly.
              </Typography>
              <Stack direction="column" sx={{ marginBottom: 4 }}>
                <Typography my={1}>Notes</Typography>
                <TextareaAutosize
                  minRows={3}
                  style={{ width: "100%", height: 100 }}
                  onChange={handleNoteSaveChange}
                  value={selectedItem.refundApplicationNotes}
                />
              </Stack>
              <Stack direction="column">
                <InputLabel>Status</InputLabel>
                {statusType === "approved" ? (
                  <Select
                    value={refundApplicationStatus}
                    sx={{ my: 1 }}
                    size="small"
                    onChange={(e) => {
                      setRefundApplicationStatus(e.target.value);
                    }}
                    fullWidth>
                    {approvalStatus.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.value}>
                          {item.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    value={refundApplicationStatus}
                    sx={{ my: 1 }}
                    size="small"
                    onChange={(e) => {
                      setRefundApplicationStatus(e.target.value);
                    }}
                    fullWidth>
                    {declinedStatus.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.value}>
                          {item.key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </Stack>

              <Stack direction="row" gap={1} justifyContent="end" alignItems="center">
                <Button variant="outlined" onClick={closeStatusChangeModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={refundApplicationStatus == "" && noteSave == ""}
                  onClick={upadteStatus}>
                  SAVE
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({ estimateReducer, refundApplicationReducer, costCoverageReducer }) => {
  return {
    refundQuestions: refundApplicationReducer.refundQuestions,
    practiceUsers: refundApplicationReducer.practiceUsers,
    loading: refundApplicationReducer.loading,
    loadingPractice: refundApplicationReducer.loadingPractice,
    loadingRefundQstns: refundApplicationReducer.loadingRefundQstns,
    success: refundApplicationReducer.success,
    error: refundApplicationReducer.error,
    fapRow: refundApplicationReducer.fapRow,
    estimateCards: estimateReducer.estimateCards,
    estimateCardLoading: estimateReducer.estimateCardLoading,
    withdrawLoading: refundApplicationReducer.withdrawLoading,
    assignLoading: refundApplicationReducer.assignLoading,
    saveLoading: refundApplicationReducer.saveLoading,
    submitLoading: refundApplicationReducer.submitLoading,
    refundResponseLoading: refundApplicationReducer.refundResponseLoading,
    refundResponse: refundApplicationReducer.refundResponse,
    approveRefundLoading: refundApplicationReducer.approveRefundLoading,
    adjustments: costCoverageReducer.adjustmentList,
    fapLoading: estimateReducer.fapLoading,
    cardClickAction: estimateReducer.cardClickAction,
    updateAdjustment: costCoverageReducer.updateAdjustment,
    treatmentQstns: estimateReducer.treatmentQstns,
    treatmentQstnsLoading: estimateReducer.treatmentQstnsLoading,
    questions: estimateReducer.questions,
    loadingQuestions: estimateReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getRefundQstns: () => {
      dispatch(actionType.getRefundQstns());
    },
    withdrawRefundApplication: (payload) => {
      dispatch(actionType.withdrawRefundApplication(payload));
    },
    getPracticeUsers: (payload) => {
      dispatch(actionType.getPracticeUsers(payload));
    },
    saveAsignee: (payload) => {
      dispatch(actionType.saveAsignee(payload));
    },
    saveRefundApplication: (payload) => {
      dispatch(actionType.saveRefundApplication(payload));
    },
    saveDraftRefundApplication: (payload) => {
      dispatch(actionType.saveDraftRefundApplication(payload));
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    resetWithdraw: () => {
      dispatch(actionType.resetWithdraw());
    },
    resetAssignee: () => {
      dispatch(actionType.resetAssignee());
    },
    resetSaveRefundApp: () => {
      dispatch(actionType.resetSaveRefundApp());
    },
    resetSubmit: () => {
      dispatch(actionType.resetSubmit());
    },
    getRefundQuestionResponse: (payload) => {
      dispatch(actionType.getRefundQuestionResponse(payload));
    },
    approveDenyRefundApplication: (payload) => {
      dispatch(actionType.approveDenyRefundApplication(payload));
    },
    resetRefundResponses: () => {
      dispatch(actionType.resetRefundResponses());
    },
    FapRowData: (payload) => {
      dispatch(actionType.FapRowData(payload));
    },
    getFinancialEstimateQuestions: (practiceId) => {
      dispatch(actionType.getFinancialEstimateQuestions(practiceId));
    },
    getTreatmentPlanQuestions: (payload) => {
      dispatch(actionType.getTreatmentPlanQuestions(payload));
    },
    cardClickedAction: () => {
      dispatch(actionType.cardClickedAction());
    },
    resetCardClick: () => {
      dispatch(actionType.resetCardClick());
    },
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FAPDetails);
