import * as actionType from "./ActionTypes";

// Comment
export const getComment = (payload) => {
  return {
    type: actionType.COMMENT_LIST_REQUESTED,
    payload: payload
  };
};

export const getMiscellaneousData = (payload) => {
  return {
    type: actionType.MISCELLANEOUS_DATA_REQUESTED,
    payload: payload
  };
};

export const updatedMiscellenousData = (payload) => {
  return {
    type: actionType.MISCELLANEOUS_DATA_UPDATE_REQUESTED,
    payload: payload
  };
};

export const getPatientContactData = (payload) => {
  return {
    type: actionType.PATIENT_CONTACT_DATA_REQUESTED,
    payload: payload
  };
};

export const updatedPatientContactData = (payload) => {
  return {
    type: actionType.PATIENT_CONTACT_DATA_UPDATE_REQUESTED,
    payload: payload
  };
};

export const getEnrolmentData = (payload) => {
  return {
    type: actionType.ENROLMENT_DATA_REQUESTED,
    payload: payload
  };
};

export const updatedEnrolmentData = (payload) => {
  return {
    type: actionType.ENROLMENT_DATA_UPDATE_REQUESTED,
    payload: payload
  };
};

export const getMetaData = () => {
  return {
    type: actionType.METADATA_DATA_REQUESTED
  };
};

export const getProgram = (payload) => {
  return {
    type: actionType.PROGRAM_DATA_REQUESTED,
    payload: payload
  };
};

export const getPhysician = (payload) => {
  return {
    type: actionType.PHYSICIAN_DATA_REQUESTED,
    payload: payload
  };
};
export const getAccountFlag = (payload) => {
  return {
    type: actionType.ACCOUNT_FLAG_DATA_REQUESTED,
    payload: payload
  };
};

export const updatedAccountFlagData = (payload) => {
  return {
    type: actionType.ACCOUNT_FLAG_DATA_UPDATE_REQUESTED,
    payload: payload
  };
};

export const addNewComment = (payload) => {
  return {
    type: actionType.ADD_NEW_COMMENT_REQUESTED,
    payload: payload
  };
};
export const resetCommentList = () => {
  return {
    type: actionType.RESET_COMMENT_LIST
  };
};
export const resetComment = () => {
  return {
    type: actionType.RESET_COMMENT
  };
};
export const addFollowUpDate = (payload) => {
  return {
    type: actionType.ADD_FOLLOW_UP_DATE_REQUEST,
    payload: payload
  };
};
export const resetMessage = () => {
  return {
    type: actionType.RESET_MESSAGE,
    error: false,
    successPR: null,
    errorPR: null,
    errorMsg: ""
  };
};
export const resetTransactionMessage = () => {
  return {
    type: actionType.RESET_TRANSACTION_MESSAGE
  };
};
export const resetAPIMessage = () => {
  return {
    type: actionType.RESET_API_MESSAGE,
    success: false,
    error: false
  };
};

// Document
export const getDocument = (payload) => {
  return {
    type: actionType.DOCUMENT_LIST_REQUESTED,
    payload: payload
  };
};

export const postFees = (payload) => {
  return {
    type: actionType.POST_FEES_REQUESTED,
    payload: payload
  };
};

export const addNewDocument = (payload) => {
  return {
    type: actionType.ADD_NEW_DOCUMENT_REQUESTED,
    payload: payload
  };
};
export const editDocument = (payload) => {
  return {
    type: actionType.EDIT_DOCUMENT_REQUESTED,
    payload: payload
  };
};
export const resetDocumentList = () => {
  return {
    type: actionType.RESET_DOCUMENT_LIST
  };
};
export const resetDocument = () => {
  return {
    type: actionType.RESET_DOCUMENT
  };
};

// Status
export const getStatus = (payload) => {
  return {
    type: actionType.STATUS_LIST_REQUESTED,
    payload: payload
  };
};

export const addNewStatus = (payload) => {
  return {
    type: actionType.ADD_NEW_STATUS_REQUESTED,
    payload: payload
  };
};
export const resetStatusList = () => {
  return {
    type: actionType.RESET_STATUS_LIST
  };
};
export const resetAddStatus = () => {
  return {
    type: actionType.RESET_ADD_STATUS
  };
};
export const resetStatus = () => {
  return {
    type: actionType.RESET_STATUS
  };
};

export const getMedcaseList = (payload) => {
  return {
    type: actionType.MEDCASE_LIST_REQUESTED,
    payload: payload
  };
};

export const getTransactionLog = (payload) => {
  return {
    type: actionType.TRANSACTION_LOG_REQUESTED,
    payload: payload
  };
};
export const resetTransactionLog = () => {
  return {
    type: actionType.RESET_TRANSACTION_LOG
  };
};

export const getContractList = (payload) => {
  return {
    type: actionType.CONTRACT_LIST_REQUESTED,
    payload: payload
  };
};
export const contractDocumentDownload = (payload) => {
  return {
    type: actionType.CONTRACT_DOCUMENT_DOWNLOAD_REQUESTED,
    payload: payload
  };
};
export const resetContractDocumentDownload = () => {
  return {
    type: actionType.RESET_CONTRACT_DOCUMENT_DOWNLOAD
  };
};

//CaseList
export const getCaseList = (payload) => {
  return {
    type: actionType.CASE_LIST_REQUESTED,
    payload: payload
  };
};

export const getProviderReimbursement = (payload) => {
  return {
    type: actionType.PROVIDEREIMBURSEMENT_LIST_REQUESTED,
    payload: payload
  };
};
export const addAproovedReimbursement = (payload) => {
  return {
    type: actionType.ADD_APPROVED_REQUESTED,
    payload: payload
  };
};

export const addNewProviderReimbursement = (payload) => {
  return {
    type: actionType.ADD_NEW_PROVIDEREIMBURSEMENT_REQUESTED,
    payload: payload
  };
};
export const editProviderReimbursement = (payload) => {
  return {
    type: actionType.EDIT_PROVIDEREIMBURSEMENT_REQUESTED,
    payload: payload
  };
};
export const resetProviderReimbursementList = () => {
  return {
    type: actionType.RESET_PROVIDEREIMBURSEMENT_LIST
  };
};

export const resetReimbursementDetailsList = () => {
  return {
    type: actionType.RESET_REIMBURSEMENTDETAILS_LIST
  };
};

export const getAddress = (payload) => {
  return {
    type: actionType.ADDRESS_LIST_REQUESTED,
    payload: payload
  };
};
export const getMedcaseStatus = (payload) => {
  return {
    type: actionType.MEDCASE_STATUS_REQUESTED,
    payload: payload
  };
};

export const addNewAddress = (payload) => {
  return {
    type: actionType.ADD_NEW_ADDRESS_REQUESTED,
    payload: payload
  };
};
export const editAddress = (payload) => {
  return {
    type: actionType.EDIT_ADDRESS_REQUESTED,
    payload: payload
  };
};
export const getMedcaseHeader = (payload) => {
  return {
    type: actionType.MEDCASE_HEADER_REQUESTED,
    payload: payload
  };
};

export const deleteAddress = (payload) => {
  return {
    type: actionType.DELETE_ADDRESS_REQUESTED,
    payload: payload
  };
};
export const resetAddressList = () => {
  return {
    type: actionType.RESET_ADDRESS_LIST
  };
};

export const getPhone = (payload) => {
  return {
    type: actionType.PHONE_LIST_REQUESTED,
    payload: payload
  };
};
export const updateMedcaseStatusDdl = (payload) => {
  return {
    type: actionType.MEDCASE_DDL_STATUS_REQUESTED,
    payload: payload
  };
};

// delete status
export const deleteMedcaseStatus = (payload) => {
  return {
    type: actionType.DELETE_STATUS_REQUESTED,
    payload: payload
  };
};

export const addNewPhone = (payload) => {
  return {
    type: actionType.ADD_NEW_PHONE_REQUESTED,
    payload: payload
  };
};
export const editPhone = (payload) => {
  return {
    type: actionType.EDIT_PHONE_REQUESTED,
    payload: payload
  };
};
export const editpatientPhone = (payload) => {
  return {
    type: actionType.EDIT_PHONE_REQUESTED,
    payload: payload
  };
};
export const getPatientDetailsHeader = (payload) => {
  return {
    type: actionType.PATIENT_DETAILS_HEADER_REQUESTED,
    payload: payload
  };
};

export const deletePhone = (payload) => {
  return {
    type: actionType.DELETE_PHONE_REQUESTED,
    payload: payload
  };
};
export const resetPhoneList = () => {
  return {
    type: actionType.RESET_PHONE_LIST
  };
};

export const getEmail = (payload) => {
  return {
    type: actionType.EMAIL_LIST_REQUESTED,
    payload: payload
  };
};
export const getMedcaseDetailsHeader = (payload) => {
  return {
    type: actionType.MEDCASE_DETAILS_HEADER_REQUESTED,
    payload: payload
  };
};

export const addNewEmail = (payload) => {
  return {
    type: actionType.ADD_NEW_EMAIL_REQUESTED,
    payload: payload
  };
};
export const editEmail = (payload) => {
  return {
    type: actionType.EDIT_EMAIL_REQUESTED,
    payload: payload
  };
};
export const getAddFees = (payload) => {
  return {
    type: actionType.ADDFEES_LIST_REQUESTED,
    payload: payload
  };
};

//GeneticTesting

export const getGeneticTesting = (payload) => {
  return {
    type: actionType.GENETICTESTING_LIST_REQUESTED,
    payload: payload
  };
};

export const addNewGeneticTesting = (payload) => {
  return {
    type: actionType.ADD_NEW_GENETICTESTING_REQUESTED,
    payload: payload
  };
};
export const editGeneticTesting = (payload) => {
  return {
    type: actionType.EDIT_GENETICTESTING_REQUESTED,
    payload: payload
  };
};
export const resetGeneticTestingList = () => {
  return {
    type: actionType.RESET_GENETICTESTING_LIST
  };
};

// Genetic Testing PGTA Summary
export const getPgtaSummary = (payload) => {
  return {
    type: actionType.PGTA_SUMMARY_ANALYSIS_REQUESTED,
    payload: payload
  };
};

//Lab Payout
export const saveLabPayout = (payload) => {
  return {
    type: actionType.ADD_LAB_PAYOUT_REQUESTED,
    payload: payload
  };
};
export const resetLabPayout = () => {
  return {
    type: actionType.RESET_LAB_PAYOUT
  };
};

//Manage Lab
export const getManageLab = () => {
  return {
    type: actionType.GET_MANAGE_LAB_REQUESTED
  };
};
export const editManageLab = (payload) => {
  return {
    type: actionType.EDIT_MANAGE_LAB_REQUESTED,
    payload: payload
  };
};
export const addNewManageLab = (payload) => {
  return {
    type: actionType.ADD_MANAGE_LAB_REQUESTED,
    payload: payload
  };
};
export const resetManageLab = () => {
  return {
    type: actionType.RESET_MANAGE_LAB
  };
};

export const deleteEmail = (payload) => {
  return {
    type: actionType.DELETE_EMAIL_REQUESTED,
    payload: payload
  };
};
export const resetEmailList = () => {
  return {
    type: actionType.RESET_EMAIL_LIST
  };
};

export const getParticiPant = (payload) => {
  return {
    type: actionType.PARTICIPANT_LIST_REQUESTED,
    payload: payload
  };
};

export const editParticipant = (payload) => {
  return {
    type: actionType.EDIT_PARTICIPANT_REQUESTED,
    payload: payload
  };
};
export const resetParticipantList = () => {
  return {
    type: actionType.RESET_PARTICIPANT_LIST
  };
};
export const updatePersonNumber = (payload) => {
  return {
    type: actionType.PARTICIPANT_LIST_UPDATE_REQUESTED,
    payload: payload
  };
};

export const getPartner = (payload) => {
  return {
    type: actionType.PARTNER_LIST_REQUESTED,
    payload: payload
  };
};
export const addPartner = (payload) => {
  return {
    type: actionType.ADD_NEW_PARTNER_REQUESTED,
    payload: payload
  };
};
export const editPartner = (payload) => {
  return {
    type: actionType.EDIT_PARTNER_REQUESTED,
    payload: payload
  };
};
export const resetPartnerList = () => {
  return {
    type: actionType.RESET_PARTNER_LIST
  };
};
export const addNewAddFees = (payload) => {
  return {
    type: actionType.ADD_NEW_ADDFEES_REQUESTED,
    payload: payload
  };
};
export const editAddFess = (payload) => {
  return {
    type: actionType.EDIT_ADDFEES_REQUESTED,
    payload: payload
  };
};
export const resetAddFeesList = () => {
  return {
    type: actionType.RESET_ADDFEES_LIST
  };
};

export const deleteTransaction = (payload) => {
  return {
    type: actionType.DELETE_TRANSACTION_REQUESTED,
    payload: payload
  };
};
export const editTransaction = (payload) => {
  return {
    type: actionType.EDIT_TRANSACTION_REQUESTED,
    payload: payload
  };
};
export const getProgramName = (payload) => {
  return {
    type: actionType.PROGRAM_NAME_REQUESTED,
    payload: payload
  };
};

export const getFinancialSnapshotPayment = (payload) => {
  return {
    type: actionType.FINANCIAL_SNAPSHOT_PAYMENT_REQUESTED,
    payload: payload
  };
};

export const addCreditCardPayment = (payload) => {
  return {
    type: actionType.CREATE_CREDIT_CARD_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const addCheckPayment = (payload) => {
  return {
    type: actionType.CREATE_CHECK_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const addLoanPayment = (payload) => {
  return {
    type: actionType.CREATE_LOAN_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const addWiredPayment = (payload) => {
  return {
    type: actionType.CREATE_WIRE_TRANSFER_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const resetPatientPayment = () => {
  return {
    type: actionType.RESET_PATIENT_PAYMENT
  };
};
export const resetGetPatientPayment = () => {
  return {
    type: actionType.RESET_GET_PATIENT_PAYMENT
  };
};
export const getPatientPaymentDetails = (payload) => {
  return {
    type: actionType.GET_PATIENT_PAYMENT_REQUESTED,
    payload: payload
  };
};

export const resetProgram = () => {
  return {
    type: actionType.RESET_PROGRAM
  };
};

export const editCreditCardPayment = (payload) => {
  return {
    type: actionType.EDIT_CREDIT_CARD_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const editCheckPayment = (payload) => {
  return {
    type: actionType.EDIT_CHECK_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const editLoanPayment = (payload) => {
  return {
    type: actionType.EDIT_LOAN_PAYMENT_REQUESTED,
    payload: payload
  };
};
export const editWiredPayment = (payload) => {
  return {
    type: actionType.EDIT_WIRE_TRANSFER_PAYMENT_REQUESTED,
    payload: payload
  };
};

export const setManualRefundList = (payload) => {
  return {
    type: actionType.MANUAL_REFUND_REQUESTED,
    payload: payload
  };
};

export const resetManualRefundMessage = () => {
  return {
    type: actionType.MANUAL_REFUND_RESET
  };
};

export const createMedcaseManually = (payload) => {
  return {
    type: actionType.CREATE_MEDCASE_MANUAL_REQUESTED,
    payload: payload
  };
};
export const resetMedcase = () => {
  return {
    type: actionType.RESET_MEDCASE,
    success: false,
    error: false
  };
};

//Trak => Refund
export const getRefundFilterList = (payload) => {
  return {
    type: actionType.REFUND_FILTER_LIST_REQUESTED,
    payload: payload
  };
};
export const resetRefundFilterList = () => {
  return {
    type: actionType.RESET_REFUND_FILTER_LIST
  };
};

export const getRefundApplicationList = (payload) => {
  return {
    type: actionType.REFUND_APPLICATION_LIST_REQUESTED,
    payload: payload
  };
};

export const resetRefundApplicationList = () => {
  return {
    type: actionType.RESET_REFUND_APPLICATION_LIST
  };
};

export const getProgramCycle = (payload) => {
  return {
    type: actionType.PROGRAM_CYCLE_REQUESTED,
    payload: payload
  };
};

export const getPricingModalData = (payload) => {
  return {
    type: actionType.PRICING_MODAL_DATA_REQUESTED,
    payload: payload
  };
};
export const getHistoryPricingModalData = (payload) => {
  return {
    type: actionType.HISTORY_PRICING_MODAL_DATA_REQUESTED,
    payload: payload
  };
};
//trak->financialSnapshotPGTA
export const getFinancialSnapshotPgta = (payload) => {
  return {
    type: actionType.FINANCIAL_SNAPSHOT_PGTA_REQUESTED,
    payload: payload
  };
};

//getClinicalTreatmentOutcomes
export const getClinicalTreatmentOutcomes = (payload) => {
  return {
    type: actionType.CLINICAL_TREATMENT_OUTCOMES_REQUESTED,
    payload: payload
  };
};
export const editTreatmentPlanOutComeList = (payload) => {
  return {
    type: actionType.EDIT_CLINICAL_TREATMENT_OUTCOMES_REQUESTED,
    payload: payload
  };
};
export const resetOutcomeList = () => {
  return {
    type: actionType.RESET_OUTCOMELIST
  };
};
export const resetRes = () => {
  return {
    type: actionType.RESET_RES
  };
};

export const createProgramCycle = (payload) => {
  return {
    type: actionType.NEW_PR_REQUESTED,
    payload: payload
  };
};
export const newPrPost = (payload) => {
  return {
    type: actionType.NEW_PR_POST_REQUESTED,
    payload: payload
  };
};

export const runPR = () => {
  return {
    type: actionType.RUN_PR_REQUESTED
  };
};

export const editPR = (payload) => {
  return {
    type: actionType.EDIT_PR_REQUESTED,
    payload: payload
  };
};

//Trak => Revenue
export const getRevenueFilter = (payload) => {
  return {
    type: actionType.REVENUE_FILTER_LIST_REQUESTED,
    payload: payload
  };
};
export const resetRevenueFilter = () => {
  return {
    type: actionType.RESET_REVENUE_FILTER_LIST
  };
};

export const getRevenue = (payload) => {
  return {
    type: actionType.REVENUE_LIST_REQUESTED,
    payload: payload
  };
};

export const postRevenue = (payload) => {
  return {
    type: actionType.POST_REVENUE_REQUESTED,
    payload: payload
  };
};

export const resetRevenue = () => {
  return {
    type: actionType.RESET_REVENUE
  };
};

export const runRevenue = () => {
  return {
    type: actionType.RUN_REVENUE_REQUESTED
  };
};

export const resetRunRevenue = () => {
  return {
    type: actionType.RESET_RUN_REVENUE
  };
};

export const createLinkMedcase = (payload) => {
  return {
    type: actionType.CREATE_LINK_MEDCASE_REQUESTED,
    payload: payload
  };
};

////Email Document After Enroll or Outcome form send authorization
export const getEmailOption = (payload) => {
  return {
    type: actionType.GET_EMAIL_OPTION_REQUESTED,
    payload: payload
  };
};

export const postEmailOption = (payload) => {
  return {
    type: actionType.POST_EMAIL_OPTION_REQUESTED,
    payload: payload
  };
};
export const resetPostEmailOption = () => {
  return {
    type: actionType.RESET_POST_EMAIL_OPTION
  };
};

export const linkMedcase = (payload) => {
  return {
    type: actionType.LINK_MEDCASE_REQUESTED,
    payload: payload
  };
};

export const unlinkMedcase = (payload) => {
  return {
    type: actionType.UNLINK_MEDCASE_REQUESTED,
    payload: payload
  };
};

export const resetLinkMedcase = () => {
  return {
    type: actionType.RESET_LINK_MEDCASE
  };
};

export const saveAuthorization = (payload) => {
  return {
    type: actionType.CREATE_SEND_AUTHORIZATION_REQUESTED,
    payload: payload
  };
};

export const resetMedCaseList = () => {
  return {
    type: actionType.RESET_MEDCASE_LIST
  };
};

export const getRevenueFin = (payload) => {
  return {
    type: actionType.GET_REVENUE_REQUESTED,
    payload
  };
};
export const savePartner = (payload) => {
  return {
    type: actionType.UPDATE_PARTNER_REQUESTED,
    payload
  };
};

export const getMedcaseRevenue605 = (payload) => {
  return {
    type: actionType.MEDCASE_REVENUE_605_REQUESTED,
    payload
  };
};
// get financial snapshot report
export const getFinSnapshotReport = (payload) => {
  return {
    type: actionType.GET_FINSNAPSHOT_REPORT_REQUESTED,
    payload: payload
  };
};

// get payment register report
export const getPaymentRegisterReport = (payload) => {
  return {
    type: actionType.GET_PAYMENTREGISTER_REPORT_REQUESTED,
    payload: payload
  };
};

// get enrollments report
export const getEnrollmentReport = (payload) => {
  return {
    type: actionType.GET_ENROLLMENT_REPORT_REQUESTED,
    payload: payload
  };
};
export const getFinSnapshot605All = (payload) => {
  return {
    type: actionType.FIN_SNAPSHOT_605_ALL_REQUESTED,
    payload: payload
    // selected patient for medcase
  };
};
export const selectedPatient = () => {
  return {
    type: actionType.SELECTED_PATIENT_FOR_MEDCASE
  };
};

export const editEnrollment = (payload) => {
  return {
    type: actionType.EDIT_ENROLL_REQUESTED,
    payload: payload
  };
};

export const getActiveParticipantReport = (payload) => {
  return {
    type: actionType.GET_ACTIVE_PARTICIPANTS_REPORT_REQUESTED,
    payload: payload
  };
};

export const getInactiveParticipantReport = (payload) => {
  return {
    type: actionType.GET_INACTIVE_PARTICIPANTS_REPORT_REQUESTED,
    payload: payload
  };
};

export const updateManualReview = (payload) => {
  return {
    type: actionType.UPDATE_MANUAL_REVIEW_REQUESTED,
    payload: payload
  };
};

export const updateClinicalReview = (payload) => {
  return {
    type: actionType.UPDATE_CLINICAL_REVIEW_REQUESTED,
    payload: payload
  };
};

export const resetManualReview = () => {
  return {
    type: actionType.RESET_MANUAL_REVIEW
  };
};

export const getRequestedCycleList = (payload) => {
  return {
    type: actionType.REQUESTED_CYCLE_REQUESTED,
    payload: payload
  };
};

export const getTrakFinacialEstimateList = (payload) => {
  return {
    type: actionType.TRAK_FINANCIAL_ESTIMATE_REQUESTED,
    payload: payload
  };
};

export const resetTrakFinancialEstimateList = () => {
  return {
    type: actionType.RESET_TRAK_FINANCIAL_ESTIMATE_LIST
  };
};

export const resetTrakFinancialEstimateMessage = () => {
  return {
    type: actionType.RESET_TRAK_FINANCIAL_ESTIMATE_MESSAGE
  };
};

//GET User role Account Rep
export const getUserRole = (payload) => {
  return {
    type: actionType.GET_USER_ROLE,
    payload
  };
};

export const getPersonalList = (payload) => {
  return {
    type: actionType.PERSONAL_DATA_REQUESTED,
    payload: payload
  };
};

// REVENUE VALIDATION
export const getRevenueValidation = (payload) => {
  return {
    type: actionType.GET_REVENUE_VALIDATION,
    payload: payload
  };
};

export const getTreatmentPlan = () => {
  return {
    type: actionType.GET_ALL_TREATMENT_PLAN
  };
};

export const revenueValidationResetMessage = () => {
  return {
    type: actionType.REVENUE_VALIDATION_RESET_MESSAGE
  };
};

// OUTCOME REPORT
export const getOutcomeReport = (payload) => {
  return {
    type: actionType.GET_OUTCOME_REPORT,
    payload: payload
  };
};

export const outcomeReportResetMessage = () => {
  return {
    type: actionType.OUTCOME_REPORT_RESET_MESSAGE
  };
};
export const getRefreshedStatusHistory = (payload) => {
  return {
    type: actionType.REFRESH_STATUS_HISTORY_REQUESTED,
    payload: payload
  };
};
export const resetRefreshedStatusCode = () => {
  return {
    type: actionType.RESET_REFRESH_STATUS_HISTORY
  };
};

// Patient Payments Edit Transaction Action

export const editTransactionPatientPayment = (payload) => {
  return {
    type: actionType.EDIT_PATIENT_PAYMENT_TRANSACTION,
    payload
  };
};
