import * as actionType from "../actions/ActionTypes";

const initialState = {
  trakFinancialEstimateList: [],
  loading: false,
  error: null,
  successMessage: "",
  errorMessage: "",
  userRole: []
};
console.log("initialState", initialState);
const trakFinancialEstimateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TRAK_FINANCIAL_ESTIMATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TRAK_FINANCIAL_ESTIMATE_SUCCESS: {
      return {
        ...state,
        trakFinancialEstimateList: action.payload,
        loading: false
      };
    }
    case actionType.ADD_FOLLOW_UP_DATE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_FOLLOW_UP_DATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorMessage: "",
        successMessage: action.successMessage
      };
    }
    case actionType.ADD_FOLLOW_UP_DATE_FAILED: {
      return {
        ...state,
        loading: false,
        successMessage: "",
        errorMessage: action.errorMessage
      };
    }
    case actionType.ADD_USER_ROLE: {
      return {
        ...state,
        loading: false,
        userRole: action.payload
      };
    }
    case actionType.TRAK_FINANCIAL_ESTIMATE_FAILURE: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_TRAK_FINANCIAL_ESTIMATE_LIST: {
      return {
        ...state,
        trakFinancialEstimateList: [],
        successMessage: "",
        errorMessage: ""
      };
    }
    case actionType.RESET_TRAK_FINANCIAL_ESTIMATE_MESSAGE: {
      return {
        ...state,
        successMessage: "",
        errorMessage: ""
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default trakFinancialEstimateReducer;
