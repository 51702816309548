import {
  Autocomplete,
  Box,
  Grid,
  // MenuItem, Select,
  TextField,
  Typography
} from "@mui/material";
import QuickAdd from "../../shared/components/QuickAdd";
import { Link } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getPractices } from "../../admin/actions";
import * as actionType from "../actions";
import { refundApplicationConstants } from "./../../shared/constants/constants";
import moment from "moment/moment";
import { FapRowData } from "../../workspace/actions";
import { resetEstimateCards } from "../../workspace/actions";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const RefundApplication = (props) => {
  const defaultValue = {
    patientNameOrId: "",
    refundApplicationStatus: "All",
    practiceId: "",
    daysSinceCreation: "90"
  };
  const { actions, refundApplicationList = [], loading, practices = [] } = props;
  const dispatch = useDispatch();
  const columns = [
    {
      field: "personKey",
      headerName: "PERSON NO",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.personKey}</Typography>;
      }
    },
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{`${row.patientFirstName + " " + row.patientLastName}`}</Typography>;
      }
    },
    {
      field: "refundApplication",
      headerName: "REFUND APPLICATION",
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          to={row.patienId}
          onClick={() => {
            localStorage.setItem("patientName", row.patientFirstName + " " + row.patientLastName);
            dispatch(
              FapRowData({
                estimateType: row.estimateType,
                estimateStatus: row.estimateStatus,
                estimateDescription: row.estimateDescr,
                estimateId: row.estimateId,
                estimateTpId: row.finEstimateTpId
              })
            );
            dispatch(resetEstimateCards());

            actions.resetRefundApplicationList();
          }}>
          <ArticleIcon />
        </Link>
      )
    },
    {
      field: "refundApplicationStatus",
      headerName: "REFUND APPLICATION STATUS",
      flex: 1
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.practiceName}</Typography>;
      }
    },
    {
      field: "createdBy",
      headerName: "INITIATE BY",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.createdBy}</Typography>;
      }
    },
    {
      field: "createdDate",
      headerName: "INITIATE DATE",
      type: "date",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.createdDate ? moment(new Date(row.createdDate)).format("L") : "--"}
          </Typography>
        );
      }
    },
    {
      field: "submittedBy",
      headerName: "SUBMITTED BY",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.submittedBy}</Typography>;
      }
    },
    {
      field: "submittedDate",
      headerName: "SUBMITTED DATE",
      type: "date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.submittedDate ? moment(new Date(row.submittedDate)).format("L") : "--"}</>;
      }
    },
    {
      field: "refundApplicationNotes",
      headerName: "NOTES",
      flex: 1.2,
      renderCell: ({ row }) => {
        return <Typography className="word-wrap">{row.refundApplicationNotes}</Typography>;
      }
    }
  ];
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState(defaultValue);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (refundApplicationList.length === 0) {
      getList();
    }
  }, [values]);

  useEffect(() => {
    if (practices.length === 0) {
      actions.getPractices();
    }
  }, []);

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      createdDate: row?.createdDate ? new Date(row?.createdDate) : "",
      submittedDate: row?.submittedDate ? new Date(row?.submittedDate) : ""
    }));
  };

  useEffect(() => {
    if (refundApplicationList.length) {
      let rows = refundApplicationList.map((item, index) => {
        item.id = index;
        item.patientName = `${item.patientLastName} ${item.patientFirstName}`;
        return item;
      });
      setRows(rows);
      const csvDataMode = prepareCsvData(rows);
      console.log("csvDataMode: ", csvDataMode);
      setCsvData(csvDataMode);
    } else {
      setRows([]);
    }
  }, [loading]);

  const getList = () => {
    actions.getRefundApplicationList({ values });
  };
  const handleInputChange = (value) => {
    if (value) {
      actions.resetRefundApplicationList();
      setValues({
        ...values,
        [value.name]: value.value
      });
    }
  };

  const handleSearchClick = (e, searchText) => {
    actions.resetRefundApplicationList();
    values.patientNameOrId = searchText;
    setValues({
      ...values
    });
  };

  const clearSearchClick = () => {
    values.patientNameOrId = "";
    setValues({ ...values });
  };

  return (
    <Box className="refund-application">
      <Box className="teal-bg-color pad-5">
        <QuickAdd
          text={1}
          search={7}
          handleSearchClick={handleSearchClick}
          clearSearchClick={clearSearchClick}
        />
        <Grid container className="align-center pad-5">
          <Grid item xs={1}>
            <Typography>{refundApplicationConstants.status.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              name="refundApplicationStatus"
              fullWidth
              value={values?.refundApplicationStatus}
              onChange={handleInputChange}>
              {refundApplicationConstants.status.values.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              disablePortal
              fullWidth
              name="refundApplicationStatus"
              value={values?.refundApplicationStatus}
              options={refundApplicationConstants.status.values.map((item, index) => {
                return {
                  id: index,
                  label: item,
                  name: "refundApplicationStatus",
                  value: item
                };
              })}
              onChange={(e, value) => {
                handleInputChange(value);
              }}
              renderInput={(params) => <TextField {...params} label="Refund App Status..." />}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography ml={1}>Practice</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              name="practiceId"
              fullWidth
              value={values.practiceId}
              onChange={handleInputChange}>
              {practices &&
                practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} - {item.practiceId}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <Autocomplete
              disablePortal
              fullWidth
              name="practiceId"
              value={
                practices.find((item) => item.practiceId === values.practiceId)
                  ? `${practices.find((item) => item.practiceId === values.practiceId).name} - ${
                      values.practiceId
                    }`
                  : ""
              }
              options={practices.map((item, index) => {
                return {
                  id: index,
                  label: `${item.name} - ${item.practiceId}`,
                  name: "practiceId",
                  value: item.practiceId
                };
              })}
              onChange={(e, value) => {
                handleInputChange(value);
              }}
              renderInput={(params) => <TextField {...params} label="Practice..." />}
            />
          </Grid>
        </Grid>
        <Grid container className="align-center pad-5">
          <Grid item xs={1}>
            <Typography>{refundApplicationConstants.daysSinceCreation.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              name="daysSinceCreation"
              value={values?.daysSinceCreation}
              fullWidth
              onChange={handleInputChange}>
              {refundApplicationConstants.daysSinceCreation.values.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {`${item} days`}
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              disablePortal
              fullWidth
              name="daysSinceCreation"
              value={`${values?.daysSinceCreation} days`}
              options={refundApplicationConstants.daysSinceCreation.values.map((item, index) => {
                return {
                  id: index,
                  label: `${item} days`,
                  name: "daysSinceCreation",
                  value: item
                };
              })}
              onChange={(e, value) => {
                handleInputChange(value);
              }}
              renderInput={(params) => <TextField {...params} label="Days Since Creation..." />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <Typography>{rows.length} person matching your criteria</Typography>
        <DataGridPremiumComp
          loading={loading}
          rows={csvData}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          className="mt-8"
          pagination
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ practices, refundApplicationTrakReducer }) => {
  return {
    practices: practices.practices,
    refundApplicationList: refundApplicationTrakReducer.refundApplicationList,
    loading: refundApplicationTrakReducer.loading,
    error: refundApplicationTrakReducer.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(getPractices());
    },
    getRefundApplicationList: (payload) => {
      dispatch(actionType.getRefundApplicationList(payload));
    },
    resetRefundApplicationList: () => {
      dispatch(actionType.resetRefundApplicationList());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundApplication);
