import { Document, Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { fapViewProgram, programBoxName } from "../../shared/constants/constants";

Font.register({
  family: "Roboto",
  src: "/Assets/fonts/Roboto-Light.ttf"
});

Font.register({
  family: "RobotoBold",
  src: "/Assets/fonts/Roboto-Bold.ttf"
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    paddingHorizontal: "30px",
    paddingVertical: "50px",
    fontFamily: "Roboto"
  },
  under_line: {
    marginVertical: 7,
    backgroundColor: "#000",
    height: "1px",
    width: "100%"
  },
  text_regular: {
    fontSize: "9px",
    fontWeight: 400
  },
  text_paragraph: {
    fontSize: "9px",
    fontWeight: 400,
    textAlign: "justify",
    marginVertical: "8px"
  },
  text_list: {
    fontSize: "9px",
    fontWeight: 400,
    textAlign: "justify",
    marginVertical: "5px",
    margingLeft: "10px"
  },
  text_regular_bold: {
    fontSize: "9px",
    fontFamily: "RobotoBold"
  },
  text_h1: {
    fontSize: "14px",
    fontFamily: "RobotoBold"
  },
  text_h2: {
    fontSize: "12px",
    fontFamily: "RobotoBold"
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  col_20: {
    width: "20%"
  },
  col_30: {
    width: "30%"
  },

  col_50: {
    width: "50%"
  },
  col_70: {
    width: "70%"
  },
  col_60: {
    width: "60%"
  },
  col_40: {
    width: "40%"
  },
  col_80: {
    width: "80%"
  },
  col_100: {
    width: "100%"
  },
  logo: {
    width: "100px",
    marginLeft: 5,
    height: "45px"
  },
  footer: {
    position: "absolute",
    bottom: 24,
    fontSize: 7,
    left: 10,
    right: 10,
    marginLeft: 15,
    marginRight: 15
  },
  footer_date: {
    position: "absolute",
    bottom: 10,
    fontSize: 7,
    left: 10,
    marginLeft: 15
  },
  footer_page_number: {
    position: "absolute",
    bottom: 10,
    fontSize: 7,
    right: 10,
    marginRight: 15
  },
  fap_header: {
    backgroundColor: "#ECF7F7",
    width: "100%"
  },
  fap_logo: {
    marginVertical: 20,
    width: "150px",
    alignSelf: "center"
  },
  fav_devider: {
    width: "45%",
    height: 3,
    backgroundColor: "#FFF",
    marginBottom: 20,
    alignSelf: "center"
  },
  group_logo: {
    marginVertical: 30,
    width: "150px",
    alignSelf: "center"
  },
  program_card: {
    width: "33%",
    padding: 5,
    border: "2px solid #26BEC9",
    marginTop: 0,
    marginLeft: 3,
    marginRight: 3
  },
  program_card_container: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "0px"
    // paddingBottom: "0px !important"
  },
  program_card_new: {
    width: "32%",
    padding: 5,
    marginTop: 0,
    marginLeft: 3,
    marginRight: 3,
    backgroundColor: "#1BA3AD",
    fontSize: 8,
    fontFamily: "RobotoBold",
    color: "#FFF"
  },
  program_card_new_text: {
    alignSelf: "center",
    color: "#FFF",
    fontSize: "11px"
  }
});

const renderGrossPrice = (serviceItems, index) => {
  if (serviceItems.length > 1) {
    return (
      <Text style={styles.text_regular} key={index}>
        ${}
        {serviceItems
          .sort((first, second) => {
            return first.grossPrice > second.grossPrice ? 1 : -1;
          })
          .map((item) => item)[0]
          .grossPrice.toLocaleString()}
        {" - "}$
        {serviceItems
          .sort((first, second) => {
            return first.grossPrice > second.grossPrice ? 1 : -1;
          })
          .map((item) => item)
          [serviceItems.length - 1].grossPrice.toLocaleString()}
      </Text>
    );
  } else {
    return (
      <Text style={styles.text_regular} key={index}>
        ${}
        {serviceItems
          .sort((first, second) => {
            return first.grossPrice > second.grossPrice ? 1 : -1;
          })
          .map((item) => item)[0]
          .grossPrice.toLocaleString()}
      </Text>
    );
  }
};

const abbreviateNumber = (value) => {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "K", "m", "b", "t"];
    var suffixNum = Math.floor(Math.log10(value) / 3);
    var shortValue = newValue / Math.pow(10, 3 * suffixNum);
    shortValue = shortValue.toFixed(1);
    return shortValue + suffixes[suffixNum];
  }
  return value;
};

const getProgramsPrice = (item) => {
  let programmFees =
    item &&
    item.programs &&
    item.programs[0] &&
    item.programs[0].programPrice &&
    item.programs[0].programPrice;

  let rangeFees =
    item &&
    item.programs &&
    item.programs[item.programs.length - 1] &&
    item.programs[item.programs.length - 1].programPrice &&
    item.programs[item.programs.length - 1].programPrice;
  return item.programs.length > 1 && item.boxName != programBoxName.PAY_PER_CYCLE
    ? "$" + abbreviateNumber(programmFees) + " - " + "$" + abbreviateNumber(rangeFees)
    : "$" + programmFees.toLocaleString("en");
};

const FinancialEstimatePDF = (props) => {
  const {
    notes,
    //card,
    // stages,
    // necessary,
    // optional,
    // adjustments,
    // fapList,
    estimateCards,
    estimates,
    estimatePdfData
  } = props;
  const patientName = localStorage.getItem("patientName");

  // Function to convert SVG Base64 to PNG Base64
  function convertSvgBase64ToPngBase64(base64Svg, width = 500, height = 500) {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const pngBase64 = canvas.toDataURL("image/png");
          resolve(pngBase64);
        } catch (error) {
          reject(`Error drawing SVG to canvas: ${error.message}`);
        }
      };
      img.onerror = () => reject(new Error("Failed to load SVG image."));
      const svgPrefix = "data:image/svg+xml;base64,";
      img.src = svgPrefix + base64Svg;
    });
  }
  const [convertedLogo, setConvertedLogo] = useState(null);
  console.log("==>", convertedLogo);
  useEffect(() => {
    if (estimates?.practiceLogo) {
      convertSvgBase64ToPngBase64(estimates.practiceLogo)
        .then((pngBase64) => setConvertedLogo(pngBase64))
        .catch((error) => console.error("Error converting SVG to PNG:", error));
    }
  }, [estimates]);

  const getEstimateCardDetsil = (item) => {
    let filterDataObj = [];

    estimateCards &&
      estimateCards.length > 0 &&
      estimateCards.filter((eCard) => {
        if (eCard.finEstimateTpId === item.estimateTpId) {
          filterDataObj.push(eCard);
          return eCard;
        } else if (
          eCard.linkedEstimateTreatmentPlan !== null &&
          eCard.linkedEstimateTreatmentPlan.finEstimateTpId === item.estimateTpId
        ) {
          filterDataObj.push(eCard.linkedEstimateTreatmentPlan);
          return eCard.linkedEstimateTreatmentPlan;
        } else {
          return null;
        }
      });

    let finalObj = (filterDataObj && filterDataObj[0]) || {};
    return finalObj;
  };

  // const getEstimateDetail = (item) => {
  //   const filterData = estimateCards.filter((eCard) => {
  //     console.log("zhr eCard details:", eCard);
  //     console.log("zhr eCard item:", item);
  //     return eCard.estimateId === item.estimateId;
  //   });

  //   console.log("filterData:", filterData);

  //   let finalObj = (filterData && filterData[0]) || {};
  //   console.log("zhr finalObj2:", finalObj);
  //   return finalObj;
  // };
  const formatPhoneNumber = (phoneNo) => {
    if (!phoneNo) return null;
    const mobileNumberFrags = phoneNo.split("-");
    return `(${mobileNumberFrags[0]}) ${mobileNumberFrags[1]}-${mobileNumberFrags[2]}`;
  };

  return (
    <Document>
      {estimatePdfData &&
        estimatePdfData.length > 0 &&
        estimatePdfData.map((item, index) => {
          let stages = item && item.treatmentPlanStageList;
          let necessary = item && item.treatmentPlanStageServiceItemsNecessary;
          let optional = item && item.treatmentPlanStageServiceItemsOptional;
          // let notes = "";
          let adjustments = item && item.adjustmentsList;
          let fapList = item && item.fertilityAccessProgram;

          let card = getEstimateCardDetsil(item);
          // let estDetails = getEstimateDetail(item);
          // console.log("zhr estDetails", estDetails);

          let totalEstimate = 0;
          let totalEstimateMax = 0;

          let tpPrice =
            /* card?.tpLinkId ? card.linkedEstimateTreatmentPlan.tpPrice : */ card.tpPrice;

          if (necessary && necessary.length) {
            let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
              if (!tpStage.range) {
                return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
              }
            });
            let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
              if (tpStage.range) {
                return serviceItems.map((item) => item.grossPrice)[0];
              }
            });

            let rangeMaxPrice = necessary.map(({ tpStage, serviceItems }) => {
              if (tpStage.range) {
                return serviceItems.map((item) => item.grossPrice)[serviceItems.length - 1];
              }
            });
            let minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);
            let maxPrice = rangeMaxPrice.filter((item) => item).reduce((a, b) => a + b, 0);

            if (maxPrice < minPrice) {
              let temp = minPrice;
              minPrice = maxPrice;
              maxPrice = temp;
            }

            totalEstimate =
              tpPrice + minPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0);
            totalEstimateMax =
              tpPrice + maxPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0);
          } else {
            totalEstimate = tpPrice;
            totalEstimateMax = tpPrice;
          }

          // if (totalEstimateMax < totalEstimate) {
          //   let temp = totalEstimate;
          //   totalEstimate = totalEstimateMax;
          //   totalEstimateMax = temp;
          // }

          return (
            <React.Fragment key={"outer" + index}>
              <Page size="A4" style={styles.page}>
                {index === 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={styles.col_50}>
                        {/* <Image style={styles.logo} src={convertedLogo || "/Assets/logo.png"} /> */}
                        <Image
                          src={`data:image/png;base64,${estimates?.practicePdfLogo}`}
                          alt="Practice Logo"
                          //height={45}
                          style={styles.logo}
                        />
                      </View>
                      <View style={styles.col_50}>
                        <Text style={[styles.text_regular, { textAlign: "right" }]}>
                          {estimates?.practiceLongName
                            ? estimates?.practiceLongName
                            : "IVF Florida Reproductive Associates"}
                        </Text>
                        {/* <Text style={[styles.text_regular, { textAlign: "right" }]}>
                          3251 N St Rd 7
                        </Text>
                        <Text style={[styles.text_regular, { textAlign: "right" }]}>
                          MARGATE, FL 33063
                        </Text> */}
                        <Text style={[styles.text_regular, { textAlign: "right" }]}>
                          {estimates?.practicePhoneNo
                            ? formatPhoneNumber(estimates?.practicePhoneNo)
                            : "(866) 770-2168"}
                        </Text>
                      </View>
                    </View>
                    {/* <View style={styles.under_line} /> */}
                    <Text style={[styles.text_h2, { marginTop: "20px", fontFamily: "Roboto" }]}>
                      {/* {estimates.estimateType == "D" ? "Detail" : "Preliminary"} Estimate for{" "} */}
                      {card?.estimateType == "D" ? "Detail" : "Preliminary"} Estimate for{" "}
                      {patientName}
                    </Text>
                  </>
                )}

                {notes ? (
                  <>
                    <View style={styles.under_line} />
                    <View style={styles.row}>
                      <View style={styles.col_30}>
                        <Text style={styles.text_h1}>Notes</Text>
                      </View>
                      <View style={styles.col_70}>
                        <Text style={styles.text_regular}>{notes}</Text>
                      </View>
                    </View>
                    <View style={[styles.under_line, { marginBottom: 15 }]} />
                  </>
                ) : (
                  <View style={styles.under_line} />
                )}

                <View style={styles.row}>
                  <View style={styles.col_30}>
                    {card?.treatmentType === "PGT-A Analysis" ? (
                      <>
                        <Text style={styles.text_h2}>
                          Cost of the {card?.treatmentType} Unlimited Program
                        </Text>
                        <br />
                        <Text
                          style={{ marginTop: "10px", fontSize: "12px", fontFamily: "RobotoBold" }}>
                          {card?.treatmentPlanName ? card?.treatmentPlanName : ""}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text style={styles.text_h1}>Cost for one {card?.treatmentType} cycle</Text>
                        <br />
                        <Text
                          style={{ marginTop: "10px", fontSize: "12px", fontFamily: "RobotoBold" }}>
                          {card?.treatmentPlanName ? card?.treatmentPlanName : ""}
                        </Text>
                      </>
                    )}
                  </View>
                  <View style={styles.col_70}>
                    <View style={styles.row}>
                      <View style={styles.col_60}>
                        <Text style={styles.text_h2}>Included</Text>
                      </View>
                      <View style={[styles.col_40, { textAlign: "right" }]}>
                        <Text style={styles.text_regular_bold}>
                          ${card && card.originalTpPrice && card.originalTpPrice.toLocaleString()}
                        </Text>
                      </View>
                    </View>

                    {stages && stages.length
                      ? stages.map((item, index) => {
                          return (
                            <View style={[styles.row, { marginVertical: "1px" }]} key={index}>
                              <View style={styles.col_60}>
                                <Text style={styles.text_regular}>{item.stageName}</Text>
                              </View>
                              <View style={styles.col_40}>
                                <Text style={styles.text_regular}></Text>
                              </View>
                            </View>
                          );
                        })
                      : ""}

                    {adjustments.length ? (
                      <>
                        <View style={styles.row}>
                          <View style={styles.col_60}>
                            <Text style={styles.text_h2}>Adjustments</Text>
                          </View>
                          <View style={[styles.col_40, { textAlign: "right" }]}></View>
                        </View>

                        {adjustments.map((item, index) => {
                          return (
                            <View key={index} style={styles.row}>
                              <View style={styles.col_60}>
                                <Text style={[styles.text_regular, { marginLeft: "5px" }]}>
                                  {item.type}
                                </Text>
                              </View>
                              <View style={[styles.col_40, { textAlign: "right" }]}>
                                <Text style={styles.text_regular}>
                                  {item.category === "G" || item.category === "LI"
                                    ? item.amountType == "A"
                                      ? ` - $${item && item.amount && item.amount.toLocaleString()}`
                                      : ` - $${
                                          item &&
                                          item.amount &&
                                          Math.round(
                                            (item.amount / 100) * card.originalTpPrice
                                          ).toLocaleString()
                                        }`
                                    : `+ $${item && item.amount && item.amount.toLocaleString()}`}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </>
                    ) : null}

                    <View style={styles.under_line} />

                    <View style={styles.row}>
                      <View style={styles.col_60}>
                        <Text style={styles.text_h2}>
                          {/* {card && card.treatmentType && card.treatmentType === "PGT-A Analysis"
                    ? "Total Cost of Program"
                    : "Amount Due to Practice"} */}
                          {card && card.treatmentType && card.treatmentType === "PGT-A Analysis"
                            ? "Total Cost of Program"
                            : card && card.treatmentType && card.treatmentType === "IVF"
                            ? "Cost per Cycle"
                            : "Amount Due to Practice"}
                        </Text>
                      </View>
                      <View style={[styles.col_40, { textAlign: "right" }]}>
                        <Text style={styles.text_regular_bold}>
                          {card && card.tpPrice && "$" + card.tpPrice.toLocaleString()}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.under_line} />

                    {/* <View style={styles.row}>
                      <View style={styles.col_60}>
                        <Text style={styles.text_h2}>Treatment Plan</Text>
                      </View>
                      <View style={[styles.col_40, { textAlign: "right" }]}>
                        <Text style={styles.text_regular}>
                          {card?.treatmentPlanName ? card?.treatmentPlanName : ""}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.under_line} /> */}

                    <View style={styles.row}>
                      <View style={styles.col_60}>
                        <Text style={styles.text_h2}>Not Included But Necessary</Text>
                      </View>
                      <View style={styles.col_40}>
                        <Text style={styles.text_regular}></Text>
                      </View>
                    </View>

                    {necessary && necessary.length
                      ? necessary.map(({ tpStage, serviceItems }, index) => {
                          if (tpStage.range) {
                            return (
                              <View key={index} style={styles.row}>
                                <View style={styles.col_60}>
                                  <Text style={[styles.text_regular /* , { marginLeft: "5px" } */]}>
                                    {tpStage.stageName}
                                  </Text>
                                </View>
                                <View style={[styles.col_40, { textAlign: "right" }]}>
                                  {renderGrossPrice(serviceItems, index)}
                                </View>
                              </View>
                            );
                          } else {
                            return (
                              <View key={index} py={0.5}>
                                <Text style={[styles.text_regular, { marginVertical: "5px" }]}>
                                  {tpStage.stageName}
                                </Text>
                                {serviceItems.map((services, i) => {
                                  return (
                                    <View style={styles.row} key={i}>
                                      <View style={styles.col_60}>
                                        <Text style={[styles.text_regular, { marginLeft: "5px" }]}>
                                          {services.serviceDesc}
                                        </Text>
                                      </View>
                                      <View style={[styles.col_40, { textAlign: "right" }]}>
                                        <Text style={styles.text_regular}>
                                          ${services.grossPrice.toLocaleString()}
                                        </Text>
                                      </View>
                                    </View>
                                  );
                                })}
                              </View>
                            );
                          }
                        })
                      : null}

                    <View style={styles.under_line} />

                    <View style={styles.row}>
                      <View style={styles.col_60}>
                        <Text style={styles.text_h2}>Total Cost</Text>
                      </View>
                      <View style={styles.col_40}>
                        <Text style={[styles.text_regular_bold, { textAlign: "right" }]}>
                          ${totalEstimate && totalEstimate.toLocaleString()}
                          {necessary.some((item) => item.tpStage.range === true) > 0
                            ? " - $" + totalEstimateMax.toLocaleString()
                            : ""}
                        </Text>
                      </View>
                    </View>

                    {card && card.depositeType === "Partial In Advance" ? (
                      <>
                        <View style={styles.under_line} />

                        <View style={styles.row}>
                          <View style={styles.col_60}>
                            <Text style={styles.text_h2}>Deposit Due to Begin Treatment</Text>
                            <Text style={{ fontSize: "7px" }}>{card.depositeComment ?? ""}</Text>
                          </View>
                          <View style={styles.col_40}>
                            <Text style={[styles.text_regular_bold, { textAlign: "right" }]}>
                              ${card.depositeAmount && card.depositeAmount.toLocaleString()}
                            </Text>
                          </View>
                        </View>
                      </>
                    ) : null}

                    <View style={styles.under_line} />

                    <View style={styles.row}>
                      <View style={styles.col_60}>
                        <Text style={styles.text_h2}>Not Included But May Become Necessary</Text>
                      </View>
                      <View style={styles.col_40}>
                        <Text style={styles.text_regular}></Text>
                      </View>
                    </View>

                    {optional && optional.length
                      ? optional.map(({ tpStage, serviceItems }, index) => {
                          if (tpStage.range) {
                            return (
                              <View key={index} style={styles.row}>
                                <View style={styles.col_60}>
                                  <Text style={[styles.text_regular /* , { marginLeft: "5px" } */]}>
                                    {tpStage.stageName}
                                  </Text>
                                </View>
                                <View style={[styles.col_40, { textAlign: "right" }]}>
                                  {renderGrossPrice(serviceItems, index)}
                                </View>
                              </View>
                            );
                          } else {
                            return (
                              <View key={index} py={0.5}>
                                <Text style={[styles.text_regular, { marginVertical: "5px" }]}>
                                  {tpStage.stageName}
                                </Text>
                                {serviceItems.map((services, i) => {
                                  return (
                                    <View style={styles.row} key={i}>
                                      <View style={styles.col_60}>
                                        <Text style={[styles.text_regular, { marginLeft: "5px" }]}>
                                          {services.serviceDesc}
                                        </Text>
                                      </View>
                                      <View style={[styles.col_40, { textAlign: "right" }]}>
                                        <Text style={styles.text_regular}>
                                          ${services.grossPrice.toLocaleString()}
                                        </Text>
                                      </View>
                                    </View>
                                  );
                                })}
                              </View>
                            );
                          }
                        })
                      : null}
                  </View>
                </View>

                <Text style={styles.footer} fixed>
                  Always refer to the detailed estimate published on your patient portal for the
                  most up to date information. Above pricing is specific to the treatment plan
                  identified. Should your treatment plan change, pricing may vary. Prices are not
                  guaranteed until your treatment plan is finalized.
                </Text>
                <Text style={styles.footer_date} fixed>
                  As of {moment().tz("America/New_York").format("MMMM D, YYYY [a]t hh:mm a z")}
                </Text>
                <Text
                  style={styles.footer_page_number}
                  render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
                  fixed></Text>
              </Page>
              {fapList &&
              fapList.programVisualization &&
              fapList.programVisualization.length > 0 &&
              card.treatmentType &&
              card.treatmentType.toString().toLowerCase().includes("ivf") ? (
                <Page size="A4" style={styles.page}>
                  <View style={styles.row}>
                    <View style={styles.fap_header}>
                      <Image style={styles.fap_logo} src="/Assets/fap-removebg.png"></Image>
                      <View style={styles.fav_devider}></View>
                      <Text
                        style={[
                          styles.text_regular,
                          { textAlign: "center", marginBottom: 20, fontSize: "9px" }
                        ]}>
                        We partner with Fertility Access to offer Multi-Cycle Discount and Refund
                        Programs
                      </Text>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <Text
                      style={[
                        styles.text_regular,
                        { textAlign: "center", marginVertical: 10, marginHorizontal: 18 }
                      ]}>
                      Even with state-of-the-art technology and the best treatment available, it
                      often takes women more than one IVF cycle to be successful. You may increase
                      your likelihood of having a baby when you commit to multiple cycles, so we
                      offer multi-cycle packages for a fixed, discounted fee that can make paying
                      for treatment more manageable.
                    </Text>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col_100}>
                      <Image style={styles.group_logo} src="/Assets/group-2.png"></Image>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col_30}></View>
                    <View style={styles.col_40}>
                      <View style={styles.row}>
                        <View style={styles.col_20}>
                          <Text
                            style={[
                              styles.text_regular,
                              { textAlign: "right", fontSize: "16px", color: "#111" }
                            ]}>
                            80%
                          </Text>
                        </View>
                        <View style={styles.col_80}>
                          <Text
                            style={[
                              styles.text_regular,
                              {
                                textAlign: "left",
                                fontSize: "7px",
                                paddingLeft: 4,
                                paddingTop: "1px"
                              }
                            ]}>
                            of patients who enroll in one of the Fertility Access Multi-Cycle
                            Programs bring home a baby*
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.col_30}></View>
                  </View>

                  <View style={[styles.program_card_container, { marginTop: 10 }]}>
                    {fapList?.programVisualization &&
                      fapList.programVisualization.map((item, inddex_n) => {
                        if (item.boxName == programBoxName.REFUND_PROGRAM) {
                          return (
                            <View style={styles.program_card_new} key={"pv_" + inddex_n}>
                              <Text style={styles.program_card_new_text}>
                                {"Ultimate peace of mind".toUpperCase()}
                              </Text>
                            </View>
                          );
                        }
                      })}
                  </View>

                  <View
                    style={[
                      styles.row,
                      {
                        justifyContent: "center",
                        paddingTop: "0px"
                        // paddingTop: "0px !important"
                      }
                    ]}>
                    {fapList?.programVisualization &&
                      fapList.programVisualization.map((item, index) => {
                        let isShow = "block";
                        let barHeight = 37;
                        if (item.boxName == programBoxName.PAY_PER_CYCLE) {
                          item.programs.sort((a, b) => {
                            return b.programPrice - a.programPrice;
                          });
                        }
                        return (
                          <React.Fragment key={"fap_" + index}>
                            <View
                              key={"fap_" + index}
                              style={[styles.program_card, { display: isShow }]}>
                              <View style={{ minHeight: 75 }}>
                                <Text
                                  style={{ fontSize: 8, marginHorizontal: 4, marginVertical: 5 }}>
                                  {item.boxName == programBoxName.NON_REFUND_PROGRAM
                                    ? "Multi-Cycle Discount Program"
                                    : item.boxName}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 9,
                                    fontFamily: "RobotoBold",
                                    marginHorizontal: 4,
                                    marginTop: 4
                                  }}>
                                  {item.boxName === programBoxName.PAY_PER_CYCLE
                                    ? getProgramsPrice(item) + " for 3 IVF + 3 FET cycles "
                                    : getProgramsPrice(item) + " for whole program"}
                                </Text>
                                {item.boxName == programBoxName.PAY_PER_CYCLE && (
                                  <Text
                                    style={{
                                      fontSize: 9,
                                      fontFamily: "RobotoBold",
                                      marginHorizontal: 4,
                                      marginTop: 2
                                    }}>
                                    {"$" +
                                      (fapList.discountedGlobalTpPrice &&
                                        fapList.discountedGlobalTpPrice.toLocaleString("en")) +
                                      " per IVF cycle + " +
                                      "$" +
                                      (fapList.globalPriceOfFet &&
                                        fapList.globalPriceOfFet.toLocaleString("en")) +
                                      " per FET cycle"}
                                  </Text>
                                )}

                                <Text
                                  style={{
                                    fontSize: 8,
                                    fontFamily: "RobotoBold",
                                    color: "gray",
                                    margin: 4
                                  }}>
                                  {fapViewProgram[item.boxName].programLabel}
                                </Text>
                              </View>

                              <View
                                style={{
                                  backgroundColor: "#818294",
                                  color: "#FFF",
                                  width: "100%",
                                  padding: 10
                                }}>
                                <Text style={{ fontSize: 8, fontFamily: "RobotoBold", margin: 3 }}>
                                  Program Summary
                                </Text>
                                <Text style={{ fontSize: 7, margin: 3, display: "list-item" }}>
                                  <Text style={{ fontSize: 9 }}>• </Text>{" "}
                                  {fapViewProgram[item.boxName].programSummary}
                                </Text>
                                <Text style={{ fontSize: 7, margin: 3, display: "list-item" }}>
                                  <Text style={{ fontSize: 9 }}>• </Text>{" "}
                                  {fapViewProgram[item.boxName].discountStr}
                                </Text>
                              </View>

                              <View
                                style={{
                                  minHeight: 125,
                                  backgroundColor: "#EFEFEF",
                                  color: "#FFF",
                                  justifyContent: "center"
                                }}>
                                <View
                                  style={{
                                    position: "absolute",
                                    bottom: 1,
                                    alignSelf: "center",
                                    flexDirection:
                                      item.boxName != programBoxName.PAY_PER_CYCLE
                                        ? "row"
                                        : undefined
                                  }}>
                                  {item.programs.map((barItem, barIndex) => {
                                    if (
                                      item.boxName != programBoxName.PAY_PER_CYCLE &&
                                      barIndex != 0
                                    ) {
                                      barHeight = barHeight + 20;
                                    }
                                    let pNames = [];

                                    if (
                                      barItem &&
                                      barItem.programName &&
                                      barItem.programName.toLowerCase() ==
                                        "multi-cycle discount program 2+"
                                    ) {
                                      pNames = ["2 IVF Cycles", "Unlimited FETs"];
                                    } else if (
                                      barItem &&
                                      barItem.programName &&
                                      barItem.programName.toLowerCase() ==
                                        "multi-cycle discount program 3+"
                                    ) {
                                      pNames = ["3 IVF Cycles", "Unlimited FETs"];
                                    } else if (
                                      barItem &&
                                      barItem.programName &&
                                      barItem.programName.toLowerCase() == "50% refund program 2+"
                                    ) {
                                      pNames = ["2 IVF Cycles", "Unlimited FETs", "50% Refund"];
                                    } else if (
                                      barItem &&
                                      barItem.programName &&
                                      barItem.programName.toLowerCase() == "100% refund program 3+"
                                    ) {
                                      pNames = ["3 IVF Cycles", "Unlimited FETs", "100% Refund"];
                                    }

                                    return (
                                      <View
                                        style={{
                                          backgroundColor: "#1BA3AD",
                                          padding: 3,
                                          margin: 1,
                                          height: barHeight,
                                          width: barItem.programName.length >= 32 ? 45 : 35,
                                          alignSelf:
                                            item.boxName != programBoxName.PAY_PER_CYCLE
                                              ? "flex-end"
                                              : undefined
                                        }}
                                        key={"p_" + barIndex}>
                                        <Text
                                          style={{
                                            fontSize: 7,
                                            marginTop: 1,
                                            marginHorizontal: 2,
                                            marginBottom: 3
                                          }}>
                                          {"$" + abbreviateNumber(barItem.programPrice)}
                                        </Text>
                                        {pNames && pNames.length ? (
                                          pNames.map((pName, pI) => {
                                            return (
                                              <Text
                                                style={{
                                                  fontSize: 4,
                                                  marginBottom: 1
                                                }}
                                                key={"pn_" + pI}>
                                                {pName}
                                              </Text>
                                            );
                                          })
                                        ) : (
                                          <Text
                                            style={{
                                              fontSize: 4
                                            }}>
                                            {barItem.programName}
                                          </Text>
                                        )}
                                      </View>
                                    );
                                  })}
                                </View>
                              </View>
                            </View>
                          </React.Fragment>
                        );
                      })}
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col_100}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginVertical: 15,
                          alignSelf: "center"
                        }}>
                        *Program success rates are an average of patients who completed a
                        Multi-Cycle Discount or Refund Program including many who required multiple
                        IVF attempts to have a baby.
                      </Text>
                    </View>
                  </View>

                  <Text style={styles.footer} fixed>
                    Always refer to the detailed estimate published on your patient portal for the
                    most up to date information. Above pricing is specific to the treatment plan
                    identified. Should your treatment plan change, pricing may vary. Prices are not
                    guaranteed until your treatment plan is finalized.
                  </Text>
                  <Text style={styles.footer_date} fixed>
                    As of {moment().tz("America/New_York").format("MMMM D, YYYY [a]t hh:mm a z")}
                  </Text>
                  <Text
                    style={styles.footer_page_number}
                    render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
                    fixed></Text>
                </Page>
              ) : null}
            </React.Fragment>
          );
        })}
    </Document>
  );
};

export default FinancialEstimatePDF;
