import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MessageIcon from "@mui/icons-material/Message";
import UserProfileIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import SearchDrawer from "./SearchDrawer";
import isAccess, { isValidToken, cognitoUserId } from "./CheckUserRights";
import ProfileDrawer from "./ProfileDrawer";
import { connect } from "react-redux";
import * as actionType from "../../shared/actions";
import jwtDecode from "jwt-decode";

function Header(props) {
  const location = useLocation();
  const navigate = useNavigate();
  let isDisable = "";
  const { enableNavFlg, profileDetails, loading, actions } = props;
  let changeUrlLink = location.pathname.toString().split("/")[1];
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [userScreenRights, setUserScreenRights] = useState([]);

  const initial_url_link_mapping = {
    workspace: {
      fe: "financial-estimates",
      ftacpg: "fertility-access-program"
    },
    patients: {
      pt: "patient-directory"
    },
    admin: {
      prpm: "practice-payment",
      pr: "practice",
      gdm: "gdm",
      pgv: "program-visualization",
      rfagc: "refund-age",
      phrmsc: "physician-reimbursement-scale",
      rlvltl: "PracticeConfiguration/relativeValue",
      pgtalc: "PGTA",
      ucron: "user-tab",
      ptpla: "patient-portal-admin",
      sfa: "staff-admin",
      dclb: "document-library"
    },
    trak: {
      cl: "caselookup",
      ct: "contract",
      pvrm: "provider-Reimbursement",
      rf: "refund",
      rn: "revenue",
      rp: "report",
      rfap: "refund-application"
    }
  };
  let workspaceScreenRights = [];
  let patientsScreenRights = [];
  let adminScreenRights = [];
  let trakScreenRights = [];

  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token === null || token === undefined || token === "") {
      navigate("/logout");
      localStorage.clear();
    } else {
      const expiryDate = new Date(jwtDecode(token).exp * 1000);
      const datenow = new Date();

      if (datenow > expiryDate) {
        navigate("/logout");
        localStorage.clear();
      }
    }
  });

  useEffect(() => {
    if (profileDetails?.userRightList) {
      const data = Object.values(profileDetails.userRightList);

      let userScreenRightDetails = data?.map((item) => {
        return {
          userDetails: item
        };
      });
      //console.log("userScreenRightDetails", userScreenRightDetails);
      localStorage.setItem("screenRights", JSON.stringify(userScreenRightDetails));
      const perdata = Object.values(profileDetails.userRightList);
      const allPermissions = [
        ...new Set(Object.values(perdata).join(":").split(":").filter(Boolean))
      ];

      setUserScreenRights(allPermissions);
    }
  }, [profileDetails]);
  /* ### only the url with id-token - start ### */
  const getIntialURL = (groupRights) => {
    let screenRightsArr = [];
    changeUrlLink.length === 0 &&
      Object.keys(initial_url_link_mapping[groupRights]).forEach((screenRightsValue) => {
        let isScreenRightExist = userScreenRights.includes(screenRightsValue);
        isScreenRightExist &&
          screenRightsArr.push(initial_url_link_mapping[groupRights][screenRightsValue]);
      });
    return screenRightsArr;
  };
  useEffect(() => {
    if (loading) {
      actions.enablePracticeNavFlag();
    }
  }, [loading]);

  useEffect(() => {
    //console.log("==>storeScreenRights1", localStorage.getItem("screenRights"));

    if (changeUrlLink.length === 0 && isValidToken && userScreenRights.length > 0) {
      workspaceScreenRights = getIntialURL("workspace");
      patientsScreenRights = getIntialURL("patients");
      adminScreenRights = getIntialURL("admin");
      trakScreenRights = getIntialURL("trak");

      if (workspaceScreenRights.length > 0) {
        navigate(`/workspace/${workspaceScreenRights[0]}`);
      } else if (patientsScreenRights.length > 0) {
        navigate(`/patients/${patientsScreenRights[0]}`);
      } else if (adminScreenRights.length > 0) {
        navigate(`/admin/${adminScreenRights[0]}`);
      } else if (trakScreenRights.length > 0) {
        navigate(`/trak/${trakScreenRights[0]}`);
      } else {
        navigate(`/logout`);
      }
    }
  }, [isValidToken, userScreenRights, profileDetails]);
  /* ### only the url with id-token - end ### */

  /* ### only the url with pathname - start ### */
  const tabChange = (groupRights) => {
    let screenToDisplay = [];
    changeUrlLink.length > 0 &&
      Object.keys(initial_url_link_mapping[groupRights]).forEach((userScreenRightsValue) => {
        if (userScreenRights.includes(userScreenRightsValue) === true) {
          screenToDisplay.push(initial_url_link_mapping[groupRights][userScreenRightsValue]);
        }
      });
    return screenToDisplay;
  };
  if (changeUrlLink.length > 0 && isValidToken) {
    workspaceScreenRights = tabChange("workspace");
    patientsScreenRights = tabChange("patients");
    adminScreenRights = tabChange("admin");
    trakScreenRights = tabChange("trak");
  }
  /* ### only the url with pathname - end ### */
  let workSpaceUrlPath = workspaceScreenRights[0] ? workspaceScreenRights[0] : "";
  let patientsUrlPath = patientsScreenRights[0] ? patientsScreenRights[0] : "";
  let adminUrlPath = adminScreenRights[0] ? adminScreenRights[0] : "";
  let trakUrlPath = trakScreenRights[0] ? trakScreenRights[0] : "";
  const navItems = [
    {
      name: "WORKSPACE",
      link: `workspace/${workSpaceUrlPath}`,
      disableSearch: false,
      isViewable: workSpaceUrlPath
    },
    {
      name: "PATIENTS",
      link: `patients/${patientsUrlPath}`,
      disableSearch: false,
      isViewable: patientsUrlPath
    },
    {
      name: "ADMIN",
      link: `admin/${adminUrlPath}`,
      disableSearch: true,
      isViewable: adminUrlPath
    },
    {
      name: "FA OPERATIONS",
      link: `trak/${trakUrlPath}`,
      disableSearch: false,
      isViewable: trakUrlPath
    }
  ];

  return (
    <>
      <nav className="wrapper nav-global" style={{ zIndex: 10 }}>
        <ul className="wrapper nav-global-section list-no-style">
          <li className="nav-global-item">
            <Link className="nav-global-item-link" to="workspace/financial-estimates">
              {
                profileDetails?.defaultPracticeLogo != null && (
                  <img
                    src={`data:image/svg+xml;base64,${profileDetails?.defaultPracticeLogo}`}
                    alt="Practice Logo"
                    height={45}
                  />
                )
                // : (
                //   <img src="/Assets/FelixLogo.svg" alt="Felix" height={45} />
                // )
              }
            </Link>
          </li>
          {navItems.map((item) => {
            let isActive = "";
            if (location.pathname.indexOf(item.name.toLowerCase()) != -1) {
              isActive = "active";
            }
            if (
              item.name === "FA OPERATIONS" &&
              location.pathname.indexOf("trak".toLowerCase()) > 0
            ) {
              isActive = "active";
            }
            if (location.pathname.indexOf("admin") != -1) {
              isDisable = "disable";
            }
            return (
              item.isViewable.length > 0 && (
                <li className={"nav-global-item " + isActive} key={item.name}>
                  {enableNavFlg ? (
                    <Link className="nav-global-item-link" to={item.link}>
                      {item.name}
                    </Link>
                  ) : (
                    <Link
                      className="nav-global-item-link-disable"
                      to={item.link}
                      style={{ pointerEvents: "none" }}>
                      {item.name}
                    </Link>
                  )}
                </li>
              )
            );
          })}
        </ul>
        <ul className="wrapper nav-utility list-no-style m-r-50">
          {(isAccess("spt") || isAccess("ucron")) && (
            <li className={"nav-utility-item " + isDisable} title="Search">
              <span className="text-center">
                {enableNavFlg ? (
                  <Link
                    className={
                      isSearchOpen ? "nav-utility-item-link active" : "nav-utility-item-link "
                    }
                    to="#"
                    onClick={() => {
                      isProfileOpen != true
                        ? setIsSearchOpen(!isSearchOpen)
                        : setIsSearchOpen(isSearchOpen);
                    }}>
                    <SearchIcon />
                    <span className="nav-utility-item-text d-Flex">Search</span>
                  </Link>
                ) : (
                  <Link
                    className={
                      isSearchOpen
                        ? "nav-utility-item-link active"
                        : "nav-utility-item-link-disable "
                    }
                    style={{ pointerEvents: "none" }}
                    to="#"
                    onClick={() => {
                      isProfileOpen != true
                        ? setIsSearchOpen(!isSearchOpen)
                        : setIsSearchOpen(isSearchOpen);
                    }}>
                    <SearchIcon />
                    <span className="nav-utility-item-text d-Flex">Search</span>
                  </Link>
                )}
              </span>
            </li>
          )}
          {(isAccess("me") || isAccess("ucron")) && (
            <li className="nav-utility-item " title="Messages">
              <span className="text-center">
                {enableNavFlg ? (
                  <Link className="nav-utility-item-link" to="/messages/inbox">
                    <MessageIcon />
                    <span className="nav-utility-item-text d-Flex">
                      {/* <span className="unread-indicator"></span> */}
                      Messages
                    </span>
                  </Link>
                ) : (
                  <Link
                    className="nav-utility-item-link-disable"
                    to="/messages/inbox"
                    style={{ pointerEvents: "none" }}>
                    <MessageIcon />
                    <span className="nav-utility-item-text d-Flex">
                      {/* <span className="unread-indicator"></span> */}
                      Messages
                    </span>
                  </Link>
                )}
              </span>
            </li>
          )}
          {(isAccess("nf") || isAccess("ucron")) && (
            <li className="nav-utility-item " title="Messages">
              <span className="text-center">
                {enableNavFlg ? (
                  <Link className="nav-utility-item-link" to="/utilities/notifications">
                    <NotificationsIcon />
                    <span className="nav-utility-item-text d-Flex">
                      {/* <span className="unread-indicator"></span> */}
                      Notification
                    </span>
                  </Link>
                ) : (
                  <Link
                    className="nav-utility-item-link-disable"
                    to="/utilities/notifications"
                    style={{ pointerEvents: "none" }}>
                    <NotificationsIcon />
                    <span className="nav-utility-item-text d-Flex">
                      {/* <span className="unread-indicator"></span> */}
                      Notification
                    </span>
                  </Link>
                )}
              </span>
            </li>
          )}
          {(isAccess("pf") || isAccess("ucron")) && (
            <li className="nav-utility-item" title="Profile">
              <span className="text-center">
                {enableNavFlg ? (
                  <Link
                    className={
                      isProfileOpen ? "nav-utility-item-link active" : "nav-utility-item-link "
                    }
                    to="#"
                    onClick={() => {
                      isSearchOpen != true
                        ? setIsProfileOpen(!isProfileOpen)
                        : setIsProfileOpen(isProfileOpen);
                    }}
                    state={{ id: "1" }}>
                    <UserProfileIcon />
                    <span className="nav-utility-item-text d-Flex">Profile</span>
                  </Link>
                ) : (
                  <Link
                    className={
                      isProfileOpen
                        ? "nav-utility-item-link active"
                        : "nav-utility-item-link-disable "
                    }
                    style={{ pointerEvents: "none" }}
                    to="#"
                    onClick={() => {
                      isSearchOpen != true
                        ? setIsProfileOpen(!isProfileOpen)
                        : setIsProfileOpen(isProfileOpen);
                    }}
                    state={{ id: "1" }}>
                    <UserProfileIcon />
                    <span className="nav-utility-item-text d-Flex">Profile</span>
                  </Link>
                )}
              </span>
            </li>
          )}
        </ul>
      </nav>
      <SearchDrawer
        openSearch={location.pathname.includes("/admin") ? false : isSearchOpen}
        close={() => setIsSearchOpen(false)}
      />
      <ProfileDrawer
        openProfile={isProfileOpen}
        close={() => setIsProfileOpen(false)}
        cognitoUserId={cognitoUserId}
      />
    </>
  );
}
const mapStateToProps = ({ messageReducer, profileDrawerReducer }) => {
  return {
    enableNavFlg: messageReducer.enableNavFlg,
    profileDetails: profileDrawerReducer.profileDetails,
    loading: profileDrawerReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    enablePracticeNavFlag: () => {
      dispatch(actionType.enablePracticeNavFlag());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
