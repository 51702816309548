//Comments
export const COMMENT_LIST_REQUESTED = "COMMENT_LIST_REQUESTED";
export const COMMENT_LIST_SUCCESS = "COMMENT_LIST_SUCCESS";
export const CLINICAL_LIST_SUCCESS = "CLINICAL_LIST_SUCCESS";
export const FINANCIAL_LIST_SUCCESS = "FINANCIAL_LIST_SUCCESS";
export const COMMENT_LIST_FAIL = "COMMENT_LIST_FAIL";
export const RESET_COMMENT_LIST = "RESET_COMMENT_LIST";
export const RESET_COMMENT = "RESET_COMMENT";

//Add Comment
export const ADD_NEW_COMMENT_REQUESTED = "ADD_NEW_COMMENT_REQUESTED";
export const ADD_NEW_COMMENT_SUCCESS = "ADD_NEW_COMMENT_SUCCESS";
export const ADD_NEW_COMMENT_FAILED = "ADD_NEW_COMMENT_FAILED";

//Add Follow Up Date
export const ADD_FOLLOW_UP_DATE_REQUEST = "ADD_FOLLOW_UP_DATE_REQUEST";
export const ADD_FOLLOW_UP_DATE_SUCCESS = "ADD_FOLLOW_UP_DATE_SUCCESS";
export const ADD_FOLLOW_UP_DATE_FAILED = "ADD_FOLLOW_UP_DATE_FAILED";
export const RESET_TRAK_FINANCIAL_ESTIMATE_MESSAGE = "RESET_TRAK_FINANCIAL_ESTIMATE_MESSAGE";

// User Role
export const GET_USER_ROLE = "GET_USER_ROLE";
export const ADD_USER_ROLE = "ADD_USER_ROLE";

//Miscellaneous Data
export const MISCELLANEOUS_DATA_REQUESTED = "MISCELLANEOUS_DATA_REQUESTED";
export const MISCELLANEOUS_DATA_SUCCESS = "MISCELLANEOUS_DATA_SUCCESS";
export const MISCELLANEOUS_DATA_FAILED = "MISCELLANEOUS_DATA_FAILED";

export const MISCELLANEOUS_DATA_UPDATE_REQUESTED = "MISCELLANEOUS_DATA_UPDATE_REQUESTED";
export const MISCELLANEOUS_DATA_UPDATE_SUCCESS = "MISCELLANEOUS_DATA_UPDATE_SUCCESS";
export const MISCELLANEOUS_DATA_UPDATE_FAILED = "MISCELLANEOUS_DATA_UPDATE_FAILED";

//Patient Contact Data
export const PATIENT_CONTACT_DATA_REQUESTED = "PATIENT_CONTACT_DATA_REQUESTED";
export const PATIENT_CONTACT_DATA_SUCCESS = "PATIENT_CONTACT_DATA_SUCCESS";
export const PATIENT_CONTACT_DATA_FAILED = "PATIENT_CONTACT_DATA_FAILED";

export const PATIENT_CONTACT_DATA_UPDATE_REQUESTED = "PATIENT_CONTACT_DATA_UPDATE_REQUESTED";
export const PATIENT_CONTACT_DATA_UPDATE_SUCCESS = "PATIENT_CONTACT_DATA_UPDATE_SUCCESS";
export const PATIENT_CONTACT_DATA_UPDATE_FAILED = "PATIENT_CONTACT_DATA_UPDATE_FAILED";

//Enrolment Data
export const ENROLMENT_DATA_REQUESTED = "ENROLMENT_DATA_REQUESTED";
export const ENROLMENT_DATA_SUCCESS = "ENROLMENT_DATA_SUCCESS";
export const ENROLMENT_DATA_FAILED = "ENROLMENT_DATA_FAILED";

export const RESET_MESSAGE = "RESET_MESSAGE";
export const RESET_API_MESSAGE = "RESET_API_MESSAGE";

export const ENROLMENT_DATA_UPDATE_REQUESTED = "ENROLMENT_DATA_UPDATE_REQUESTED";
export const ENROLMENT_DATA_UPDATE_SUCCESS = "ENROLMENT_DATA_UPDATE_SUCCESS";
export const ENROLMENT_DATA_UPDATE_FAILED = "ENROLMENT_DATA_UPDATE_FAILED";

//Program
export const PROGRAM_DATA_REQUESTED = "PROGRAM_DATA_REQUESTED";
export const PROGRAM_DATA_SUCCESS = "PROGRAM_DATA_SUCCESS";
export const PROGRAM_DATA_FAILED = "PROGRAM_DATA_FAILED";

//Metadata
export const METADATA_DATA_REQUESTED = "METADATA_DATA_REQUESTED";
export const METADATA_DATA_SUCCESS = "METADATA_DATA_SUCCESS";
export const METADATA_DATA_FAILED = "METADATA_DATA_FAILED";

//Physician
export const PHYSICIAN_DATA_REQUESTED = "PHYSICIAN_DATA_REQUESTED";
export const PHYSICIAN_DATA_SUCCESS = "PHYSICIAN_DATA_SUCCESS";
export const PHYSICIAN_DATA_FAILED = "PHYSICIAN_DATA_FAILED";

//Account Flag
export const ACCOUNT_FLAG_DATA_REQUESTED = "ACCOUNT_FLAG_DATA_REQUESTED";
export const ACCOUNT_FLAG_DATA_SUCCESS = "ACCOUNT_FLAG_DATA_SUCCESS";
export const ACCOUNT_FLAG_DATA_FAILED = "ACCOUNT_FLAG_DATA_FAILED";

export const ACCOUNT_FLAG_DATA_UPDATE_REQUESTED = "ACCOUNT_FLAG_DATA_UPDATE_REQUESTED";
export const ACCOUNT_FLAG_DATA_UPDATE_SUCCESS = "ACCOUNT_FLAG_DATA_UPDATE_SUCCESS";
export const ACCOUNT_FLAG_DATA_UPDATE_FAILED = "ACCOUNT_FLAG_DATA_UPDATE_FAILED";

//Document
export const DOCUMENT_LIST_REQUESTED = "DOCUMENT_LIST_REQUESTED";
export const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS";
export const DOCUMENT_LIST_FAIL = "DOCUMENT_LIST_FAIL";
export const RESET_DOCUMENT_LIST = "RESET_DOCUMENT_LIST";
export const RESET_DOCUMENT = "RESET_DOCUMENT";

//Fees
export const POST_FEES_REQUESTED = "FEES_REQUESTED";
export const POST_FEES_SUCCESS = "FEES_SUCCESS";
export const POST_FEES_FAILED = "FEES_FAILED";

//Add Document
export const ADD_NEW_DOCUMENT_REQUESTED = "ADD_NEW_DOCUMENT_REQUESTED";
export const ADD_NEW_DOCUMENT_SUCCESS = "ADD_NEW_DOCUMENT_SUCCESS";
export const ADD_NEW_DOCUMENT_FAILED = "ADD_NEW_DOCUMENT_FAILED";

//Edit Document
export const EDIT_DOCUMENT_REQUESTED = "EDIT_DOCUMENT_REQUESTED";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_FAILED = "EDIT_DOCUMENT_FAILED";

//Status
export const STATUS_LIST_REQUESTED = "STATUS_LIST_REQUESTED";
export const STATUS_LIST_SUCCESS = "STATUS_LIST_SUCCESS";
export const STATUS_LIST_FAIL = "STATUS_LIST_FAIL";
export const RESET_STATUS_LIST = "RESET_STATUS_LIST";
export const RESET_STATUS = "RESET_STATUS";
export const RESET_ADD_STATUS = "RESET_ADD_STATUS";

//Delete status
export const DELETE_STATUS_REQUESTED = "DELETE_STATUS_REQUESTED";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAILED = "DELETE_STATUS_FAILED";

//Add Comment
export const ADD_NEW_STATUS_REQUESTED = "ADD_NEW_STATUS_REQUESTED";
export const ADD_NEW_STATUS_SUCCESS = "ADD_NEW_STATUS_SUCCESS";
export const ADD_NEW_STATUS_FAILED = "ADD_NEW_STATUS_FAILED";

//Medcase
export const MEDCASE_LIST_REQUESTED = "MEDCASE_LIST_REQUESTED";
export const MEDCASE_LIST_SUCCESS = "MEDCASE_LIST_SUCCESS";
export const MEDCASE_LIST_FAILED = "MEDCASE_LIST_FAILED";

//Contracts
export const CONTRACT_LIST_REQUESTED = "CONTRACT_LIST_REQUESTED";
export const CONTRACT_LIST_SUCCESS = "CONTRACT_LIST_SUCCESS";
export const CONTRACT_LIST_FAILED = "CONTRACT_LIST_FAILED";

export const CONTRACT_DOCUMENT_DOWNLOAD_REQUESTED = "CONTRACT_DOCUMENT_DOWNLOAD_REQUESTED";
export const CONTRACT_DOCUMENT_DOWNLOAD_SUCCESS = "CONTRACT_DOCUMENT_DOWNLOAD_SUCCESS";
export const CONTRACT_DOCUMENT_DOWNLOAD_FAILED = "CONTRACT_DOCUMENT_DOWNLOAD_FAILED";
export const RESET_CONTRACT_DOCUMENT_DOWNLOAD = "RESET_CONTRACT_DOCUMENT_DOWNLOAD";

//CaseList
export const CASE_LIST_REQUESTED = "CASE_LIST_REQUESTED";
export const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
export const CASE_LIST_FAILED = "CASE_LIST_FAILED";

//Pr
export const PROVIDEREIMBURSEMENT_LIST_REQUESTED = "PROVIDEREIMBURSEMENT_LIST_REQUESTED";
export const PROVIDEREIMBURSEMENT_LIST_SUCCESS = "PROVIDEREIMBURSEMENT_LIST_SUCCESS";
export const PROVIDEREIMBURSEMENT_LIST_FAIL = "PROVIDEREIMBURSEMENT_LIST_FAIL";
export const RESET_PROVIDEREIMBURSEMENT_LIST = "RESET_PROVIDEREIMBURSEMENT_LIST";
export const RESET_PROVIDEREIMBURSEMENT = "RESET_PROVIDEREIMBURSEMENT";

//Add Pr
export const ADD_NEW_PROVIDEREIMBURSEMENT_REQUESTED = "ADD_NEW_PROVIDEREIMBURSEMENT_REQUESTED";
export const ADD_NEW_PROVIDEREIMBURSEMENT_SUCCESS = "ADD_NEW_PROVIDEREIMBURSEMENT_SUCCESS";
export const ADD_NEW_PROVIDEREIMBURSEMENT_FAILED = "ADD_NEW_PROVIDEREIMBURSEMENT_FAILED";

//Edit Pr
export const EDIT_PROVIDEREIMBURSEMENT_REQUESTED = "EDIT_PROVIDEREIMBURSEMENT_REQUESTED";
export const EDIT_PROVIDEREIMBURSEMENT_SUCCESS = "EDIT_PROVIDEREIMBURSEMENT_SUCCESS";
export const EDIT_PROVIDEREIMBURSEMENT_FAILED = "EDIT_PROVIDEREIMBURSEMENT_FAILED";
//add aprooved data
export const ADD_APPROVED_REQUESTED = "ADD_APPROVED_REQUESTED";
export const ADD_APPROVED_SUCCESS = "ADD_APPROVED_SUCCESS";
export const ADD_APPROVED_FAILED = "ADD_APPROVED_FAILED";

//RUN PR
export const RUN_PR_REQUESTED = "RUN_PR_REQUESTED";
export const RUN_PR_SUCCESS = "RUN_PR_SUCCESS";
export const RUN_PR_FAILED = "RUN_PR_FAILED";

export const REIMBURSEMENTDETAILS_LIST_REQUESTED = "REIMBURSEMENTDETAILS_LIST_REQUESTED";
export const REIMBURSEMENTDETAILS_LIST_SUCCESS = "REIMBURSEMENTDETAILS_LIST_SUCCESS";
export const REIMBURSEMENTDETAILS_LIST_FAIL = "REIMBURSEMENTDETAILS_LIST_FAIL";
export const RESET_REIMBURSEMENTDETAILS_LIST = "RESET_REIMBURSEMENTDETAILS_LIST";
export const RESET_REIMBURSEMENTDETAILS = "RESET_REIMBURSEMENTDETAILS";

//EDIT PR
export const EDIT_PR_REQUESTED = "EDIT_PR_REQUESTED";
export const EDIT_PR_SUCCESS = "EDIT_PR_SUCCESS";
export const EDIT_PR_FAILED = "EDIT_PR_FAILED";
//Address
export const ADDRESS_LIST_REQUESTED = "ADDRESS_LIST_REQUESTED";
export const ADDRESS_LIST_SUCCESS = "ADDRESS_LIST_SUCCESS";
export const ADDRESS_LIST_FAIL = "ADDRESS_LIST_FAIL";
export const RESET_ADDRESS_LIST = "RESET_ADDRESS_LIST";
export const RESET_ADDRESS = "RESET_ADDRESS";

//Add Address
export const ADD_NEW_ADDRESS_REQUESTED = "ADD_NEW_ADDRESS_REQUESTED";
export const ADD_NEW_ADDRESS_SUCCESS = "ADD_NEW_ADDRESS_SUCCESS";
export const ADD_NEW_ADDRESS_FAILED = "ADD_NEW_ADDRESS_FAILED";

//Edit Address
export const EDIT_ADDRESS_REQUESTED = "EDIT_ADDRESS_REQUESTED";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILED = "EDIT_ADDRESS_FAILED";

//delete Address
export const DELETE_ADDRESS_REQUESTED = "DELETE_ADDRESS_REQUESTED";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";

//Phone
export const PHONE_LIST_REQUESTED = "PHONE_LIST_REQUESTED";
export const PHONE_LIST_SUCCESS = "PHONE_LIST_SUCCESS";
export const PHONE_LIST_FAIL = "PHONE_LIST_FAIL";
export const RESET_PHONE_LIST = "RESET_PHONE_LIST";
export const RESET_PHONE = "RESET_PHONE";

//Add Phone
export const ADD_NEW_PHONE_REQUESTED = "ADD_NEW_PHONE_REQUESTED";
export const ADD_NEW_PHONE_SUCCESS = "ADD_NEW_PHONE_SUCCESS";
export const ADD_NEW_PHONE_FAILED = "ADD_NEW_PHONE_FAILED";

//Edit Phone
export const EDIT_PHONE_REQUESTED = "EDIT_PHONE_REQUESTED";
export const EDIT_PHONE_SUCCESS = "EDIT_PHONE_SUCCESS";
export const EDIT_PHONE_FAILED = "EDIT_PHONE_FAILED";

//delete Phone
export const DELETE_PHONE_REQUESTED = "DELETE_PHONE_REQUESTED";
export const DELETE_PHONE_SUCCESS = "DELETE_PHONE_SUCCESS";
export const DELETE_PHONE_FAILED = "DELETE_PHONE_FAILED";

//Email
export const EMAIL_LIST_REQUESTED = "EMAIL_LIST_REQUESTED";
export const EMAIL_LIST_SUCCESS = "EMAIL_LIST_SUCCESS";
export const EMAIL_LIST_FAIL = "EMAIL_LIST_FAIL";
export const RESET_EMAIL_LIST = "RESET_EMAIL_LIST";
export const RESET_EMAIL = "RESET_EMAIL";

//Add Email
export const ADD_NEW_EMAIL_REQUESTED = "ADD_NEW_EMAIL_REQUESTED";
export const ADD_NEW_EMAIL_SUCCESS = "ADD_NEW_EMAIL_SUCCESS";
export const ADD_NEW_EMAIL_FAILED = "ADD_NEW_EMAIL_FAILED";

//Edit Email
export const EDIT_EMAIL_REQUESTED = "EDIT_EMAIL_REQUESTED";
export const EDIT_EMAIL_SUCCESS = "EDIT_EMAIL_SUCCESS";
export const EDIT_EMAIL_FAILED = "EDIT_EMAIL_FAILED";

//delete Email
export const DELETE_EMAIL_REQUESTED = "DELETE_EMAIL_REQUESTED";
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS";
export const DELETE_EMAIL_FAILED = "DELETE_EMAIL_FAILED";

//Participant
export const PARTICIPANT_LIST_REQUESTED = "PARTICIPANT_LIST_REQUESTED";
export const PARTICIPANT_LIST_SUCCESS = "PARTICIPANT_LIST_SUCCESS";
export const PARTICIPANT_LIST_FAIL = "PARTICIPANT_LIST_FAIL";
export const RESET_PARTICIPANT_LIST = "RESET_PARTICIPANT_LIST";
export const RESET_PARTICIPANT = "RESET_PARTICIPANT";

export const PARTICIPANT_LIST_UPDATE_REQUESTED = "PARTICIPANT_LIST_UPDATE_REQUESTED";
export const PARTICIPANT_LIST_UPDATE_SUCCESS = "PARTICIPANT_LIST_UPDATE_SUCCESS";
export const PARTICIPANT_LIST_UPDATE_FAILED = "PARTICIPANT_LIST_UPDATE_FAILED";

//Edit Participant
export const EDIT_PARTICIPANT_REQUESTED = "EDIT_PARTICIPANT_REQUESTED";
export const EDIT_PARTICIPANT_SUCCESS = "EDIT_PARTICIPANT_SUCCESS";
export const EDIT_PARTICIPANT_FAILED = "EDIT_PARTICIPANT_FAILED";

//Partner
export const PARTNER_LIST_REQUESTED = "PARTNER_LIST_REQUESTED";
export const PARTNER_LIST_SUCCESS = "PARTNER_LIST_SUCCESS";
export const PARTNER_LIST_FAIL = "PARTNER_LIST_FAIL";
export const RESET_PARTNER_LIST = "RESET_PARTNER_LIST";
export const RESET_PARTNER = "RESET_PARTNER";

//Add Partner
export const ADD_NEW_PARTNER_REQUESTED = "ADD_NEW_PARTNER_REQUESTED";
export const ADD_NEW_PARTNER_SUCCESS = "ADD_NEW_PARTNER_SUCCESS";
export const ADD_NEW_PARTNER_FAILED = "ADD_NEW_PARTNER_FAILED";

//Edit Partner
export const EDIT_PARTNER_REQUESTED = "EDIT_PARTNER_REQUESTED";
export const EDIT_PARTNER_SUCCESS = "EDIT_PARTNER_SUCCESS";
export const EDIT_PARTNER_FAILED = "EDIT_PARTNER_FAILED";

export const MEDCASE_STATUS_REQUESTED = "MEDCASE_STATUS_REQUESTED";
export const MEDCASE_STATUS_SUCCESS = "MEDCASE_STATUS_SUCCESS";
export const MEDCASE_STATUS_FAIL = "MEDCASE_STATUS_FAIL";

export const MEDCASE_HEADER_REQUESTED = "MEDCASE_HEADER_REQUESTED";
export const MEDCASE_HEADER_SUCCESS = "MEDCASE_HEADER_SUCCESS";
export const MEDCASE_HEADER_FAIL = "MEDCASE_HEADER_FAIL";

export const MEDCASE_DDL_STATUS_REQUESTED = "MEDCASE_DDL_STATUS_REQUESTED";
export const MEDCASE_DDL_STATUS_SUCCESS = "MEDCASE_DDL_STATUS_SUCCESS";
export const MEDCASE_DDL_STATUS_FAIL = "MEDCASE_DDL_STATUS_FAIL";

export const PATIENT_DETAILS_HEADER_REQUESTED = "PATIENT_DETAILS_HEADER";
export const PATIENT_DETAILS_HEADER_SUCCESS = "PATIENT_DETAILS_HEADER_SUCCESS";
export const PATIENT_DETAILS_HEADER_FAILED = "PATIENT_DETAILS_HEADER_FAILED";

export const MEDCASE_DETAILS_HEADER_REQUESTED = "MEDCASE_DETAILS_HEADER_REQUESTED";
export const MEDCASE_DETAILS_HEADER_SUCCESS = "MEDCASE_DETAILS_HEADER_SUCCESS";
export const MEDCASE_DETAILS_HEADER_FAILED = "MEDCASE_DETAILS_HEADER_FAILED";

//GeneticTesting
export const GENETICTESTING_LIST_REQUESTED = "GENETICTESTING_LIST_REQUESTED";
export const GENETICTESTING_LIST_SUCCESS = "PGENETICTESTING_LIST_SUCCESS";
export const GENETICTESTING_LIST_FAIL = "GENETICTESTING_LIST_FAIL";
export const RESET_GENETICTESTING_LIST = "RESET_GENETICTESTING_LIST";
export const RESET_GENETICTESTING = "RESET_GENETICTESTING";

export const PGTA_SUMMARY_ANALYSIS_REQUESTED = "PGTA_SUMMARY_ANALYSIS_REQUESTED";
export const PGTA_SUMMARY_ANALYSIS_SUCCESS = "PGTA_SUMMARY_ANALYSIS_SUCCESS";
export const PGTA_SUMMARY_ANALYSIS_FAIL = "PGTA_SUMMARY_ANALYSIS_FAIL";

//Add GeneticTesting
export const ADD_NEW_GENETICTESTING_REQUESTED = "ADD_NEW_GENETICTESTING_REQUESTED";
export const ADD_NEW_GENETICTESTING_SUCCESS = "ADD_NEW_GENETICTESTING_SUCCESS";
export const ADD_NEW_GENETICTESTING_FAILED = "ADD_NEW_GENETICTESTING_FAILED";

//Edit GeneticTesting
export const EDIT_GENETICTESTING_REQUESTED = "EDIT_GENETICTESTING_REQUESTED";
export const EDIT_GENETICTESTING_SUCCESS = "EDIT_GENETICTESTING_SUCCESS";
export const EDIT_GENETICTESTING_FAILED = "EDIT_GENETICTESTING_FAILED";

//Add Lab Payout
export const ADD_LAB_PAYOUT_REQUESTED = "ADD_LAB_PAYOUT_REQUESTED";
export const ADD_LAB_PAYOUT_SUCCESS = "ADD_LAB_PAYOUT_SUCCESS";
export const ADD_LAB_PAYOUT_FAIL = "ADD_LAB_PAYOUT_FAIL";
export const RESET_LAB_PAYOUT = "RESET_LAB_PAYOUT";

//Manage Lab
export const GET_MANAGE_LAB_REQUESTED = "GET_MANAGE_LAB_REQUESTED";
export const GET_MANAGE_LAB_SUCCESS = "GET_MANAGE_LAB_SUCCESS";
export const GET_MANAGE_LAB_FAIL = "GET_MANAGE_LAB_FAIL";

export const EDIT_MANAGE_LAB_REQUESTED = "EDIT_MANAGE_LAB_REQUESTED";
export const EDIT_MANAGE_LAB_SUCCESS = "EDIT_MANAGE_LAB_SUCCESS";
export const EDIT_MANAGE_LAB_FAIL = "EDIT_MANAGE_LAB_FAIL";

export const ADD_MANAGE_LAB_REQUESTED = "ADD_MANAGE_LAB_REQUESTED";
export const ADD_MANAGE_LAB_SUCCESS = "ADD_MANAGE_LAB_SUCCESS";
export const ADD_MANAGE_LAB_FAIL = "ADD_MANAGE_LAB_FAIL";
export const RESET_MANAGE_LAB = "RESET_MANAGE_LAB";

//AddFees
export const ADDFEES_LIST_REQUESTED = "ADDFEES_LIST_REQUESTED";
export const ADDFEES_LIST_SUCCESS = "ADDFEES_LIST_SUCCESS";
export const ADDFEES_LIST_FAIL = "ADDFEES_LIST_FAIL";
export const RESET_ADDFEES_LIST = "RESET_ADDFEES_LIST";
export const RESET_ADDFEES = "RESET_ADDFEES";

//Add AddFees
export const ADD_NEW_ADDFEES_REQUESTED = "ADD_NEW_ADDFEES_REQUESTED";
export const ADD_NEW_ADDFEES_SUCCESS = "ADD_NEW_ADDFEES_SUCCESS";
export const ADD_NEW_ADDFEES_FAILED = "ADD_NEW_ADDFEES_FAILED";

//Edit AddFees
export const EDIT_ADDFEES_REQUESTED = "EDIT_ADDFEES_REQUESTED";
export const EDIT_ADDFEES_SUCCESS = "EDIT_ADDFEES_SUCCESS";
export const EDIT_ADDFEES_FAILED = "EDIT_ADDFEES_FAILED";

//delete Transaction
export const DELETE_TRANSACTION_REQUESTED = "DELETE_TRANSACTION_REQUESTED";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL";

//edit Transaction
export const EDIT_TRANSACTION_REQUESTED = "EDIT_TRANSACTION_REQUESTED";
export const EDIT_TRANSACTION_SUCCESS = "EDIT_TRANSACTION_SUCCESS";
export const EDIT_TRANSACTION_FAIL = "EDIT_TRANSACTION_FAIL";

//Transaction Log
export const TRANSACTION_LOG_REQUESTED = "TRANSACTION_LOG_REQUESTED";
export const TRANSACTION_LOG_SUCCESS = "TRANSACTION_LOG_SUCCESS";
export const TRANSACTION_LOG_FAILED = "TRANSACTION_LOG_FAILED";
export const RESET_TRANSACTION_LOG = "RESET_TRANSACTION_LOG";
export const RESET_TRANSACTION_MESSAGE = "RESET_TRANSACTION_MESSAGE";

//Program Name
export const PROGRAM_NAME_REQUESTED = "PROGRAM_NAME_REQUESTED";
export const PROGRAM_NAME_SUCCESS = "PROGRAM_NAME_SUCCESS";
export const PROGRAM_NAME_FAILED = "PROGRAM_NAME_FAILED";
export const RESET_PROGRAM = "RESET_PROGRAM";

//financialSnapshotPayment
export const FINANCIAL_SNAPSHOT_PAYMENT_REQUESTED = "FINANCIAL_SNAPSHOT_PAYMENT_REQUESTED";
export const FINANCIAL_SNAPSHOT_PAYMENT_SUCCESS = "FINANCIAL_SNAPSHOT_PAYMENT_SUCCESS";
export const FINANCIAL_SNAPSHOT_PAYMENT_FAILED = "FINANCIAL_SNAPSHOT_PAYMENT_FAILED";

//SetManualRefund
export const MANUAL_REFUND_REQUESTED = "MANUAL_REFUND_REQUESTED";
export const MANUAL_REFUND_SUCCESS = "MANUAL_REFUND_SUCCESS";
export const MANUAL_REFUND_FAILED = "MANUAL_REFUND_FAILED";
export const MANUAL_REFUND_RESET = "MANUAL_REFUND_RESET";

//patient payment
export const CREDIT_CARD_PAYMENT_REQUESTED = "CREDIT_CARD_PAYMENT_REQUESTED";
export const CREDIT_CARD_PAYMENT_SUCCESS = "CREDIT_CARD_PAYMENT_SUCCESS";
export const CREDIT_CARD_PAYMENT_FAILED = "CREDIT_CARD_PAYMENT_FAILED";

export const CREATE_CREDIT_CARD_PAYMENT_REQUESTED = "CREATE_CREDIT_CARD_PAYMENT_REQUESTED";
export const CREATE_CREDIT_CARD_PAYMENT_SUCCESS = "CREATE_CREDIT_CARD_PAYMENT_SUCCESS";
export const CREATE_CREDIT_CARD_PAYMENT_FAILED = "CREATE_CREDIT_CARD_PAYMENT_FAILED";

export const CHECK_PAYMENT_REQUESTED = "CHECK_PAYMENT_REQUESTED";
export const CHECK_PAYMENT_SUCCESS = "CHECK_PAYMENT_SUCCESS";
export const CHECK_PAYMENT_FAILED = "CHECK_PAYMENT_FAILED";

export const CREATE_CHECK_PAYMENT_REQUESTED = "CREATE_CHECK_PAYMENT_REQUESTED";
export const CREATE_CHECK_PAYMENT_SUCCESS = "CREATE_CHECK_PAYMENT_SUCCESS";
export const CREATE_CHECK_PAYMENT_FAILED = "CREATE_CHECK_PAYMENT_FAILED";

export const LOAN_PAYMENT_REQUESTED = "LOAN_PAYMENT_REQUESTED";
export const LOAN_PAYMENT_SUCCESS = "LOAN_PAYMENT_SUCCESS";
export const LOAN_PAYMENT_FAILED = "LOAN_PAYMENT_FAILED";

export const CREATE_LOAN_PAYMENT_REQUESTED = "CREATE_LOAN_PAYMENT_REQUESTED";
export const CREATE_LOAN_PAYMENT_SUCCESS = "CREATE_LOAN_PAYMENT_SUCCESS";
export const CREATE_LOAN_PAYMENT_FAILED = "CREATE_LOAN_PAYMENT_FAILED";

export const WIRE_TRANSFER_PAYMENT_REQUESTED = "WIRE_TRANSFER_PAYMENT_REQUESTED";
export const WIRE_TRANSFER_PAYMENT_SUCCESS = "WIRE_TRANSFER_PAYMENT_SUCCESS";
export const WIRE_TRANSFER_PAYMENT_FAILED = "WIRE_TRANSFER_PAYMENT_FAILED";

export const CREATE_WIRE_TRANSFER_PAYMENT_REQUESTED = "CREATE_WIRE_TRANSFER_PAYMENT_REQUESTED";
export const CREATE_WIRE_TRANSFER_PAYMENT_SUCCESS = "CREATE_WIRE_TRANSFER_PAYMENT_SUCCESS";
export const CREATE_WIRE_TRANSFER_PAYMENT_FAILED = "CREATE_WIRE_TRANSFER_PAYMENT_FAILED";

// Save Transaction Details
export const EDIT_CREDIT_CARD_PAYMENT_REQUESTED = "EDIT_CREDIT_CARD_PAYMENT_REQUESTED";
export const EDIT_CREDIT_CARD_PAYMENT_SUCCESS = "EDIT_CREDIT_CARD_PAYMENT_SUCCESS";
export const EDIT_CREDIT_CARD_PAYMENT_FAILED = "EDIT_CREDIT_CARD_PAYMENT_FAILED";

export const EDIT_CHECK_PAYMENT_REQUESTED = "EDIT_CHECK_PAYMENT_REQUESTED";
export const EDIT_CHECK_PAYMENT_SUCCESS = "EDIT_CHECK_PAYMENT_SUCCESS";
export const EDIT_CHECK_PAYMENT_FAILED = "EDIT_CHECK_PAYMENT_FAILED";

export const EDIT_LOAN_PAYMENT_REQUESTED = "EDIT_LOAN_PAYMENT_REQUESTED";
export const EDIT_LOAN_PAYMENT_SUCCESS = "EDIT_LOAN_PAYMENT_SUCCESS";
export const EDIT_LOAN_PAYMENT_FAILED = "EDIT_LOAN_PAYMENT_FAILED";

export const EDIT_WIRE_TRANSFER_PAYMENT_REQUESTED = "EDIT_WIRE_TRANSFER_PAYMENT_REQUESTED";
export const EDIT_WIRE_TRANSFER_PAYMENT_SUCCESS = "EDIT_WIRE_TRANSFER_PAYMENT_SUCCESS";
export const EDIT_WIRE_TRANSFER_PAYMENT_FAILED = "EDIT_WIRE_TRANSFER_PAYMENT_FAILED";

export const RESET_PATIENT_PAYMENT = "RESET_PATIENT_PAYMENT";

export const GET_PATIENT_PAYMENT_REQUESTED = "GET_PATIENT_PAYMENT_REQUESTED";
export const GET_PATIENT_PAYMENT_SUCCESS = "GET_PATIENT_PAYMENT_SUCCESS";
export const GET_PATIENT_PAYMENT_FAIL = "GET_PATIENT_PAYMENT_FAIL";
export const RESET_GET_PATIENT_PAYMENT = "RESET_GET_PATIENT_PAYMENT";

//Program Cycle
export const PROGRAM_CYCLE_REQUESTED = "PROGRAM_CYCLE_REQUESTED";
export const PROGRAM_CYCLE_SUCCESS = "PROGRAM_CYCLE_SUCCESS";
export const PROGRAM_CYCLE_FAILED = "PROGRAM_CYCLE_FAILED";

//Pricing Modal
export const PRICING_MODAL_DATA_REQUESTED = "PRICING_MODAL_DATA_REQUESTED";
export const PRICING_MODAL_DATA_SUCCESS = "PRICING_MODAL_DATA_SUCCESS";
export const PRICING_MODAL_DATA_FAILED = "PRICING_MODAL_DATA_FAILED";

export const CREATE_MEDCASE_MANUAL_REQUESTED = "CREATE_MEDCASE_MANUAL_REQUESTED";
export const CREATE_MEDCASE_MANUAL_SUCCESS = "CREATE_MEDCASE_MANUAL_SUCCESS";
export const CREATE_MEDCASE_MANUAL_FAILED = "CREATE_MEDCASE_MANUAL_FAILED";
export const RESET_MEDCASE = "RESET_MEDCASE";

//Trak => Refund
export const REFUND_FILTER_LIST_REQUESTED = "REFUND_FILTER_LIST_REQUESTED";
export const REFUND_FILTER_LIST_SUCCESS = "REFUND_FILTER_LIST_SUCCESS";
export const REFUND_FILTER_LIST_FAILED = "REFUND_FILTER_LIST_FAILED";
export const RESET_REFUND_FILTER_LIST = "RESET_REFUND_FILTER_LIST";

//Trak => Refund Application
export const REFUND_APPLICATION_LIST_REQUESTED = "REFUND_APPLICATION_LIST_REQUESTED";
export const REFUND_APPLICATION_LIST_SUCCESS = "REFUND_APPLICATION_LIST_SUCCESS";
export const REFUND_APPLICATION_LIST_FAILED = "REFUND_APPLICATION_LIST_FAILED";
export const RESET_REFUND_APPLICATION_LIST = "RESET_REFUND_APPLICATION_LIST";

//trak->financialSnapshotPGTA
export const FINANCIAL_SNAPSHOT_PGTA_REQUESTED = "FINANCIAL_SNAPSHOT_PGTA_REQUESTED";
export const FINANCIAL_SNAPSHOT_PGTA_SUCCESS = "FINANCIAL_SNAPSHOT_PGTA_SUCCESS";
export const FINANCIAL_SNAPSHOT_PGTA_FAILED = "FINANCIAL_SNAPSHOT_PGTA_FAILED";

export const CLINICAL_TREATMENT_OUTCOMES_REQUESTED = "CLINICAL_TREATMENT_OUTCOMES_REQUESTED";
export const CLINICAL_TREATMENT_OUTCOMES_SUCCESS = "CLINICAL_TREATMENT_OUTCOMES_SUCCESS";
export const CLINICAL_TREATMENT_OUTCOMES_FAILED = "CLINICAL_TREATMENT_OUTCOMES_FAILED";

export const EDIT_CLINICAL_TREATMENT_OUTCOMES_REQUESTED =
  "EDIT_CLINICAL_TREATMENT_OUTCOMES_REQUESTED";
export const EDIT_CLINICAL_TREATMENT_OUTCOMES_SUCCESS = "EDIT_CLINICAL_TREATMENT_OUTCOMES_SUCCESS";
export const EDIT_CLINICAL_TREATMENT_OUTCOMES_FAILED = "EDIT_CLINICAL_TREATMENT_OUTCOMES_FAILED";
export const RESET_OUTCOMELIST = "RESET_OUTCOMELIST";
export const RESET_RES = "RESET_RES";

//CreateProgramCycle
export const NEW_PR_REQUESTED = "NEW_PR_REQUESTED";
export const NEW_PR_SUCCESS = "NEW_PR_SUCCESS";
export const NEW_PR_FAILED = "NEW_PR_FAILED";

export const NEW_PR_POST_REQUESTED = "NEW_PR_POST_REQUESTED";
export const NEW_PR_POST_SUCCESS = "NEW_PR_POST_SUCCESS";
export const NEW_PR_POST_FAILED = "NEW_PR_POST_FAILED";

//Trak => Revenue
export const REVENUE_FILTER_LIST_REQUESTED = "REVENUE_FILTER_LIST_REQUESTED";
export const REVENUE_FILTER_LIST_SUCCESS = "REVENUE_FILTER_LIST_SUCCESS";
export const REVENUE_FILTER_LIST_FAIL = "REVENUE_FILTER_LIST_FAIL";
export const RESET_REVENUE_FILTER_LIST = "RESET_REVENUE_FILTER_LIST";

export const RUN_REVENUE_REQUESTED = "RUN_REVENUE_REQUESTED";
export const RUN_REVENUE_SUCCESS = "RUN_REVENUE_SUCCESS";
export const RUN_REVENUE_FAIL = "RUN_REVENUE_FAIL";
export const RESET_RUN_REVENUE = "RESET_RUN_REVENUE";

//SetManualRevenue
export const REVENUE_LIST_REQUESTED = "REVENUE_LIST_REQUESTED";
export const REVENUE_LIST_SUCCESS = "REVENUE_LIST_SUCCESS";
export const REVENUE_LIST_FAIL = "REVENUE_LIST_FAIL";

export const POST_REVENUE_REQUESTED = "ADD_REVENUE_REQUESTED";
export const POST_REVENUE_SUCCESS = "ADD_REVENUE_SUCCESS";
export const POST_REVENUE_FAILED = "ADD_REVENUE_FAILED";
export const RESET_REVENUE = "RESET_REVENUE";

export const CREATE_LINK_MEDCASE_REQUESTED = "CREATE_LINK_MEDCASE_REQUESTED";
export const CREATE_LINK_MEDCASE_SUCCESS = "CREATE_LINK_MEDCASE_SUCCESS";
export const CREATE_LINK_MEDCASE_FAILED = "CREATE_LINK_MEDCASE_FAILED";

//Email Document After Enroll or Outcome form send authorization
export const GET_EMAIL_OPTION_REQUESTED = "GET_EMAIL_OPTION_REQUESTED";
export const GET_EMAIL_OPTION_SUCCESS = "GET_EMAIL_OPTION_SUCCESS";
export const GET_EMAIL_OPTION_FAIL = "GET_EMAIL_OPTION_FAIL";

export const POST_EMAIL_OPTION_REQUESTED = "POST_EMAIL_OPTION_REQUESTED";
export const POST_EMAIL_OPTION_SUCCESS = "POST_EMAIL_OPTION_SUCCESS";
export const POST_EMAIL_OPTION_FAIL = "POST_EMAIL_OPTION_FAIL";
export const RESET_POST_EMAIL_OPTION = "RESET_POST_EMAIL_OPTION";

export const LINK_MEDCASE_REQUESTED = "LINK_MEDCASE_REQUESTED";
export const LINK_MEDCASE_SUCCESS = "LINK_MEDCASE_SUCCESS";
export const LINK_MEDCASE_FAIL = "LINK_MEDCASE_FAIL";

export const UNLINK_MEDCASE_REQUESTED = "UNLINK_MEDCASE_REQUESTED";
export const UNLINK_MEDCASE_SUCCESS = "UNLINK_MEDCASE_SUCCESS";
export const UNLINK_MEDCASE_FAIL = "UNLINK_MEDCASE_FAIL";
export const RESET_LINK_MEDCASE = "RESET_LINK_MEDCASE";

export const CREATE_SEND_AUTHORIZATION_REQUESTED = "CREATE_SEND_AUTHORIZATION_REQUESTED";
export const CREATE_SEND_AUTHORIZATION_SUCCESS = "CREATE_SEND_AUTHORIZATION_SUCCESS";
export const CREATE_SEND_AUTHORIZATION_FAIL = "CREATE_SEND_AUTHORIZATION_FAIL";

export const GET_REVENUE_REQUESTED = "GET_REVENUE_REQUESTED";
export const GET_REVENUE_SUCCESS = "GET_REVENUE_SUCCESS";
export const GET_REVENUE_FAIL = "GET_REVENUE_FAIL";

export const RESET_MEDCASE_LIST = "RESET_MEDCASE_LIST";

export const UPDATE_PARTNER_REQUESTED = "UPDATE_PARTNER_REQUESTED";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAIL = "UPDATE_PARTNER_FAIL";

export const MEDCASE_REVENUE_605_REQUESTED = "MEDCASE_REVENUE_605_REQUESTED";
export const MEDCASE_REVENUE_605_SUCCESS = "MEDCASE_REVENUE_605_SUCCESS";
export const MEDCASE_REVENUE_605_FAIL = "MEDCASE_REVENUE_605_FAIL";

export const GET_FINSNAPSHOT_REPORT_REQUESTED = "GET_FINSNAPSHOT_REPORT_REQUESTED";
export const GET_FINSNAPSHOT_REPORT_SUCCESS = "GET_FINSNAPSHOT_REPORT_SUCCESS";
export const GET_FINSNAPSHOT_REPORT_FAILED = "GET_FINSNAPSHOT_REPORT_FAILED";

//get financial snapshot reports data
export const GET_PAYMENTREGISTER_REPORT_REQUESTED = "GET_PAYMENTREGISTER_REPORT_REQUESTED";
export const GET_PAYMENTREGISTER_REPORT_SUCCESS = "GET_PAYMENTREGISTER_REPORT_SUCCESS";
export const GET_PAYMENTREGISTER_REPORT_FAILED = "GET_PAYMENTREGISTER_REPORT_FAILED";

//get enrollment reports data
export const GET_ENROLLMENT_REPORT_REQUESTED = "GET_ENROLLMENT_REPORT_REQUESTED";
export const GET_ENROLLMENT_REPORT_SUCCESS = "GET_ENROLLMENT_REPORT_SUCCESS";
export const GET_ENROLLMENT_REPORT_FAILED = "GET_ENROLLMENT_REPORT_FAILED";

// REVENUE VALIDATION
export const GET_REVENUE_VALIDATION = "GET_REVENUE_VALIDATION";
export const GET_REVENUEVALIDATION_REPORT_REQUESTED = "GET_REVENUEVALIDATION_REPORT_REQUESTED";
export const GET_REVENUEVALIDATION_REPORT_SUCCESS = "GET_REVENUEVALIDATION_REPORT_SUCCESS";
export const GET_REVENUEVALIDATION_REPORT_FAILED = "GET_REVENUEVALIDATION_REPORT_FAILED";
export const REVENUE_VALIDATION_RESET_MESSAGE = "REVENUE_VALIDATION_RESET_MESSAGE";
export const ALL_TREATMENT_PLAN_DATA = "ALL_TREATMENT_PLAN_DATA";
export const GET_ALL_TREATMENT_PLAN = "GET_ALL_TREATMENT_PLAN";
export const ALL_TREATMENT_PLAN_FAILED = "ALL_TREATMENT_PLAN_FAILED";

// REPORT -> OUTCOME REPORT
export const GET_OUTCOME_REPORT = "GET_OUTCOME_REPORT";
export const GET_OUTCOME_REPORT_SUCCESS = "GET_OUTCOME_REPORT_SUCCESS";
export const GET_OUTCOME_REPORT_FAILED = "GET_OUTCOME_REPORT_FAILED";
export const OUTCOME_REPORT_RESET_MESSAGE = "OUTCOME_REPORT_RESET_MESSAGE";

export const FIN_SNAPSHOT_605_ALL_REQUESTED = "FIN_SNAPSHOT_605_ALL_REQUESTED";
export const FIN_SNAPSHOT_605_ALL_SUCCESS = "FIN_SNAPSHOT_605_ALL_SUCCESS";
export const FIN_SNAPSHOT_605_ALL_FAIL = "FIN_SNAPSHOT_605_ALL_FAIL";

export const EDIT_ENROLL_REQUESTED = "EDIT_ENROLL_REQUESTED";
export const EDIT_ENROLL_SUCCESS = "EDIT_ENROLL_SUCCESS";
export const EDIT_ENROLL_FAIL = "EDIT_ENROLL_FAIL";
export const SELECTED_PATIENT_FOR_MEDCASE = "SELECTED_PATIENT_FOR_MEDCASE";

export const GET_ACTIVE_PARTICIPANTS_REPORT_REQUESTED = "GET_ACTIVE_PARTICIPANTS_REPORT_REQUESTED";
export const GET_ACTIVE_PARTICIPANTS_REPORT_SUCCESS = "GET_ACTIVE_PARTICIPANTS_REPORT_SUCCESS";
export const GET_ACTIVE_PARTICIPANTS_REPORT_FAILED = "GET_ACTIVE_PARTICIPANTS_REPORT_FAILED";

export const GET_INACTIVE_PARTICIPANTS_REPORT_REQUESTED =
  "GET_INACTIVE_PARTICIPANTS_REPORT_REQUESTED";
export const GET_INACTIVE_PARTICIPANTS_REPORT_SUCCESS = "GET_INACTIVE_PARTICIPANTS_REPORT_SUCCESS";
export const GET_INACTIVE_PARTICIPANTS_REPORT_FAILED = "GET_INACTIVE_PARTICIPANTS_REPORT_FAILED";

export const UPDATE_MANUAL_REVIEW_REQUESTED = "UPDATE_MANUAL_REVIEW_REQUESTED";
export const UPDATE_MANUAL_REVIEW_SUCCESS = "UPDATE_MANUAL_REVIEW_SUCCESS";
export const UPDATE_MANUAL_REVIEW_FAILURE = "UPDATE_MANUAL_REVIEW_FAILURE";
export const RESET_MANUAL_REVIEW = "RESET_MANUAL_REVIEW";

export const UPDATE_CLINICAL_REVIEW_REQUESTED = "UPDATE_CLINICAL_REVIEW_REQUESTED";
export const UPDATE_CLINICAL_REVIEW_SUCCESS = "UPDATE_CLINICAL_REVIEW_SUCCESS";
export const UPDATE_CLINICAL_REVIEW_FAILURE = "UPDATE_CLINICAL_REVIEW_FAILURE";

export const REQUESTED_CYCLE_REQUESTED = "REQUESTED_CYCLE_REQUESTED";
export const REQUESTED_CYCLE_SUCCESS = "REQUESTED_CYCLE_SUCCESS";
export const REQUESTED_CYCLE_FAILURE = "REQUESTED_CYCLE_FAILURE";

export const TRAK_FINANCIAL_ESTIMATE_REQUESTED = "TRAK_FINANCIAL_ESTIMATE_REQUESTED";
export const TRAK_FINANCIAL_ESTIMATE_SUCCESS = "TRAK_FINANCIAL_ESTIMATE_SUCCESS";
export const TRAK_FINANCIAL_ESTIMATE_FAILURE = "TRAK_FINANCIAL_ESTIMATE_FAILURE";
export const RESET_TRAK_FINANCIAL_ESTIMATE_LIST = "RESET_TRAK_FINANCIAL_ESTIMATE_LIST";

export const PERSONAL_DATA_REQUESTED = "PERSONAL_DATA_REQUESTED";

//History Pricing Modal
export const HISTORY_PRICING_MODAL_DATA_REQUESTED = "HISTORY_PRICING_MODAL_DATA_REQUESTED";
export const HISTORY_PRICING_MODAL_DATA_SUCCESS = "HISTORY_PRICING_MODAL_DATA_SUCCESS";
export const HISTORY_PRICING_MODAL_DATA_FAILED = "HISTORY_PRICING_MODAL_DATA_FAILED";

//Refresh All Status History
export const REFRESH_STATUS_HISTORY_REQUESTED = "REFRESH_STATUS_HISTORY_REQUESTED";
export const REFRESH_STATUS_HISTORY_SUCCESS = "REFRESH_STATUS_HISTORY_SUCCESS";
export const REFRESH_STATUS_HISTORY_FAILED = "REFRESH_STATUS_HISTORY_FAILED";
export const RESET_REFRESH_STATUS_HISTORY = "RESET_REFRESH_STATUS_HISTORY";

// Patient Payment Action Types
export const EDIT_PATIENT_PAYMENT_TRANSACTION = "EDIT_PATIENT_PAYMENT_TRANSACTION";
